import React from "react";
import { CredentialExpandableProps } from "./credential-expandable";
import { EnvironmentOutlined } from "@ant-design/icons";
import "./styles.css";

export const CredentialExpandableMore: React.FC<CredentialExpandableProps> = (
  props
) => {
  return (
    <div className="container">
      <div className="sectionHeader" style={{ gap: "1.5em" }}>
        <div style={{ fontWeight: 600, cursor: "pointer" }}>Location</div>
        <div style={{ cursor: "pointer" }}>Verification History</div>
        <div style={{ cursor: "pointer" }}>Credential Schema</div>
      </div>
      <div
        className="moreBody"
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "1em",
          gap: "1em",
        }}
      >
        <p style={{ display: "flex", flexDirection: "row", gap: 8 }}>
          <EnvironmentOutlined rev={undefined} />
          Sandy, UT 84043 - Anonyome Credit Union Branch
        </p>
        <div className="">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115388.36377983418!2d-112.01729360835384!3d40.590535218592244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8752f50f4cb57d07%3A0x71e17fb84d142a36!2sAnonyome%20Labs!5e0!3m2!1sen!2sus!4v1704828016409!5m2!1sen!2sus"
            title="location"
            width="480"
            height="240"
            style={{ border: 0, borderRadius: 12 }}
            loading="lazy"
          ></iframe>
        </div>
        <p style={{ fontSize: 12 }}>
          Location only active when requesting the credential in a branch that
          has location services turned on.
        </p>
      </div>
    </div>
  );
};
