import { CloudAgentConfig } from '../../entities'
import { CloudAgentConfigGatewayInterface } from '../../gateway-interfaces'

export class GetCloudAgentConfig {
  constructor(
    private readonly cloudAgentConfigGateway: CloudAgentConfigGatewayInterface,
  ) {}

  async execute(): Promise<CloudAgentConfig> {
    return await this.cloudAgentConfigGateway.getCloudAgentConfig()
  }
}
