import {
  ConnectionList,
  ConnectionsGetStateEnum,
  ConnRecord,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import { Connection, ConnectionID } from '../../domain/entities'
import { ConnectionNotFoundError } from '../../domain/error-types'
import { ConnectionGatewayInterface } from '../../domain/gateway-interfaces'
import { convertIso8601ToDate } from '../../utils/convertIso8601ToDate'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'
import { apiCall } from './utils'

export class ConnectionGateway implements ConnectionGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getConnectionById(id: ConnectionID): Promise<Connection> {
    const connRecord: ConnRecord = await apiCall({
      run: this.cloudAgentAPIs.connections.connectionsConnIdGet({
        connId: id,
      }),
      logErrMsg: `Failed to retrieve connection with id ${id}`,
      onError: (error) => {
        if (error.message.includes(`404`)) {
          throw new ConnectionNotFoundError()
        }
      },
    })

    return transformToConnection(connRecord)
  }

  async getConnectionList(): Promise<Connection[]> {
    /**
     * Since the connectionsGet SDK call can only have one specified string for the 'state' parameter,
     * it's more efficient to get all connections, then return filtered results.
     */
    const result: ConnectionList = await apiCall({
      run: this.cloudAgentAPIs.connections.connectionsGet({}),
      logErrMsg: 'Failed to get connections',
    })

    const connRecords: ConnRecord[] = result.results ?? []

    const filterStates: string[] = [
      ConnectionsGetStateEnum.Response,
      ConnectionsGetStateEnum.Active,
      ConnectionsGetStateEnum.Completed,
    ]

    const filteredConnRecords = connRecords.filter((connRecord) => {
      return connRecord.state && filterStates.includes(connRecord.state)
    })

    return transformToConnectionList(filteredConnRecords)
  }

  async deleteConnection(id: string): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.connections.connectionsConnIdDelete({
        connId: id,
      }),
      logErrMsg: `Failed to delete connection with id ${id}`,
    })
  }
}

function transformToConnectionList(connRecords: ConnRecord[]): Connection[] {
  const connections = []
  for (const connRecord of connRecords) {
    const connection = transformToConnection(connRecord)
    connections.push(connection)
  }
  return connections
}

function transformToConnection(connRecord: ConnRecord): Connection {
  let updatedDatetime = undefined
  if (connRecord.updated_at) {
    updatedDatetime = convertIso8601ToDate(connRecord.updated_at)
  }
  return {
    id: connRecord.connection_id ?? '',
    name: connRecord.alias ?? '',
    theirLabel: connRecord.their_label ?? '',
    updateDatetime: updatedDatetime,
    debug: { 'SDK::ConnRecord': connRecord },
  }
}
