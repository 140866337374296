export enum CredentialExchangeRoleViewModel {
  Unknown = 'Unknown',
  Holder = 'Holder',
  Issuer = 'Issuer',
}

export enum CredentialExchangeRoleSearchViewModel {
  Holder = 'Holder',
  Issuer = 'Issuer',
}
