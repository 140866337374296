import React, { useContext, useEffect } from "react";
import { useAsyncFn } from "react-use";
import { CredentialListViewModel } from "../../../../../modules/admin-console/controllers/view-models";
import { CloudAgentRefresh } from "../../../../../components/shared/cloud-agent-refresh";
import { CredentialsPageContext } from "../../../../../components/context/credentials/credentials-page-context";
import { CredentialsList } from "./credential-list";

/**
 * View and manage issued credentials and owned credentials.
 */
export const CredentialsCard: React.FC = () => {
  const { controller } = useContext(CredentialsPageContext);

  const [
    { loading: isLoading, value: viewModel, error },
    loadUpdatedViewModel,
  ] = useAsyncFn(async (): Promise<CredentialListViewModel> => {
    return await controller.loadCredentialList();
  }, [controller]);

  useEffect(() => {
    void loadUpdatedViewModel();
  }, [loadUpdatedViewModel]);

  const credentialsList =
    error !== undefined ? (
      <CloudAgentRefresh />
    ) : (
      <CredentialsList
        viewModel={viewModel}
        isLoading={isLoading}
        loadUpdatedViewModel={loadUpdatedViewModel}
      />
    );

  return <>{credentialsList}</>;
};
