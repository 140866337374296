import {
  LatestMessageRow,
  Message,
  MessageList,
  MessageState,
} from '../../domain/entities'
import { MessagesGatewayInterface } from '../../domain/gateway-interfaces/MessagesGatewayInterface'
import { ApolloClient, gql } from '@apollo/client'
import { convertIso8601ToDate } from '../../utils/convertIso8601ToDate'
import { apiCall } from './utils'
import {
  BasicMessage,
  BasicMessageDirection,
  BasicMessagesResult,
  BroadcastBasicMessageResult,
  Connection,
  ConnectionsResult,
  DeleteBasicMessagesResult,
} from './graphql/types'

export class MessagesGateway implements MessagesGatewayInterface {
  constructor(private readonly client: ApolloClient<unknown>) {}

  async sendMessage(connectionId: string, content: string): Promise<void> {
    await apiCall({
      run: this.client.mutate<BasicMessage>({
        mutation: gql`
          mutation SendMessage($connectionId: ID!, $content: String!) {
            sendBasicMessage(connectionId: $connectionId, content: $content) {
              connectionId
              content
            }
          }
        `,
        variables: { connectionId, content },
      }),
      logErrMsg: `Failed to send message to ${connectionId}.`,
    })
  }

  async broadcastMessage(
    connectionIds: string[],
    message: string,
  ): Promise<void> {
    const content = message
    const result = await apiCall({
      run: this.client.mutate<{
        broadcastBasicMessage: BroadcastBasicMessageResult
      }>({
        mutation: gql`
          mutation BroadcastMessage($connectionIds: [ID!]!, $content: String!) {
            broadcastBasicMessage(
              connectionIds: $connectionIds
              content: $content
            ) {
              connectionIds
            }
          }
        `,
        variables: { connectionIds, content },
      }),
      logErrMsg: `Failed to broadcast message.`,
    })

    const notSent = connectionIds.filter(
      (item) =>
        !result.data?.broadcastBasicMessage.connectionIds?.includes(item),
    )
    if (notSent.length > 0) {
      throw new Error(
        `Failed to broadcast message to connections: ${notSent.join(', ')}.`,
      )
    }
  }

  async deleteMessagesForConnection(connectionId: string): Promise<void> {
    await apiCall({
      run: this.client.mutate<DeleteBasicMessagesResult>({
        mutation: gql`
          mutation DeleteMessages($connectionId: ID!) {
            deleteBasicMessagesForConnection(connectionId: $connectionId)
          }
        `,
        variables: { connectionId },
      }),
      logErrMsg: `Failed to delete messages for connection ${connectionId}.`,
    })
  }

  async getLatestMessageRows(): Promise<LatestMessageRow[]> {
    const messageRows = await apiCall({
      run: this.client.query<{ connections: ConnectionsResult }>({
        query: gql`
          query {
            connections(
              page: { limit: null, nextToken: null }
              filter: { hasBasicMessages: true }
            ) {
              items {
                id
                label
                basicMessages(page: { limit: 1, nextToken: null }) {
                  items {
                    id
                    connectionId
                    content
                    timestamp
                    direction
                  }
                }
              }
              nextToken
            }
          }
        `,
        fetchPolicy: 'no-cache',
      }),
      logErrMsg: `Failed to get message rows.`,
    })
    return transformToLatestMessageRows(messageRows.data.connections.items)
  }

  async getMessagesByConnectionId(
    connectionId: string,
    limit: number,
    nextTokenId?: string,
  ): Promise<MessageList> {
    const nextToken = nextTokenId ?? null
    const messages = await apiCall({
      run: this.client.query<{ basicMessages: BasicMessagesResult }>({
        query: gql`
          query Query($connectionId: ID!, $limit: Int, $nextToken: String) {
            basicMessages(
              page: { limit: $limit, nextToken: $nextToken }
              filter: { connectionId: $connectionId }
            ) {
              items {
                id
                content
                timestamp
                direction
              }
              nextToken
            }
          }
        `,
        variables: { connectionId, limit, nextToken },
        fetchPolicy: 'no-cache',
      }),
      logErrMsg: `Failed to get messages for connection ${connectionId}.`,
    })
    return {
      messages: transformToMessages(messages.data.basicMessages.items),
      nextToken: messages.data.basicMessages.nextToken ?? undefined,
    }
  }
}

function transformToMessages(gqlMessages: BasicMessage[]): Message[] {
  const messages: Message[] = []
  for (let i = 0; i < gqlMessages.length; i++) {
    const message = gqlMessages[i]
    messages.push(transformToMessage(message))
  }
  return messages
}

function transformToMessage(gqlMessage: BasicMessage): Message {
  return {
    id: gqlMessage.id,
    connectionId: gqlMessage.connectionId ?? '',
    content: gqlMessage.content,
    timestamp: convertIso8601ToDate(gqlMessage.timestamp ?? undefined),
    direction: transformToMessageState(gqlMessage.direction),
    debug: gqlMessage,
  }
}

function transformToLatestMessageRows(
  gqlConnectionMessage: Connection[],
): LatestMessageRow[] {
  const latestMessages: LatestMessageRow[] = []
  for (let i = 0; i < gqlConnectionMessage.length; i++) {
    const gqlConnMess = gqlConnectionMessage[i]
    latestMessages.push({
      connectionId: gqlConnMess.id,
      connectionName: gqlConnMess.label,
      message: transformToMessage(gqlConnMess.basicMessages.items[0]),
    })
  }
  return latestMessages
}

function transformToMessageState(
  direction: BasicMessageDirection,
): MessageState {
  switch (direction) {
    case BasicMessageDirection.Inbound:
      return MessageState.INBOUND
    case BasicMessageDirection.Outbound:
      return MessageState.OUTBOUND
  }
}
