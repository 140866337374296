import {
  Connection,
  Credential,
  CredentialExchange,
  CredentialExchangeRole,
  isIssuedCredential,
  isOwnedCredential,
  isRevocableCredential,
  OwnedCredential,
  Schema,
} from '../domain/entities'
import {
  ConnectionGatewayInterface,
  CredDefGatewayInterface,
  CredentialExchangeGatewayInterface,
  CredentialGatewayInterface,
} from '../domain/gateway-interfaces'
import {
  DeleteCredential,
  GetConnectionList,
  GetCredDefById,
  GetCredentialExchangeListForCredentials,
  GetCredentialList,
  IsCredentialRevoked,
  RevokeCredential,
} from '../domain/use-cases'
import { convertCredDefIdToName } from '../utils/convertCredDef'
import {
  convertSchemaIdToName,
  convertSchemaIdToVersion,
} from '../utils/convertSchemaId'
import {
  CredentialDetailsViewModel,
  CredentialExchangeRoleViewModel,
  CredentialListItemViewModel,
  CredentialListViewModel,
} from './view-models'

type ListItemData = {
  credential: Credential
  credentialExchange?: CredentialExchange
  connection?: Connection
  schema?: Schema
}

export class CredentialsPageController {
  private loadedListData: { [listItemIndex: number]: ListItemData } = {}

  constructor(
    private readonly credentialGateway: CredentialGatewayInterface,
    private readonly credExGateway: CredentialExchangeGatewayInterface,
    private readonly connectionGateway: ConnectionGatewayInterface,
    private readonly credDefGateway: CredDefGatewayInterface,
  ) {}

  async loadCredentialList(): Promise<CredentialListViewModel> {
    const [credentials, credentialExchanges, connections] = await Promise.all([
      new GetCredentialList(this.credentialGateway).execute(),
      new GetCredentialExchangeListForCredentials(this.credExGateway).execute(),
      new GetConnectionList(this.connectionGateway).execute(),
    ])

    const items: CredentialListItemViewModel[] = []
    let listItemIndex = 0

    for (const credential of credentials) {
      const credentialExchange = findExchangeForCredential(
        credential,
        credentialExchanges,
      )

      const connection = connections.find((connection: Connection) => {
        return connection.id === credentialExchange?.connectionId
      })

      const myCredExRole =
        credentialExchange?.myRole ??
        (isOwnedCredential(credential)
          ? CredentialExchangeRole.Holder
          : CredentialExchangeRole.Unknown)

      console.log('Credential: ', credential)

      items.push({
        index: listItemIndex,
        schemaName: convertSchemaIdToName(credential.schemaId),
        schemaVersion: convertSchemaIdToVersion(credential.schemaId),
        credDefName: convertCredDefIdToName(credential.credDefId),
        credDefId: credential.credDefId,
        isRevocable: isRevocableCredential(credential),
        credentialId: (credential as OwnedCredential).id ?? '',
        credExId: credentialExchange?.id ?? '',
        myCredExRole: transformCredExRoleToViewModel(myCredExRole),
        connectionName: connection?.name ?? '',
        connectionId: credentialExchange?.connectionId ?? '',
        updatedDatetime: credentialExchange?.updatedDatetime,
        isConnectionNotFound: connection === undefined,
        debug: {
          Credential: credential.debug,
          CredentialExchange: credentialExchange?.debug,
        },
      })

      this.loadedListData[listItemIndex] = {
        credential: credential,
        credentialExchange: credentialExchange,
        connection: connection,
      }

      listItemIndex++
    }

    return {
      items: items,
    }
  }

  async loadCredentialDetails(
    listItemIndex: number,
  ): Promise<CredentialDetailsViewModel> {
    if (!(listItemIndex in this.loadedListData)) {
      throw Error('Data is not loaded')
    }

    const listItemData = this.loadedListData[listItemIndex]
    const credential = listItemData.credential
    const credentialExchange = listItemData.credentialExchange
    const connection = listItemData.connection

    const credDef = await new GetCredDefById(this.credDefGateway).execute(
      credential.credDefId,
    )

    const isRevoked = !isRevocableCredential(credential)
      ? false
      : await new IsCredentialRevoked(this.credentialGateway).execute(
          credential,
        )

    return {
      connectionName: connection?.name ?? '',
      connectionId: credentialExchange?.connectionId ?? '',
      schemaId: credential.schemaId,
      schemaName: convertSchemaIdToName(credential.schemaId),
      schemaVersion: convertSchemaIdToVersion(credential.schemaId),
      credDefId: credential.credDefId,
      credDefName: convertCredDefIdToName(credential.credDefId),
      isRevocable: credDef?.isRevocable ?? false,
      credentialId: (credential as OwnedCredential).id ?? '',
      revocationRegistryId: isRevocableCredential(credential)
        ? credential.revocationRegistryId
        : '',
      revocationId: isRevocableCredential(credential)
        ? credential.revocationId ?? ''
        : '',
      revocable:
        credDef === undefined ? '' : credDef.isRevocable ? 'Yes' : 'No',
      revoked: isRevoked ? 'Yes' : 'No',
      myCredExRole: transformCredExRoleToViewModel(credentialExchange?.myRole),
      attributes: credentialExchange?.credentialOffer.attributes ?? [],
      updatedDatetime: credentialExchange?.updatedDatetime,
      isConnectionNotFound: connection === undefined,
      isCredDefNotFound: credDef === undefined,
    }
  }

  async isCredentialRevoked(listItemIndex: number): Promise<boolean> {
    if (!(listItemIndex in this.loadedListData)) {
      throw Error(
        'Cannot check credential revocation status because data is not loaded',
      )
    }

    const listItemData = this.loadedListData[listItemIndex]
    const credential = listItemData.credential

    if (!isRevocableCredential(credential)) {
      throw Error('Credential is not revocable')
    }

    return await new IsCredentialRevoked(this.credentialGateway).execute(
      credential,
    )
  }

  async revokeCredential(listItemIndex: number): Promise<void> {
    if (!(listItemIndex in this.loadedListData)) {
      throw Error('Cannot revoke credential because data is not loaded')
    }

    const listItemData = this.loadedListData[listItemIndex]
    const credential = listItemData.credential

    if (!isIssuedCredential(credential)) {
      throw Error('Cannot revoke credential which was not issued by this agent')
    }

    if (!isRevocableCredential(credential)) {
      throw Error('Credential is not revocable')
    }

    await new RevokeCredential(this.credentialGateway).execute(credential)
  }

  async deleteCredential(listItemIndex: number): Promise<void> {
    if (!(listItemIndex in this.loadedListData)) {
      throw Error('Cannot delete credential because data is not loaded')
    }

    const listItemData = this.loadedListData[listItemIndex]
    const credential = listItemData.credential

    await new DeleteCredential(this.credentialGateway).execute(credential)
  }
}

function findExchangeForCredential(
  credential: Credential,
  credentialExchanges: CredentialExchange[],
): CredentialExchange | undefined {
  if (isOwnedCredential(credential)) {
    return credentialExchanges.find(
      (credentialExchange: CredentialExchange) => {
        return credentialExchange.credentialId === credential.id
      },
    )
  }
  if (isIssuedCredential(credential)) {
    return credentialExchanges.find(
      (credentialExchange: CredentialExchange) => {
        return credentialExchange.id === credential.credExId
      },
    )
  }
  return undefined
}

function transformCredExRoleToViewModel(
  role?: CredentialExchangeRole,
): CredentialExchangeRoleViewModel {
  if (role === CredentialExchangeRole.Holder) {
    return CredentialExchangeRoleViewModel.Holder
  }
  if (role === CredentialExchangeRole.Issuer) {
    return CredentialExchangeRoleViewModel.Issuer
  }
  return CredentialExchangeRoleViewModel.Unknown
}
