interface ID {
  firstname: string;
  lastname: string;
  dob: string;
  address: string;
  idexp: string;
  idnumber: string;
}

function parseDateString(dateString: string): Date | null {
  const [month, day, year] = dateString.split("/").map(Number);

  // Check if the parsed date components are valid
  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    console.error("Invalid date string format: " + dateString);
    return null;
  }

  // Create a Date object
  const dateObject: Date = new Date(year, month - 1, day);

  // Check if the created Date object is valid
  if (isNaN(dateObject.getTime())) {
    console.error("Invalid date");
    return null;
  }

  return dateObject;
}

function formatDateToString(date: Date): string {
  const day: number = date.getDate();
  const month: number = date.getMonth() + 1; // Month is zero-based, so add 1
  const year: number = date.getFullYear();

  // Pad single-digit day and month with a leading zero if needed
  const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;

  // Combine components into "dd/mm/yyyy" format
  const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;

  return formattedDate;
}

const dlRegexPattern = /^[A-Z]\d{7}$/;
const lastnameRegexPattern = /^(LN|IN|NN)$/i;
const firstnameRegexPattern = /^(FN|RN|EN)$/i;
const dobRegexPattern = /^(D|P|O)O(B|S|E)$/i;
const expRegexPattern = /^(EXP)$/i;

const tempJsonString =
  '{"firstname": "unknownFN", "lastname": "unknownLN", "dob": "1/1/2000", "address":"123 main street, SUNNYVALE, CA 94234", "idexp":"1/1/2050", "idnumber":"E1231234"}';
// a real sample
// const tempJsonString = '{"firstname": "ALEXANDER", "lastname": "SAMPLE", "dob": "08/31/1977", "address":"2570 24TH STREET, SACRAMENTO, CA 958184", "idexp":"08/31/2014", "idnumber":"I1234562"}';

let firstNameIdx = 0;
let dobIdx = 0;

export function getDLDataFromOCR(input: string): [string, string] {
  const jsonObject: ID = JSON.parse(tempJsonString);

  let lastNameFound = false;
  let firstNameFound = false;

  //console.log("input test: " + input)
  const wordsArray: string[] = input.split(/\s+/).filter(Boolean);

  for (let i = 0; i < wordsArray.length - 1; i++) {
    const currentString: string = wordsArray[i];

    if (!lastNameFound) {
      if (currentString.length > 3) {
        const t = currentString.substring(0, 2);
        if (lastnameRegexPattern.test(t)) {
          jsonObject.lastname = currentString.substring(2);
          lastNameFound = true;
          continue;
        }
      } else if (lastnameRegexPattern.test(currentString)) {
        jsonObject.lastname = wordsArray[i + 1];
        lastNameFound = true;
        continue;
      }
    }

    if (!firstNameFound) {
      if (currentString.length > 3) {
        const t = currentString.substring(0, 2);
        if (firstnameRegexPattern.test(t)) {
          jsonObject.firstname = currentString.substring(2);
          firstNameIdx = i + 1;
          firstNameFound = true;
          continue;
        }
      } else if (firstnameRegexPattern.test(currentString)) {
        jsonObject.firstname = wordsArray[i + 1];
        firstNameIdx = i + 1;
        firstNameFound = true;
        continue;
      }
    }

    if (expRegexPattern.test(currentString)) {
      const exp = parseDateString(wordsArray[i + 1]);
      if (exp !== null) {
        jsonObject.idexp = formatDateToString(exp);
        //console.log("EXP found!: " + currentString + "," + wordsArray[i+1]);
        continue;
      }
    }

    if (dobRegexPattern.test(currentString)) {
      const dob = parseDateString(wordsArray[i + 1]);
      if (dob !== null) {
        jsonObject.dob = formatDateToString(dob);
        //console.log("DOB found!: " + currentString + "," + wordsArray[i+1]);
        dobIdx = i + 1;
        continue;
      }
    }

    if (dlRegexPattern.test(currentString)) {
      jsonObject.idnumber = currentString;
      //console.log("DL found!");
      continue;
    }
  }

  if (firstNameIdx && dobIdx) {
    jsonObject.address = "";
    //console.log('add address from ' + firstNameIdx + ' to ' + dobIdx);
    for (let i = firstNameIdx + 1; i < dobIdx - 1; i++) {
      //console.log('aad ' + wordsArray[i]);
      jsonObject.address += wordsArray[i];
      if (i < dobIdx - 2) jsonObject.address += " ";
    }
  }

  return [
    JSON.stringify(jsonObject),
    jsonObject.firstname + " " + jsonObject.lastname,
  ];
}
