import { ConnectionID, CredDefID, CredentialID, SchemaID } from './index'

export type CredentialExchangeID = string

export type CredentialExchange = {
  readonly updatedDatetime?: Date
  readonly id: CredentialExchangeID
  /**
   * The credentialId property is only set if the CredentialExchange has resulted in an OwnedCredential.
   */
  readonly credentialId: CredentialID
  readonly credentialOffer: CredentialOffer
  readonly connectionId: ConnectionID
  readonly comment: string
  readonly state: CredentialExchangeState
  readonly myRole: CredentialExchangeRole
  readonly debug?: object
}

/**
 * A preview of the details of the credential offered to the connection, or offered by the connection to this agent.
 */
export type CredentialOffer = {
  readonly schemaId: SchemaID
  readonly credDefId: CredDefID
  readonly attributes: CredentialOfferAttribute[]
}

export type CredentialOfferAttribute = {
  readonly name: string
  readonly value: string
}

export enum CredentialExchangeState {
  Unknown = 'Unknown',
  ProposalSent = 'ProposalSent',
  ProposalReceived = 'ProposalReceived',
  OfferSent = 'OfferSent',
  OfferReceived = 'OfferReceived',
  RequestSent = 'RequestSent',
  RequestReceived = 'RequestReceived',
  CredentialIssued = 'CredentialIssued',
  CredentialReceived = 'CredentialReceived',
  CredentialAcked = 'CredentialAcked',
  CredentialRevoked = 'CredentialRevoked',
  Abandoned = 'Abandoned',
}

export enum CredentialExchangeRole {
  Unknown = 'Unknown',
  Holder = 'Holder',
  Issuer = 'Issuer',
}
