import React, { useContext, useState } from "react";
import { createWorker } from "tesseract.js";
import { getDLDataFromOCR } from "./dl-ocr-parsing";
import { ConnectionExchangePageController } from "../../../../modules/admin-console/controllers";
import { ConnectionExchangePageContext } from "../../../../components/context/connections/connections-page-context";
import { SpinnerOverlay } from "./spinner-overlay";
import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Select } from "antd";
import "../../styles.css";
import { getAccessToken } from "../../../../modules/session/auth-guard";

//import { useAsync } from 'react-use';
//import { AsyncState } from 'react-use/lib/useAsyncFn';

const captureWidth = 1920; // We will scale the captured picture width to this
const displayHeight = 360;

const data_caption = "1080 OGG";

let videoStream: MediaStream | null;

async function startCamera(setCameraReady: {
  (): void;
  (): void;
}): Promise<void> {
  const cameraFeed = document.getElementById("cameraFeed") as HTMLVideoElement;
  // hide the captured picture
  const displayCanvas = document.getElementById(
    "displayCanvasID"
  ) as HTMLCanvasElement;

  if (displayCanvas) {
    displayCanvas.setAttribute("height", "0");
  }

  try {
    videoStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    cameraFeed.srcObject = videoStream;
    cameraFeed.onloadedmetadata = function () {
      cameraFeed.play();
      setCameraReady();
    };
  } catch (error) {
    console.error("Error starting camera:", error);
  }
}

async function stopCamera(): Promise<void> {
  // Note:
  // The camera won't turn off on Chrome on macOS.
  //
  const cameraFeed = document.getElementById("cameraFeed") as HTMLVideoElement;

  try {
    if (cameraFeed) {
      cameraFeed.pause();
      cameraFeed.srcObject = null;
      cameraFeed.src = "";
      cameraFeed.setAttribute("height", "0");
    }
    if (videoStream) {
      const tracks = videoStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  } catch (error) {
    console.error("Error stopping camera:", error);
  }
}

async function takePicture(storePictureData: boolean): Promise<void> {
  // capture the snaptshot of the vide to canvas
  const captureCanvas = document.getElementById(
    "captureCanvasID"
  ) as HTMLCanvasElement;

  // show the captured picture for confirm
  const displayCanvas = document.getElementById(
    "displayCanvasID"
  ) as HTMLCanvasElement;

  if (captureCanvas && displayCanvas) {
    const cameraFeed = document.getElementById(
      "cameraFeed"
    ) as HTMLVideoElement;

    const captureContext = captureCanvas.getContext(
      "2d"
    ) as CanvasRenderingContext2D;
    const displayContext = displayCanvas.getContext(
      "2d"
    ) as CanvasRenderingContext2D;

    const captureHeight =
      (cameraFeed.clientHeight / cameraFeed.clientWidth) * captureWidth;

    captureCanvas.setAttribute("width", captureWidth.toString());
    captureCanvas.setAttribute("height", captureHeight.toString());

    displayCanvas.setAttribute("width", cameraFeed.clientWidth.toString());
    displayCanvas.setAttribute("height", cameraFeed.clientHeight.toString());

    if (cameraFeed) {
      captureContext.drawImage(cameraFeed, 0, 0, captureWidth, captureHeight);
      displayContext.drawImage(
        cameraFeed,
        0,
        0,
        cameraFeed.clientWidth,
        cameraFeed.clientHeight
      );

      // update the captured the picture data to global PictureData only for the id front for now
      if (storePictureData) {
        const data = captureCanvas.toDataURL("image/png");
        console.log("stored image/png data length: " + data.length);
        await localStorage.setItem("PictureData", data);
      }
    }

    captureCanvas.setAttribute("height", "0");

    // stop the camera
    await stopCamera();
  }
}
const NoQRURL = "no url";

async function recognize(
  accessToken: string,
  controller: ConnectionExchangePageController
): Promise<string> {
  const worker = await createWorker("eng");
  const picData = localStorage.getItem("PictureData");
  let invitationURL = NoQRURL;

  if (!picData) {
    return invitationURL;
  }

  try {
    await (async () => {
      const {
        data: { text },
      } = await worker.recognize(picData);
      console.log("!!! recorgnized results start");
      console.log(text);
      console.log("!!! recorgnized results end");
      await worker.terminate();
      const dlJSONStrings = getDLDataFromOCR(text);
      console.log("!!! dlJSONString: " + dlJSONStrings[0]);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
        body: `${dlJSONStrings[0]}`,
      };
      console.log("!!!" + requestOptions);

      if (dlJSONStrings[0]) {
        try {
          await fetch(
            "https://cloudagent-admin-api.ino-cu.research.sudoplatform.com/temporary-post-attributes",
            requestOptions
          ).then((response) => console.log(response.json()));
        } catch (error) {
          console.log(error);
        }
      }

      const connectionName = dlJSONStrings[1];
      const invitationLabel = "CU-TEST";
      const result = await controller.createConnectionInvitation(
        connectionName,
        invitationLabel
      );
      //console.log('!!! results of createConnectionInvitation: ' + result.invitationUrl);
      invitationURL = result.invitationUrl;
      await localStorage.setItem("invitationURL", invitationURL);
      console.log("localStorage.setItem: " + invitationURL);
    })();
  } catch (error) {
    throw error;
  }
  return invitationURL;
}

const infoContent = (
  <div>
    By scanning the QR code your MemberID will be stored securely inside your
    ACU App, and allow us to verify your real identity quickly and easily in the
    future.
  </div>
);

interface Props {
  setHasCaptured: (hasCaptured: boolean) => void;
  modalState: string;
  onClose: () => void;
}

const FrontTitle = "Drivers License Front";
const BackTitle = "Drivers License Back";

export const CapturePicture: React.FC<Props> = (props) => {
  const cameraSelected = "cameraSelected";

  const { modalState, onClose } = props;
  const { controller } = useContext(ConnectionExchangePageContext);
  const [invitationURL, setInvitationURL] = useState<string>(NoQRURL);
  const [streaming, setstreaming] = useState<boolean>(false);
  const [showCaptureButton, setshowCaptureButton] = useState<boolean>(false);
  const [pictureTaken, setPictureTaken] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [processing, setprocessing] = useState<boolean>(false);

  const reset = async (): Promise<void> => {
    const displayCanvas = document.getElementById(
      "displayCanvasID"
    ) as HTMLCanvasElement;
    if (displayCanvas) {
      displayCanvas.setAttribute("height", "0");
    }

    await stopCamera();

    setprocessing(false);
    setstreaming(false);
    setPictureTaken(false);
    setshowCaptureButton(false);
    setSelectedOption("");
  };

  const startCapture = async (): Promise<void> => {
    const setCameraReady = (): void => {
      setshowCaptureButton(true);
    };

    await setshowCaptureButton(false);
    await setstreaming(true);
    await startCamera(setCameraReady);
    setPictureTaken(false);
  };

  let captureTitle = "";
  if (modalState === "take-picture-front") captureTitle = FrontTitle;
  else if (modalState === "take-picture-back") captureTitle = BackTitle;
  else console.error("unknown modal state");

  return (
    <div>
      <div className="modalTitle">
        <span>{captureTitle}</span>
        <div className="spacer" />
        {/* <div onClick={onClose}>
          <CloseCircleFilled rev={undefined} className="clickable" />
        </div> */}
      </div>

      <div className="modalBody">
        <div className="previewContainer">
          {streaming ? (
            <div id="container">
              <video
                id="cameraFeed"
                height={displayHeight}
                data-caption={data_caption}
              />
            </div>
          ) : null}

          <div className="content-container">
            {processing && <SpinnerOverlay />}
            {/*processing && <div className="loading-overlay"></div>*/}
            <div className="actual-content">
              <canvas id="displayCanvasID" height="0"></canvas>
            </div>
          </div>
        </div>

        <div className="controlContainer">
          <Select
            className="select"
            defaultValue="Select Webcam"
            onChange={(value) => {
              setSelectedOption(value);
              if (value === cameraSelected) {
                startCapture();
              } else {
                reset();
              }
            }}
            options={[
              { value: cameraSelected, label: "Camera1" },
              { value: "dummy", label: "Camera2" },
            ]}
          />

          <div className="spacer" />

          {streaming && !pictureTaken && showCaptureButton ? (
            <Button
              id="CapturePicture__capture-btn"
              type="primary"
              shape="round"
              onClick={async () => {
                await takePicture(captureTitle === FrontTitle);
                setPictureTaken(true);
                setstreaming(false);
              }}
              disabled={!streaming}
            >
              Take Picture
            </Button>
          ) : null}

          {!streaming && pictureTaken ? (
            <Button
              className="secondaryButton"
              id="CapturePicture__capture-btn"
              type="primary"
              shape="round"
              onClick={async () => {
                startCapture();
              }}
              disabled={false}
            >
              Retake
            </Button>
          ) : (
            ""
          )}

          {pictureTaken ? (
            <Button
              id="CapturePicture__capture-btn"
              type="primary"
              shape="round"
              onClick={async () => {
                setprocessing(true);
                if (captureTitle === FrontTitle) {
                  setInvitationURL(
                    await recognize(getAccessToken()!, controller)
                  );
                }
                reset();
                props.setHasCaptured(true);
                setSelectedOption("");
                onClose();
              }}
              disabled={false}
            >
              Submit
            </Button>
          ) : (
            ""
          )}
        </div>
        <canvas id="captureCanvasID" height="0" />
      </div>
    </div>
  );
};
