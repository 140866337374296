import {
  ConnectionList as SDKConnectionList,
  ConnectionsGetStateEnum as SDKConnectionsGetStateEnum,
  ConnRecord as SDKConnRecord,
  ConnRecordTheirRoleEnum as SDKConnRecordTheirRoleEnum,
  CreateInvitationRequest as SDKCreateInvitationRequest,
  InvitationResult as SDKInvitationResult,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import {
  ConnectionExchange,
  ConnectionExchangeRole,
  ConnectionExchangeState,
  ConnectionInvitation,
} from '../../domain/entities'
import { ConnectionExchangeGatewayInterface } from '../../domain/gateway-interfaces'
import { convertIso8601ToDate } from '../../utils/convertIso8601ToDate'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'
import { apiCall } from './utils'

export class ConnectionExchangeGateway
  implements ConnectionExchangeGatewayInterface
{
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async createConnectionInvitation(
    name: string,
    label?: string,
  ): Promise<ConnectionInvitation> {
    const body: SDKCreateInvitationRequest | undefined = label
      ? {
          my_label: label, // the name of this Cloud Agent as seen by the invitee in the invitation
        }
      : undefined

    const invitationResult = await apiCall({
      run: this.cloudAgentAPIs.connections.connectionsCreateInvitationPost({
        alias: name, // a human friendly name to use for the connection
        autoAccept: true, // automatically accept a connection requested by an invitee who receives this invitation
        multiUse: false, // single or multi-use invitation
        _public: false, // whether to use the Cloud Agent's public DID or create a new peer DID
        body: body,
      }),
      logErrMsg: 'Failed to create connection invitation',
    })

    return transformToConnectionInvitation(invitationResult)
  }

  async deleteConnectionExchange(id: string): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.connections.connectionsConnIdDelete({
        connId: id,
      }),
      logErrMsg: `Failed to delete connection exchange with id ${id}`,
    })
  }

  async getConnectionExchangeList(): Promise<ConnectionExchange[]> {
    /**
     * Since the connectionsGet SDK call can only have one specified string for the 'state' parameter,
     * it's more efficient to get all connections, then return filtered results.
     */
    const result: SDKConnectionList = await apiCall({
      run: this.cloudAgentAPIs.connections.connectionsGet({}),
      logErrMsg: 'Failed to get connections',
    })

    const connRecords: SDKConnRecord[] = result.results ?? []

    const filterStates: string[] = [
      SDKConnectionsGetStateEnum.Error,
      SDKConnectionsGetStateEnum.Start,
      SDKConnectionsGetStateEnum.Init,
      SDKConnectionsGetStateEnum.Invitation,
      SDKConnectionsGetStateEnum.Request,
      SDKConnectionsGetStateEnum.Abandoned,
    ]

    const filteredConnRecords = connRecords.filter((connRecord) => {
      return connRecord.state && filterStates.includes(connRecord.state)
    })

    return transformToConnectionExchangeList(filteredConnRecords)
  }

  async acceptConnectionInvitation(
    name: string,
    invitation: ConnectionInvitation,
  ): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.connections.connectionsReceiveInvitationPost({
        alias: name, // a human friendly name to use for the connection
        autoAccept: true, // automatically accept the invitation after it's received. This will send a connection request to the inviter
        mediationId: undefined,
        body: {
          // received invitation details
          id: invitation.connectionExchangeId,
          type: invitation.type,
          label: invitation.myLabel,
          recipientKeys: invitation.recipientKeys,
          serviceEndpoint: invitation.endpoint,
          routingKeys: invitation.routingKeys,
          did: invitation.did,
          imageUrl: invitation.imageUrl,
        },
      }),
      logErrMsg: 'Failed to accept connection invitation',
    })
  }
}

function transformToConnectionExchangeList(
  connRecords: SDKConnRecord[],
): ConnectionExchange[] {
  const connections: ConnectionExchange[] = []
  for (const sdkConnRecord of connRecords) {
    const connectionExchange = transformToConnectionExchange(sdkConnRecord)
    connections.push(connectionExchange)
  }
  return connections
}

function transformToConnectionExchange(
  sdkConnRecord: SDKConnRecord,
): ConnectionExchange {
  return {
    id: sdkConnRecord.connection_id ?? '',
    name: sdkConnRecord.alias ?? '',
    state: transformToConnectionExchangeState(sdkConnRecord.state ?? ''),
    myRole: transformMyRole(sdkConnRecord.their_role),
    updatedDateTime: convertIso8601ToDate(sdkConnRecord.updated_at),
    debug: { 'SDK::ConnRecord': sdkConnRecord },
  }
}

function transformMyRole(
  theirRole?: SDKConnRecordTheirRoleEnum,
): ConnectionExchangeRole {
  switch (theirRole) {
    case SDKConnRecordTheirRoleEnum.Invitee:
      return ConnectionExchangeRole.Inviter
    case SDKConnRecordTheirRoleEnum.Inviter:
      return ConnectionExchangeRole.Invitee
    case SDKConnRecordTheirRoleEnum.Requester:
      return ConnectionExchangeRole.Responder
    case SDKConnRecordTheirRoleEnum.Responder:
      return ConnectionExchangeRole.Requester
  }
  return ConnectionExchangeRole.Unknown
}

function transformToConnectionExchangeState(
  sdkState: string,
): ConnectionExchangeState {
  switch (sdkState) {
    case SDKConnectionsGetStateEnum.Abandoned:
      return ConnectionExchangeState.Abandoned
    case SDKConnectionsGetStateEnum.Request:
      return ConnectionExchangeState.Request
    case SDKConnectionsGetStateEnum.Error:
      return ConnectionExchangeState.Error
    case SDKConnectionsGetStateEnum.Start:
      return ConnectionExchangeState.Start
    case SDKConnectionsGetStateEnum.Init:
      return ConnectionExchangeState.Init
    case SDKConnectionsGetStateEnum.Invitation:
      return ConnectionExchangeState.Invitation
    case SDKConnectionsGetStateEnum.Response:
      return ConnectionExchangeState.Response
    default:
      return ConnectionExchangeState.Unknown
  }
}

function transformToConnectionInvitation(
  sdkInvitationResult: SDKInvitationResult,
): ConnectionInvitation {
  return {
    connectionExchangeId: sdkInvitationResult.invitation?.id ?? '',
    type: sdkInvitationResult.invitation?.type ?? '',
    myLabel: sdkInvitationResult.invitation?.label ?? '',
    recipientKeys: sdkInvitationResult.invitation?.recipientKeys ?? [],
    endpoint: sdkInvitationResult.invitation?.serviceEndpoint ?? '',
    invitationUrl: sdkInvitationResult.invitation_url ?? '',
    routingKeys: sdkInvitationResult.invitation?.routingKeys,
    did: sdkInvitationResult.invitation?.did,
    imageUrl: sdkInvitationResult.invitation?.imageUrl ?? undefined,
  }
}
