import {
  DIFField,
  DIFHolder,
  DIFHolderDirectiveEnum,
  DIFProofRequest,
  Filter as SDKFilter,
  InputDescriptors as SDKInputDescriptor,
  PresentProof20SendRequestPostRequest,
  SchemaInputDescriptor,
  SchemasInputDescriptorFilter,
  V20PresExRecord,
  V20PresExRecordList,
  V20PresExRecordRoleEnum,
  V20PresExRecordStateEnum,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import { v4 as uuidv4 } from 'uuid'
import {
  Field,
  Filter,
  InputDescriptor,
  ProofExchange,
  ProofExchangeRole,
  ProofExchangeState,
  ProofType,
} from '../../../domain/entities/W3C'
import { ApiDataIsNotW3cError } from '../../../domain/error-types'
import {
  CredentialForInputDescriptor,
  OutboundFilter,
  OutboundInputDescriptor,
  OutboundProofRequest,
  ProofExchangeGatewayInterface,
} from '../../../domain/gateway-interfaces/W3C'
import { convertIso8601ToDate } from '../../../utils/convertIso8601ToDate'
import { CloudAgentAPI } from '../sdk-wrappers/cloudAgent'
import { apiCall } from '../utils'

/**
 * This class is intended to comply with https://identity.foundation/presentation-exchange/spec/v1.0.0/
 */
export class ProofExchangeGateway implements ProofExchangeGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getInProgressProofExchangeList(): Promise<ProofExchange[]> {
    const result: V20PresExRecordList = await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsGet({}),
      logErrMsg: 'Failed to get proof exchanges',
    })

    const presExRecords: V20PresExRecord[] = result.results ?? []

    const filterStates = [
      V20PresExRecordStateEnum.ProposalSent,
      V20PresExRecordStateEnum.ProposalReceived,
      V20PresExRecordStateEnum.RequestSent,
      V20PresExRecordStateEnum.RequestReceived,
      V20PresExRecordStateEnum.PresentationSent,
      V20PresExRecordStateEnum.PresentationReceived,
      V20PresExRecordStateEnum.Abandoned,
    ]

    const filteredPresExRecords = presExRecords.filter((presExRecord) => {
      return presExRecord.state && filterStates.includes(presExRecord.state)
    })

    return transformToProofExchangeList(filteredPresExRecords)
  }

  async sendProofPresentationRequest(
    proofRequest: OutboundProofRequest,
  ): Promise<void> {
    const sdkInputDescriptors: SDKInputDescriptor[] =
      transformToSdkInputDescriptors(proofRequest.inputDescriptors)

    const requestParams: PresentProof20SendRequestPostRequest = {
      body: {
        auto_verify: true,
        connection_id: proofRequest.connectionId,
        presentation_request: {
          dif: {
            options: {
              challenge: uuidv4(),
              domain: uuidv4(),
            },
            presentation_definition: {
              input_descriptors: sdkInputDescriptors,
              name: proofRequest.name,
              format: {
                ldp_vp: { proof_type: [proofRequest.proofType.toString()] },
              },
            },
          },
        },
      },
    }

    await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20SendRequestPost(
        requestParams,
      ),
      logErrMsg: 'Failed to send proof request',
    })
  }

  async getProofExchangeById(id: string): Promise<ProofExchange> {
    const v20ProofExchange: V20PresExRecord = await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsPresExIdGet(
        {
          presExId: id,
        },
      ),
      logErrMsg: 'Failed to get proof exchange',
    })

    return transformToProofExchange(v20ProofExchange)
  }

  async sendProofPresentation(
    proofExId: string,
    credentialsForInputDescriptors: CredentialForInputDescriptor[],
  ): Promise<void> {
    const credentialIdsByInputDescriptorId: {
      [inputDescriptorId: string]: string[]
    } = {}

    for (let i = 0; i < credentialsForInputDescriptors.length; ++i) {
      credentialIdsByInputDescriptorId[
        credentialsForInputDescriptors[i].inputDescriptorId
      ] = [credentialsForInputDescriptors[i].credentialId]
    }

    await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsPresExIdSendPresentationPost(
        {
          presExId: proofExId,
          body: {
            dif: {
              record_ids: credentialIdsByInputDescriptorId,
            },
          },
        },
      ),
      logErrMsg: 'Failed to send proof presentation',
    })
  }

  async sendProblemReport(
    proofExId: string,
    description: string,
  ): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsPresExIdProblemReportPost(
        {
          presExId: proofExId,
          body: {
            description: description,
          },
        },
      ),
      logErrMsg: `Failed to send problem report for proof exchange with id ${proofExId}`,
    })
  }

  async deleteProofExchangeById(id: string): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsPresExIdDelete(
        {
          presExId: id,
        },
      ),
      logErrMsg: `Failed to delete proof exchange with id ${id}`,
    })
  }
}

function transformToProofExchangeList(
  v20PresExRecords: V20PresExRecord[],
): ProofExchange[] {
  const proofExchanges: ProofExchange[] = []
  for (const v20PresExRecord of v20PresExRecords) {
    try {
      const proofExchange = transformToProofExchange(v20PresExRecord)
      proofExchanges.push(proofExchange)
    } catch (error) {
      // for this list operation, ignore any proof exchanges returned from the API which are not W3C
      if (error instanceof ApiDataIsNotW3cError) {
        continue
      }
      throw error
    }
  }
  return proofExchanges
}

/**
 * This transformer is exported so the ProofPresentationGateway can use it. When we switch to using GraphQL,
 * we will likely need to move all entity transformers into a 'src/domain/gateways/W3C/transformers' directory so that
 * they can be more easily shared between use-case-specific gateways.
 */
export function transformToProofExchange(
  v20PresExRecord: V20PresExRecord,
): ProofExchange {
  const sdkPresRequest: SdkPresRequest =
    v20PresExRecord.by_format?.pres_request ?? {}

  if (sdkPresRequest.dif === undefined) {
    throw new ApiDataIsNotW3cError()
  }

  const sdkInputDescriptors: SDKInputDescriptor[] =
    sdkPresRequest.dif.presentation_definition?.input_descriptors ?? []

  const sdkFormatLdpVp: SdkFormatLdpVp =
    sdkPresRequest.dif.presentation_definition?.format?.ldp_vp ?? {}

  return {
    id: v20PresExRecord.pres_ex_id ?? '',
    connectionId: v20PresExRecord.connection_id ?? '',
    name: sdkPresRequest.dif.presentation_definition?.name ?? '',
    comment: v20PresExRecord.pres?.comment ?? '',
    inputDescriptors: transformToInputDescriptors(sdkInputDescriptors),
    proofType: transformToProofType(sdkFormatLdpVp.proof_type ?? []),
    myRole: transformToProofExchangeRole(v20PresExRecord.role),
    state: transformToProofExchangeState(v20PresExRecord.state),
    updatedDatetime: convertIso8601ToDate(v20PresExRecord.updated_at),
    debug: { 'SDK::V20PresExRecord': v20PresExRecord },
  }
}

function transformToInputDescriptors(
  sdkInputDescriptors: SDKInputDescriptor[],
): InputDescriptor[] {
  return sdkInputDescriptors.map((sdkInputDescriptor) => {
    return transformToInputDescriptor(sdkInputDescriptor)
  })
}

function transformToInputDescriptor(
  sdkInputDescriptor: SDKInputDescriptor,
): InputDescriptor {
  const sdkFields: DIFField[] = sdkInputDescriptor?.constraints?.fields ?? []
  const sdkHolderConstraints: DIFHolder[] =
    sdkInputDescriptor?.constraints?.is_holder ?? []

  return {
    id: sdkInputDescriptor?.id ?? '',
    schemaUris: transformToSchema(sdkInputDescriptor?.schema ?? []),
    fields: transformToFields(sdkFields, sdkHolderConstraints),
  }
}

function transformToSchema(sdkSchema: SdkSchema): string[] {
  if (Object.hasOwn(sdkSchema, 'uri_groups')) {
    const uriGroups =
      (sdkSchema as SchemasInputDescriptorFilter).uri_groups ?? []

    // the 'nested array' structure is unique to ACApy and serves no purpose, so only process the first entry
    const uriGroup = uriGroups.length > 0 ? uriGroups[0] : []

    return uriGroup.map((sdkSchemaUri) => sdkSchemaUri.uri ?? '')
  }

  return (sdkSchema as SchemaInputDescriptor[]).map(
    (sdkSchemaUri) => sdkSchemaUri.uri ?? '',
  )
}

function transformToFields(
  sdkFields: DIFField[],
  sdkHolderConstraints: DIFHolder[],
): Field[] {
  return sdkFields.map((sdkField: DIFField): Field => {
    const fieldId = sdkField.id ?? ''
    return {
      id: fieldId,
      path: sdkField.path ?? [],
      purpose: sdkField.purpose ?? '',
      isHolder: transformToHolderConstraint(fieldId, sdkHolderConstraints),
      filter: transformToFilter((sdkField.filter ?? {}) as object),
    }
  })
}

function transformToHolderConstraint(
  fieldId: string,
  sdkHolderConstraints: DIFHolder[],
): boolean {
  for (let i = 0; i < sdkHolderConstraints.length; ++i) {
    if (
      sdkHolderConstraints[i].directive === DIFHolderDirectiveEnum.Required &&
      (sdkHolderConstraints[i].field_id ?? []).indexOf(fieldId) > -1
    ) {
      return true
    }
  }
  return false
}

function transformToFilter(sdkFilter: SDKFilter): Filter {
  // '_const' is expected to be 'const' at runtime, and it's expected to be a string rather than an object at runtime,
  // despite what the type system believes.
  // @see https://identity.foundation/presentation-exchange/#predicate-feature
  const castedSdkFilter = sdkFilter as { const?: string }
  return {
    const: castedSdkFilter.const,
  }
}

function transformToProofExchangeRole(
  role: string | undefined,
): ProofExchangeRole {
  switch (role) {
    case V20PresExRecordRoleEnum.Prover:
      return ProofExchangeRole.Prover
    case V20PresExRecordRoleEnum.Verifier:
      return ProofExchangeRole.Verifier
    default:
      return ProofExchangeRole.Unknown
  }
}

function transformToProofExchangeState(state?: string): ProofExchangeState {
  switch (state) {
    case V20PresExRecordStateEnum.ProposalSent:
      return ProofExchangeState.ProposalSent
    case V20PresExRecordStateEnum.ProposalReceived:
      return ProofExchangeState.ProposalReceived
    case V20PresExRecordStateEnum.RequestSent:
      return ProofExchangeState.RequestSent
    case V20PresExRecordStateEnum.RequestReceived:
      return ProofExchangeState.RequestReceived
    case V20PresExRecordStateEnum.PresentationSent:
      return ProofExchangeState.PresentationSent
    case V20PresExRecordStateEnum.PresentationReceived:
      return ProofExchangeState.PresentationReceived
    case V20PresExRecordStateEnum.Abandoned:
      return ProofExchangeState.Abandoned
    case V20PresExRecordStateEnum.Done:
      return ProofExchangeState.Done
    case V20PresExRecordStateEnum.Deleted:
    // fall through - not supported
  }
  return ProofExchangeState.Unknown
}

function transformToProofType(proofTypes: string[]): ProofType {
  if (proofTypes.length > 0) {
    switch (proofTypes[0]) {
      case ProofType.BbsBlsSignature2020:
        return ProofType.BbsBlsSignature2020
      case ProofType.Ed25519Signature2018:
        return ProofType.Ed25519Signature2018
    }
  }
  return ProofType.Unknown
}

function transformToSdkInputDescriptors(
  outboundInputDescriptors: OutboundInputDescriptor[],
): SDKInputDescriptor[] {
  return outboundInputDescriptors.map((outboundInputDescriptor) => {
    return transformToSdkInputDescriptor(outboundInputDescriptor)
  })
}

function transformToSdkInputDescriptor(
  outboundInputDescriptor: OutboundInputDescriptor,
): SDKInputDescriptor {
  const sdkFields: DIFField[] = transformToSdkFields(outboundInputDescriptor)

  return {
    id: uuidv4(),
    schema: transformToSdkSchema(outboundInputDescriptor),
    constraints: {
      fields: sdkFields,
      is_holder: transformToSdkHolderConstraints(
        outboundInputDescriptor,
        sdkFields,
      ),
    },
  }
}

function transformToSdkFields(
  outboundInputDescriptor: OutboundInputDescriptor,
): DIFField[] {
  const sdkFields: DIFField[] = outboundInputDescriptor.fields.map((field) => {
    return {
      id: uuidv4(),
      filter: transformToSdkFilter(field.filter),
      path: [`$.credentialSubject.${field.path}`],
    }
  })

  // Always require the credential to be from a specific issuer
  sdkFields.push({
    id: uuidv4(),
    path: ['$.issuer', '$.issuer.id'],
    filter: transformToSdkFilter({
      const: outboundInputDescriptor.issuerDid,
    }),
  })

  return sdkFields
}

function transformToSdkFilter(filter: OutboundFilter): SDKFilter | undefined {
  // if no filters are set, pass undefined to the SDK
  if (filter.const === undefined) {
    return undefined
  }

  return {
    _const: filter.const ? new String(filter.const) : undefined,
  }
}

function transformToSdkSchema(
  outboundInputDescriptor: OutboundInputDescriptor,
): SchemasInputDescriptorFilter {
  const sdkUriGroups: SchemaInputDescriptor[][] = [
    [
      {
        required: true,
        uri: 'https://www.w3.org/2018/credentials#VerifiableCredential',
      },
      ...outboundInputDescriptor.schemaUris.map((schemaUri) => {
        return {
          required: true,
          uri: schemaUri,
        }
      }),
    ],
  ]

  return {
    oneof_filter: undefined,
    uri_groups: sdkUriGroups,
  }
}

function transformToSdkHolderConstraints(
  outboundInputDescriptor: OutboundInputDescriptor,
  sdkFields: DIFField[],
): DIFHolder[] | undefined {
  if (!outboundInputDescriptor.isHolder) {
    return undefined
  }

  return [
    {
      directive: DIFHolderDirectiveEnum.Required,
      field_id: sdkFields.map((field) => field.id ?? ''),
    },
  ]
}

/**
 * Assume all properties in the SDK response object are optional, in order to avoid crashes.
 */
interface SdkPresRequest {
  // AnonCreds AIP-2.0 records will have an 'indy' property instead of 'dif'
  indy?: object
  dif?: DIFProofRequest
}

interface SdkFormatLdpVp {
  proof_type?: string[]
}

type SdkSchema = SchemasInputDescriptorFilter | SchemaInputDescriptor[]
