import { ApolloError } from '@apollo/client'

/**
 * Replacement for @deprecated function 'reportCloudAgentError' in src/gateways/sudo-di-cloud-agent/sdk-wrappers/utils/errorlog.ts
 */
export async function apiCall<ReturnType>(args: {
  run: Promise<ReturnType>
  logErrMsg: string
  onError?: (error: Error) => void
}): Promise<ReturnType> {
  try {
    return await args.run
  } catch (rawError: unknown) {
    let error: Error
    if (rawError instanceof ApolloError) {
      error = rawError
    } else {
      const httpResponse = rawError as Response
      error = await transformHttpResponseToGenericErrorType(httpResponse)
    }

    // optionally inject custom 'catch' logic. Throw custom error types from here
    if (args.onError) {
      args.onError(error)
    }

    // if no custom error type was thrown, then log and throw a generic error type
    if (error instanceof ApolloError) {
      logGraphQLError(args.logErrMsg, error)
    } else {
      const httpResponse = rawError as Response
      logGenericHttpError(args.logErrMsg, error, httpResponse)
    }
    throw error
  }
}

async function transformHttpResponseToGenericErrorType(
  httpResponse: Response,
): Promise<Error> {
  // when the agent returns 422 it usually includes a body explaining why the request was not processable
  let reason422
  try {
    reason422 =
      httpResponse.status === 422
        ? JSON.stringify(await httpResponse.json())
        : undefined
  } catch (error) {
    const errorAsString = (error as Error).toString()
    reason422 = `JSON.stringify failed to convert 422 response body, ${errorAsString}`
  }

  const httpResponseBody: string = await new Response(httpResponse.body).text()

  const message = reason422 ? reason422 : httpResponseBody
  return Error(message)
}

function logGenericHttpError(
  message: string,
  error: Error,
  httpResponse: Response,
): void {
  console.error(`Error occurred on API call.
  Application Message : ${message}
  Network Message     : ${error.message}
  URL                 : ${httpResponse.url}
  HTTP Status Code    : ${httpResponse.status}
  HTTP Status Text    : ${httpResponse.statusText}`)
}

function logGraphQLError(message: string, error: ApolloError): void {
  console.error(`Error occurred on API call.
  Application Message : ${message}
  Network Message     : ${error.message}
  GraphQL Path        : ${error.graphQLErrors[0].path}`)
}
