import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { ExpandableConfig } from "rc-table/lib/interface";
import {
  CredentialListItemViewModel,
  CredentialListViewModel,
} from "../../../../../modules/admin-console/controllers/view-models";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";

import { Button, Checkbox } from "antd";
import { CredentialExpandable } from "../credential-expandable/credential-expandable";

import { V10PresentationSendRequestRequest } from "@sudoplatform-labs/sudo-di-cloud-agent";
import {
  ProofExchange,
  ProofExchangeState,
  ProofPresentation,
} from "../../../../../modules/admin-console/domain/entities";
import { CheckCircleFilled } from "@ant-design/icons";
import {
  cloudAgentAPIs,
  cloudAgentGateways,
} from "../../../../../modules/cloudAgent";
import { TableClearAllButton } from "./table-clear-all-button";

function getTableRowExpandable(
  _transformDataForDisplay?: (rowData: CredentialListItemViewModel) => object
): ExpandableConfig<CredentialListItemViewModel> | undefined {
  return {
    expandedRowRender: (rowData: CredentialListItemViewModel) => (
      <CredentialExpandable rowData={rowData} />
    ),
    expandIcon: ({ expanded, onExpand, record }) => {
      if (expanded) {
        return (
          <MinusSquareOutlined
            onClick={(e) => onExpand(record, e)}
            rev={undefined}
          />
        );
      } else
        return (
          <PlusSquareOutlined
            onClick={(e) => onExpand(record, e)}
            rev={undefined}
          />
        );
    },
  };
}

interface Props {
  viewModel?: CredentialListViewModel;
  isLoading: boolean;
  loadUpdatedViewModel: () => Promise<CredentialListViewModel>;
}

export const CredentialsList: React.FC<Props> = (props) => {
  const [proofRequests, setProofRequests] = useState<ProofExchange[]>([]);
  const [proofs, setProofs] = useState<ProofPresentation[]>([]);

  const { viewModel, isLoading } = props;

  const id = "CredentialsList";

  const onClickCancelRequest = (
    credential: CredentialListItemViewModel
  ): void => {
    console.log(credential);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // refresh proof request states every 2 seconds
      cloudAgentGateways.proofExGateway
        .getInProgressProofExchangeList()
        .then((proofExchanges) => {
          // console.log('Got proof exchanges: ', proofExchanges)
          setProofRequests(proofExchanges);
        })
        .catch((e) => {
          console.error(e);
        });
      // refresh proofs every 2 seconds
      cloudAgentGateways.proofPresGateway
        .getProofPresentationsList()
        .then((proofs) => {
          // console.log('Got proofs: ', proofs)
          setProofs(proofs);
        })
        .catch((e) => {
          console.error(e);
        });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const getProofRequestOrProofState = (
    credential: CredentialListItemViewModel
  ): ProofExchangeState | string | undefined => {
    // check proof requests
    for (const proofRequest of proofRequests) {
      if (proofRequest.connectionId === credential.connectionId) {
        if (
          proofRequest.requestedProofs.attributeGroups[0].restrictions[0][
            "credDefId"
          ] === credential.credDefId
        ) {
          return proofRequest.state;
        }
      }
    }
    // check proofs
    for (const proof of proofs) {
      if (proof.connectionId === credential.connectionId) {
        // TODO: compare credential ID?
        return "proof";
      }
    }
    return undefined;
  };

  const onClickSendRequest = (
    credential: CredentialListItemViewModel
  ): void => {
    try {
      const agentRequest: V10PresentationSendRequestRequest = {
        connection_id: credential.connectionId,
        comment: "Proof Request Comment",
        // trace: request.trace,
        auto_verify: true, // request.auto_verify ?? false,
        proof_request: {
          name: "Proof Request",
          // nonce: request.proof_request.nonce,
          version: "1.0", // request.proof_request.version ?? '1.0',
          requested_attributes: {
            "0": {
              names: [
                "address",
                "dob",
                "firstname",
                "idexp",
                "idnumber",
                "lastname",
              ], // TODO: get from credential
              restrictions: [{ cred_def_id: credential.credDefId }],
              non_revoked: { to: Math.floor(new Date().getTime() / 1000) }, // current timestamp
            },
          }, // request.proof_request.requested_attributes,
          requested_predicates: {}, // request.proof_request.requested_predicates,
        },
      };

      cloudAgentAPIs.presentV10Proofs
        .presentProofSendRequestPost({
          body: agentRequest,
        })
        .then((presentationExchange) => {
          console.log(presentationExchange);
          console.log("Proof request sent");
          // const newPRStates = { ...proofRequestStates }
          // newPRStates[credential.credDefId] = 'sent'
          // setProofRequestStates(newPRStates)
        });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: "Name",
      dataIndex: "connectionName",
      key: "connectionName",
    },
    {
      title: "Select Credentials",
      dataIndex: "credDefName",
      key: "credDefName",
      render(data: string) {
        return <Checkbox>{data}</Checkbox>;
      },
    },
    {
      title: "Schema ID",
      dataIndex: "schemaName",
      key: "schemaName",
    },
    {
      title: "Verification",
      // dataIndex: '',
      key: "",
      render(credential: CredentialListItemViewModel) {
        const proofRequestState = getProofRequestOrProofState(credential);
        switch (
          proofRequestState // proofRequestStates[credential.credDefId]) {
        ) {
          case "proof":
            return (
              <div>
                <Button
                  type="primary"
                  style={{ backgroundColor: "rgba(0,120,0,1)" }}
                >
                  <CheckCircleFilled rev={undefined} /> Verified
                </Button>
              </div>
            );
          case ProofExchangeState.Abandoned:
            return (
              <div>
                <p>Unable to verify</p>
                <Button
                  onClick={() => {
                    onClickSendRequest(credential);
                  }}
                  type="primary"
                  danger
                >
                  Resend Request
                </Button>
              </div>
            );
          case ProofExchangeState.RequestSent:
            return (
              <div>
                <p>Waiting for response...</p>
                <Button
                  type="link"
                  danger
                  onClick={() => {
                    onClickCancelRequest(credential);
                  }}
                >
                  Cancel Request
                </Button>
              </div>
            );
          default:
            return (
              <Button
                onClick={() => {
                  onClickSendRequest(credential);
                }}
                type="primary"
              >
                Send Request
              </Button>
            );
        }
      },
    },
    {
      key: 'action',
      width: '10%',
      title: <TableClearAllButton onRefresh={props.loadUpdatedViewModel} />,
      align: 'right'
    ,}
  ];

  return (
    <Table
      style={{ maxWidth: "100%" }}
      id={id}
      loading={isLoading}
      columns={columns}
      showSorterTooltip={false}
      dataSource={viewModel?.items}
      rowKey={(viewModel: CredentialListItemViewModel) => viewModel.index}
      expandable={getTableRowExpandable((rowData) => {
        return rowData.debug ?? {};
      })}
    />
  );
};
