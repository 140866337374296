function logCloudAgentError(
  context: string,
  response: Response,
  body: string,
  detail?: string,
): void {
  console.error(`
    ${context}
    URL : ${response.url}
    Reported HTTP status : ${response.status}
    Status text : ${response.statusText}
    Body : ${body}
    ${detail ? `Details : ${detail}` : ''} 
  `)
}

/**
 * @deprecated Use instead: src/gateways/sudo-di-cloud-agent/utils/apiCall.ts
 */
export async function reportCloudAgentError(
  context: string,
  httpResponse: Response,
): Promise<Error> {
  // When the agent returns 422 it usually includes a
  // body explaining why the request was not processable
  let reason422
  try {
    reason422 =
      httpResponse.status === 422
        ? JSON.stringify(await httpResponse.json())
        : undefined
  } catch (err) {
    reason422 = `JSON.stringify failed to convert 422 response body, ${(
      err as Error
    ).toString()}`
  }

  const responseBody: string = await new Response(httpResponse.body).text()
  logCloudAgentError(context, httpResponse, responseBody, reason422)

  return Error(reason422 ? reason422 : responseBody)
}
