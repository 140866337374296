import { Connection } from '../../entities'
import { ConnectionGatewayInterface } from '../../gateway-interfaces'

export class GetConnectionList {
  constructor(private readonly connectionGateway: ConnectionGatewayInterface) {}

  async execute(): Promise<Connection[]> {
    return await this.connectionGateway.getConnectionList()
  }
}
