import {
  isIssuedCredential,
  isOwnedCredential,
  RevocableCredential,
} from '../../entities'
import { CredentialGatewayInterface } from '../../gateway-interfaces'

export class IsCredentialRevoked {
  constructor(private readonly credentialGateway: CredentialGatewayInterface) {}

  async execute(credential: RevocableCredential): Promise<boolean> {
    if (isIssuedCredential(credential)) {
      return await this.credentialGateway.isIssuedCredentialRevoked(credential)
    }

    if (isOwnedCredential(credential)) {
      return await this.credentialGateway.isOwnedCredentialRevoked(credential)
    }

    return false
  }
}
