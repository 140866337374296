import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { Environment } from "../../environment";

export const authenticateUser = (
  username: string,
  password: string,
  onSuccess: (accessToken: string) => void,
  onFailure: (err: any) => void
) => {
  new CognitoUser({
    Username: username,
    Pool: new CognitoUserPool({
      UserPoolId: Environment.cognito.userPoolId,
      ClientId: Environment.cognito.clientId,
    }),
  }).authenticateUser(
    new AuthenticationDetails({
      Username: username,
      Password: password,
    }),
    {
      onSuccess: (result) => {
        var accessToken = result.getAccessToken().getJwtToken();
        onSuccess(accessToken);
      },
      onFailure: (err) => {
        onFailure(err);
      },
    }
  );
};
