import { CredentialExchange } from '../../entities'
import { CredentialExchangeGatewayInterface } from '../../gateway-interfaces'

export class GetCredentialExchangeListForCredentials {
  constructor(
    private readonly credExGateway: CredentialExchangeGatewayInterface,
  ) {}

  async execute(): Promise<CredentialExchange[]> {
    return await this.credExGateway.getCompletedCredExList()
  }
}
