export type ConnectionExchangeListViewModel = {
  items: ConnectionExchangeListItemViewModel[]
}
export type ConnectionExchangeListItemViewModel = {
  connectionId: string
  name: string
  myRole: ConnectionExchangeRoleViewModel
  state: ConnectionExchangeStateViewModel
  updatedDatetime?: Date
  debug?: object
}

export enum ConnectionExchangeRoleViewModel {
  Unknown = 'Unknown',
  Invitee = 'Invitee',
  Inviter = 'Inviter',
  Requester = 'Requester',
  Responder = 'Responder',
}

export enum ConnectionExchangeStateViewModel {
  Unknown = 'Unknown',
  Error = 'Error',
  Start = 'Start',
  Init = 'Init',
  Invitation = 'Invitation',
  Response = 'Response',
  Request = 'Request',
  Abandoned = 'Abandoned',
}

export enum ConnectionExchangeRoleFilterViewModel {
  Invitee = 'Invitee',
  Inviter = 'Inviter',
}

export enum ConnectionExchangeStateFilterViewModel {
  Error = 'Error',
  Start = 'Start',
  Init = 'Init',
  Invitation = 'Invitation',
  Response = 'Response',
  Request = 'Request',
  Abandoned = 'Abandoned',
}
