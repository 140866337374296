import {
  Credential,
  isIssuedCredential,
  isOwnedCredential,
} from '../../entities'
import { CredentialGatewayInterface } from '../../gateway-interfaces'

export class DeleteCredential {
  constructor(private readonly credentialGateway: CredentialGatewayInterface) {}

  async execute(credential: Credential): Promise<void> {
    if (isIssuedCredential(credential)) {
      return await this.credentialGateway.deleteIssuedCredential(credential)
    }

    if (isOwnedCredential(credential)) {
      return await this.credentialGateway.deleteOwnedCredential(credential)
    }

    throw Error('Unsupported credential')
  }
}
