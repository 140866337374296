import { Schema as SdkSchema } from '@sudoplatform-labs/sudo-di-cloud-agent/lib/models/Schema'
import { Schema, SchemaID } from '../../domain/entities'
import {
  FailedToWriteToLedgerError,
  SchemaAlreadyExistsError,
  SchemaNotFoundError,
} from '../../domain/error-types'
import { SchemaGatewayInterface } from '../../domain/gateway-interfaces'
import {
  SchemaCreateParams,
  createSchema,
  fetchAllAgentSchemaIds,
  fetchSchemaDetails,
} from './sdk-wrappers/Schemas'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'

export class SchemaGateway implements SchemaGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async createSchema(
    schema: Pick<Schema, 'name' | 'version' | 'attributes'>,
  ): Promise<void> {
    const sdkSchema = transformToSchemaCreateParams(schema)
    try {
      await createSchema(this.cloudAgentAPIs, sdkSchema)
    } catch (error) {
      const message = (error as Error).message

      if (
        message.includes(
          '400: Error storing schema. Error storing schema. Duplicate row.',
        ) ||
        message.includes('400: Schema already exists on ledger')
      ) {
        throw new SchemaAlreadyExistsError()
      }

      throw new FailedToWriteToLedgerError()
    }
  }

  async getSchemaByIdFromLedger(id: SchemaID): Promise<Schema> {
    if (id.length === 0) {
      throw new SchemaNotFoundError()
    }

    const sdkSchema: SdkSchema = await fetchSchemaDetails(
      this.cloudAgentAPIs,
      id,
    )

    return transformToSchema(sdkSchema)
  }

  async getCreatedSchemaList(): Promise<Schema[]> {
    const sdkSchemaIds: string[] = await fetchAllAgentSchemaIds(
      this.cloudAgentAPIs,
    )
    return await Promise.all(
      sdkSchemaIds.map(async (schemaId) => {
        return await this.getSchemaByIdFromLedger(schemaId)
      }),
    )
  }
}

function transformToSchema(sdkSchema: SdkSchema): Schema {
  return {
    id: sdkSchema.id ?? '',
    name: sdkSchema.name ?? '',
    version: sdkSchema.version ?? '',
    attributes: sdkSchema.attrNames ?? [],
    seqNo: sdkSchema.seqNo?.toString() ?? '',
    debug: { 'SDK::Schema': sdkSchema },
  }
}

function transformToSchemaCreateParams(
  schema: Pick<Schema, 'name' | 'version' | 'attributes'>,
): SchemaCreateParams {
  return {
    name: schema.name,
    version: schema.version,
    attributes: schema.attributes,
  }
}
