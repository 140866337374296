import { TAAAcceptance } from "@sudoplatform-labs/sudo-di-cloud-agent/lib/models/TAAAcceptance";
import {
  TAA,
  TaaAcceptanceMechanism,
  TaaAcceptanceRecord,
  TaaInfo,
} from "../../domain/entities";
import { MustNotAcceptTaaWithEmptyMechanismError } from "../../domain/error-types";
import { TaaGatewayInterface } from "../../domain/gateway-interfaces";
import { convertEpochToDate } from "../../utils/convertEpochToDate";
import { CloudAgentAPI } from "./sdk-wrappers/cloudAgent";
import {
  acceptLedgerTaa,
  fetchLedgerTaaRaw,
  TAAInfoExtended,
  TAARecordExtended,
} from "./sdk-wrappers/TransactionAuthorAgreement";

export class TaaGateway implements TaaGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getTaaInfoFromLedger(): Promise<TaaInfo> {
    const sdkTaaInfo = await fetchLedgerTaaRaw(this.cloudAgentAPIs);
    return transformToTaaInfo(sdkTaaInfo);
  }

  async acceptTaaWithMechanism(taa: TAA, mechanism: string): Promise<void> {
    if (mechanism.length === 0) {
      throw new MustNotAcceptTaaWithEmptyMechanismError();
    }

    await acceptLedgerTaa(this.cloudAgentAPIs, {
      mechanism: mechanism,
      text: taa.text,
      version: taa.version,
    });
  }
}

function transformToTaaInfo(sdkTaaInfo: TAAInfoExtended): TaaInfo {
  return {
    taa: transformToTaa(sdkTaaInfo.taa_record ?? {}),
    isAcceptanceRequired: sdkTaaInfo.taa_required ?? false,
    mechanisms: transformToMechanismList(sdkTaaInfo.aml_record?.aml ?? {}),
    acceptanceRecord: transformToAcceptanceRecord(sdkTaaInfo.taa_accepted),
    debug: { "SDK::TAAInfoExtended": sdkTaaInfo },
  };
}

function transformToTaa(taaRecord: TAARecordExtended): TAA {
  return {
    text: taaRecord.text ?? "",
    version: taaRecord.version ?? "",
    modifiedDatetime: convertEpochToDate(taaRecord.ratification_ts),
  };
}

function transformToMechanismList(aml: {
  [key: string]: string;
}): TaaAcceptanceMechanism[] {
  return Object.entries(aml).map(([key, value]) => {
    return {
      name: key,
      description: value,
    };
  });
}

function transformToAcceptanceRecord(
  taaAcceptance?: TAAAcceptance | null
): TaaAcceptanceRecord | undefined {
  // 'taaAcceptance' can be null during runtime, even though the SDK type does not suggest it
  if (taaAcceptance) {
    return {
      mechanismUsed: taaAcceptance.mechanism ?? "",
      acceptedDate: convertEpochToDate(taaAcceptance.time),
    };
  }
  return undefined;
}
