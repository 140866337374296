import { ConnectionInvitation } from '../../entities'
import { ConnectionExchangeGatewayInterface } from '../../gateway-interfaces'

export class AcceptConnectionInvitation {
  constructor(
    private readonly connectionExchangeGateway: ConnectionExchangeGatewayInterface,
  ) {}

  async execute(name: string, invitation: ConnectionInvitation): Promise<void> {
    return await this.connectionExchangeGateway.acceptConnectionInvitation(
      name,
      invitation,
    )
  }
}
