import React from "react";
import { CredentialExpandableChat } from "./ce-chat";
import { CredentialExpandableMore } from "./ce-more";
import "./styles.css";
import { CredentialListItemViewModel } from "../../../../../modules/admin-console/controllers/view-models";

export interface CredentialExpandableProps {
  rowData: CredentialListItemViewModel;
}

export const CredentialExpandable: React.FC<CredentialExpandableProps> = (
  props
) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1em",
        width: "100%",
      }}
    >
      <CredentialExpandableChat rowData={props.rowData} />
      <CredentialExpandableMore rowData={props.rowData} />
    </div>
  );
};
