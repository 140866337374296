import { ProofType } from './ProofType'

export type ProofExchange = {
  readonly id: string
  readonly connectionId: string
  readonly name: string
  readonly comment: string
  readonly inputDescriptors: InputDescriptor[]
  readonly proofType: ProofType
  readonly myRole: ProofExchangeRole
  readonly state: ProofExchangeState
  readonly updatedDatetime?: Date
  readonly debug?: object
}

export type InputDescriptor = {
  readonly id: string
  readonly schemaUris: string[]
  readonly fields: Field[]
}

export type Field = {
  readonly id: string
  readonly path: string[]
  readonly purpose: string
  readonly isHolder: boolean // if true, then the holder must be the subject of the presented credential
  readonly filter: Filter
}

// @see https://identity.foundation/presentation-exchange/#predicate-feature
export type Filter = {
  readonly const?: string
}

export enum ProofExchangeRole {
  Unknown = 'Unknown',
  Prover = 'Prover',
  Verifier = 'Verifier',
}

export enum ProofExchangeState {
  Unknown = 'Unknown',
  ProposalSent = 'ProposalSent',
  ProposalReceived = 'ProposalReceived',
  RequestSent = 'RequestSent',
  RequestReceived = 'RequestReceived',
  PresentationSent = 'PresentationSent',
  PresentationReceived = 'PresentationReceived',
  Done = 'Done',
  Abandoned = 'Abandoned',
}
