import { Space } from 'antd'
import React from 'react'

export const CloudAgentRefresh: React.FC = () => {
  return (
    <>
      <Space
        direction="horizontal"
        style={{ width: '100%', height: '100px', justifyContent: 'center' }}
      >
        <span>
          Could not connect to the Cloud Agent Service, please try again.
        </span>
      </Space>
    </>
  )
}
