import { CredentialDefinition } from '@sudoplatform-labs/sudo-di-cloud-agent'
import { CredDef, CredDefID, SchemaID } from '../../domain/entities'
import {
  CredDefAlreadyExistsError,
  CredDefNotFoundError,
  FailedToWriteToLedgerError,
} from '../../domain/error-types'
import { CredDefGatewayInterface } from '../../domain/gateway-interfaces'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'
import {
  createCredentialDefinition,
  fetchAllAgentCredentialDefinitionDetails,
  fetchAllAgentCredentialDefinitionIds,
  fetchCredentialDefinitionDetails,
} from './sdk-wrappers/CredentialDefinitions'

export class CredDefGateway implements CredDefGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async createCredDef(
    credDef: { name: string; schemaId: SchemaID; isRevocable: boolean },
    revocationRegistrySize: number,
  ): Promise<void> {
    try {
      await createCredentialDefinition(this.cloudAgentAPIs, {
        tag: credDef.name,
        schema: credDef.schemaId,
        revocable: credDef.isRevocable,
        size: revocationRegistrySize,
      })
    } catch (error) {
      const message = (error as Error).message

      const alreadyInWalletRegex = new RegExp(
        '400: Credential definition.*is in wallet.*but not on ledger',
      )
      const alreadyOnLedgerRegex = new RegExp(
        '400: Credential definition.*is on ledger.*but not in wallet',
      )
      if (
        alreadyInWalletRegex.test(message) ||
        alreadyOnLedgerRegex.test(message)
      ) {
        throw new CredDefAlreadyExistsError()
      }

      throw new FailedToWriteToLedgerError()
    }
  }

  async getCredDefByIdFromLedger(id: CredDefID): Promise<CredDef> {
    if (id.length === 0) {
      throw new CredDefNotFoundError()
    }

    const credentialDefinition: CredentialDefinition =
      await fetchCredentialDefinitionDetails(this.cloudAgentAPIs, id)

    return transformToCredDef(credentialDefinition)
  }

  async getCreatedCredDefIdList(): Promise<CredDefID[]> {
    return await fetchAllAgentCredentialDefinitionIds(this.cloudAgentAPIs)
  }

  async getCreatedCredDefList(): Promise<CredDef[]> {
    const credDefs = await fetchAllAgentCredentialDefinitionDetails(
      this.cloudAgentAPIs,
    )
    return credDefs.map((credDef) => {
      return transformToCredDef(credDef)
    })
  }
}

function transformToCredDef(
  credentialDefinition: CredentialDefinition,
): CredDef {
  return {
    id: credentialDefinition.id ?? '',
    schemaSeqNo: credentialDefinition.schemaId ?? '',
    name: credentialDefinition.tag ?? '',
    isRevocable: credentialDefinition.value?.revocation !== undefined,
    debug: { 'SDK::CredentialDefinition': credentialDefinition },
  }
}
