import React, { useEffect } from "react";
import { CredentialsCard } from "./pages/registered-identities/credential-card/credential-card";
import { SearchOutlined } from "@ant-design/icons";
import { CredentialsPage } from "../../components/context/credentials/credentials-page";

import { Input } from "antd";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../modules/session/auth-guard";

export const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <CredentialsPage>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",

          position: "absolute",
          top: 0,
          left: 0,
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: "rgba(255,255,255,1)",
        }}
      >
        <div
          className="sidebar"
          style={{
            minWidth: 252,
            backgroundColor: "rgba(0,12,37,1)",
            paddingTop: "72px",
          }}
        >
          <div
            className="sidebarItem"
            style={{
              backgroundColor: "rgba(255,255,255,0.3)",
            }}
          >
            Registered Identities
          </div>
          <div className="sidebarItem">Proof Exchange</div>
          <div className="sidebarItem">Proofs</div>
        </div>
        <div className="adminPanel" style={{ flexGrow: 1 }}>
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              gap: "1em",
              padding: "0 2em",
              minWidth: 500,
              height: "72px",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: 600, fontSize: 24 }}>Credentials</span>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined rev={undefined} />}
              style={{
                // backgroundColor: 'rgba(247,247,248,1)',
                // borderWidth: 0,
                fontWeight: 400,
                borderRadius: "2em",
                width: 240,
              }}
            />
          </div>

          <CredentialsCard />
        </div>
      </div>
    </CredentialsPage>
  );
};
