export function convertSchemaIdToName(schemaId: string): string {
  const parts = schemaId.split(':')
  const name = parts[2]
  return name
}

export function convertSchemaIdToVersion(schemaId: string): string {
  const parts = schemaId.split(':')
  const version = parts[3]
  return version
}
