import { ConnectionExchangeID } from '../../entities'
import { ConnectionExchangeGatewayInterface } from '../../gateway-interfaces'

export class DeleteConnectionExchange {
  constructor(
    private readonly connectionExchangeGateway: ConnectionExchangeGatewayInterface,
  ) {}

  async execute(id: ConnectionExchangeID): Promise<void> {
    return await this.connectionExchangeGateway.deleteConnectionExchange(id)
  }
}
