import { ConnectionID } from '../Connection'
import { CredentialSubject } from './CredentialSubject'

/**
 * The ProofPresentation entity contains the values presented to a verifier.
 * A ProofPresentation is the result of a ProofExchange.
 */
export type ProofPresentation = {
  readonly id: string
  readonly connectionId: ConnectionID
  readonly presentedProofs: PresentedProofs
  readonly verified?: boolean // this will be undefined until verification is complete
  readonly myRole: ProofPresentationRole
  readonly updatedDatetime?: Date // a ProofPresentation should not be modified, so this is likely createdDatetime
  readonly debug?: object
}

export type PresentedProofs = {
  readonly presentedLdProofVCsByInputDescriptorId: {
    [inputDescriptorId: string]: PresentedLdProofVC
  }
}

export type PresentedLdProofVC = {
  readonly context: string[]
  readonly type: string[]
  readonly issuanceDate?: Date
  readonly issuerDid?: string
  readonly credentialSubject: CredentialSubject
}

export enum ProofPresentationRole {
  Unknown = 'Unknown',
  Prover = 'Prover',
  Verifier = 'Verifier',
}
