import {
  IndyPresSpec,
  PresentProofRecordsGetStateEnum,
  V10PresentationExchange,
  V10PresentationExchangeRoleEnum,
  V10PresentationSendRequestRequest,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import {
  IndyProofReqAttrSpec,
  IndyProofReqAttrSpecNonRevoked,
  IndyRequestedCredsRequestedAttr,
} from '@sudoplatform-labs/sudo-di-cloud-agent/lib/models'
import {
  ConnectionID,
  CredentialID,
  NonRevokedPeriod,
  ProofExchange,
  ProofExchangeID,
  ProofExchangeRole,
  ProofExchangeState,
  ProofRestriction,
  RequestedAttributeGroup,
} from '../../domain/entities'
import { ConnectionNotFoundError } from '../../domain/error-types'
import { ProofExchangeGatewayInterface } from '../../domain/gateway-interfaces'
import { convertDateToEpoch } from '../../utils/convertDateToEpoch'
import { convertEpochToDate } from '../../utils/convertEpochToDate'
import { convertIso8601ToDate } from '../../utils/convertIso8601ToDate'
import {
  fetchFilteredProofExchangeRecords,
  fetchProofExchangeRecord,
  sendProofPresentation as sdkSendProofPresentation,
  sendProofRequest as sdkSendProofRequest,
} from './sdk-wrappers/ProofPresentation'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'
import { apiCall } from './utils'

export class ProofExchangeGateway implements ProofExchangeGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getProofExchangeById(id: ProofExchangeID): Promise<ProofExchange> {
    const v10PresentationExchange: V10PresentationExchange =
      await fetchProofExchangeRecord(this.cloudAgentAPIs, id)
    return transformToProofExchange(v10PresentationExchange)
  }

  async getInProgressProofExchangeList(): Promise<ProofExchange[]> {
    const v10PresentationExchanges: V10PresentationExchange[] =
      await fetchFilteredProofExchangeRecords(this.cloudAgentAPIs, {
        states: [
          PresentProofRecordsGetStateEnum.ProposalSent,
          PresentProofRecordsGetStateEnum.ProposalReceived,
          PresentProofRecordsGetStateEnum.RequestSent,
          PresentProofRecordsGetStateEnum.RequestReceived,
          PresentProofRecordsGetStateEnum.PresentationSent,
          PresentProofRecordsGetStateEnum.PresentationReceived,
          PresentProofRecordsGetStateEnum.Abandoned,
        ],
      })

    return transformToProofExchangeList(v10PresentationExchanges)
  }

  async getCompletedProofExchangeList(): Promise<ProofExchange[]> {
    const v10PresentationExchanges: V10PresentationExchange[] =
      await fetchFilteredProofExchangeRecords(this.cloudAgentAPIs, {
        states: [
          PresentProofRecordsGetStateEnum.Verified,
          PresentProofRecordsGetStateEnum.PresentationAcked,
        ],
      })

    return transformToProofExchangeList(v10PresentationExchanges)
  }

  async sendProofRequest(
    connectionId: ConnectionID,
    name: string,
    comment: string,
    requestedProofs: {
      attributeGroupsById: {
        [groupId: string]: {
          attributeNames: string[]
          restrictions: {
            credDefId?: string
          }
          nonRevokedPeriod?: {
            to: Date
          }
        }
      }
    },
  ): Promise<void> {
    const v10PresentationSendRequestRequest =
      transformToV10PresentationSendRequestRequest(
        connectionId,
        name,
        comment,
        requestedProofs,
      )

    try {
      await sdkSendProofRequest(
        this.cloudAgentAPIs,
        v10PresentationSendRequestRequest,
      )
    } catch (error) {
      if ((error as Error).message.includes(`400: Record not found`)) {
        throw new ConnectionNotFoundError()
      }
      if (
        // This case (most liekly) handles the case in which the custom connection ID is not a valid UUID, although another error could be the cause as well.
        (error as Error).message.includes(
          `Response constructor: Body has already been consumed.`,
        )
      ) {
        throw new ConnectionNotFoundError()
      }
      throw error
    }
  }

  async sendProofResponse(
    proofExId: ProofExchangeID,
    presentedProofs: {
      attributeGroupsById: {
        [groupId: string]: {
          credentialId: CredentialID
          revealRawValue: boolean
        }
      }
    },
  ): Promise<void> {
    const indyPresSpec = transformToIndyPresSpec(presentedProofs)

    await sdkSendProofPresentation(this.cloudAgentAPIs, {
      presentation: proofExId,
      values: indyPresSpec,
    })
  }

  async sendProblemReport(
    proofExId: ProofExchangeID,
    description: string,
  ): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.presentV10Proofs.presentProofRecordsPresExIdProblemReportPost(
        {
          presExId: proofExId,
          body: {
            description: description,
          },
        },
      ),
      logErrMsg: `Failed to send problem report for proof exchange with id ${proofExId}`,
    })
  }

  async deleteProofExchangeById(id: ProofExchangeID): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.presentV10Proofs.presentProofRecordsPresExIdDelete(
        {
          presExId: id,
        },
      ),
      logErrMsg: `Failed to delete proof exchange with id ${id}`,
    })
  }
}

function transformToProofExchangeList(
  v10PresentationExchanges: V10PresentationExchange[],
): ProofExchange[] {
  return v10PresentationExchanges.map((v10PresentationExchange) => {
    return transformToProofExchange(v10PresentationExchange)
  })
}

function transformToProofExchange(
  v10PresentationExchange: V10PresentationExchange,
): ProofExchange {
  return {
    id: v10PresentationExchange.presentation_exchange_id ?? '',
    connectionId: v10PresentationExchange.connection_id ?? '',
    name: v10PresentationExchange.presentation_request?.name ?? '',
    comment: v10PresentationExchange.presentation_request_dict?.comment ?? '',
    requestedProofs: {
      attributeGroups: transformToRequestedAttributeGroupList(
        v10PresentationExchange.presentation_request?.requested_attributes,
      ),
    },
    myRole: transformToProofExchangeRole(v10PresentationExchange.role),
    state: transformToProofExchangeState(v10PresentationExchange.state),
    updatedDatetime: convertIso8601ToDate(v10PresentationExchange.updated_at),
    debug: { 'SDK::V10PresentationExchange': v10PresentationExchange },
  }
}

function transformToRequestedAttributeGroupList(indyProofReqAttrSpecList?: {
  [groupId: string]: IndyProofReqAttrSpec
}): RequestedAttributeGroup[] {
  if (indyProofReqAttrSpecList === undefined) {
    return []
  }

  return Object.entries(indyProofReqAttrSpecList).map(
    (entry: [string, IndyProofReqAttrSpec]) => {
      const groupId = entry[0]
      const indyProofReqAttrSpec = entry[1]
      return transformToRequestedAttributeGroup(groupId, indyProofReqAttrSpec)
    },
  )
}

function transformToRequestedAttributeGroup(
  groupId: string,
  indyProofReqAttrSpec: IndyProofReqAttrSpec,
): RequestedAttributeGroup {
  let nonRevokedPeriod: NonRevokedPeriod | undefined = undefined
  let fromDateTime = undefined
  let toDateTime = undefined

  if (indyProofReqAttrSpec.non_revoked?.from) {
    fromDateTime = convertEpochToDate(indyProofReqAttrSpec.non_revoked?.from)
  }
  if (indyProofReqAttrSpec.non_revoked?.to) {
    toDateTime = convertEpochToDate(indyProofReqAttrSpec.non_revoked?.to)
  }
  if (fromDateTime || toDateTime) {
    nonRevokedPeriod = { fromDateTime: fromDateTime, toDateTime: toDateTime }
  }

  // The SDK will only set either "names" or "name", but never both.
  const attributeNames: string[] =
    indyProofReqAttrSpec.names !== undefined
      ? indyProofReqAttrSpec.names
      : indyProofReqAttrSpec.name !== undefined
      ? [indyProofReqAttrSpec.name]
      : []

  return {
    groupId: groupId,
    attributeNames: attributeNames,
    nonRevokedPeriod: nonRevokedPeriod,
    restrictions: transformToProofRestrictionList(
      indyProofReqAttrSpec?.restrictions,
    ),
  }
}

function transformToProofRestrictionList(
  restrictions?: {
    [key: string]: string
  }[],
): ProofRestriction[] {
  if (restrictions === undefined) {
    return []
  }

  return restrictions.map((restriction) => {
    return transformToProofRestriction(restriction)
  })
}

function transformToProofRestriction(restriction: {
  [key: string]: string
}): ProofRestriction {
  return {
    credDefId: restriction['cred_def_id'],
  }
}

function transformToProofExchangeState(state?: string): ProofExchangeState {
  switch (state) {
    case PresentProofRecordsGetStateEnum.ProposalSent:
      return ProofExchangeState.ProposalSent

    case PresentProofRecordsGetStateEnum.ProposalReceived:
      return ProofExchangeState.ProposalReceived

    case PresentProofRecordsGetStateEnum.RequestSent:
      return ProofExchangeState.RequestSent

    case PresentProofRecordsGetStateEnum.RequestReceived:
      return ProofExchangeState.RequestReceived

    case PresentProofRecordsGetStateEnum.PresentationSent:
      return ProofExchangeState.PresentationSent

    case PresentProofRecordsGetStateEnum.PresentationReceived:
      return ProofExchangeState.PresentationReceived

    case PresentProofRecordsGetStateEnum.Verified:
      return ProofExchangeState.PresentationVerified

    case PresentProofRecordsGetStateEnum.PresentationAcked:
      return ProofExchangeState.PresentationAcked

    case PresentProofRecordsGetStateEnum.Abandoned:
      return ProofExchangeState.Abandoned
  }
  return ProofExchangeState.Unknown
}

function transformToProofExchangeRole(
  role?: V10PresentationExchangeRoleEnum,
): ProofExchangeRole {
  switch (role) {
    case V10PresentationExchangeRoleEnum.Prover:
      return ProofExchangeRole.Prover

    case V10PresentationExchangeRoleEnum.Verifier:
      return ProofExchangeRole.Verifier
  }
  return ProofExchangeRole.Unknown
}

function transformToV10PresentationSendRequestRequest(
  connectionId: ConnectionID,
  name: string,
  comment: string,
  requestedProofs: {
    attributeGroupsById: {
      [groupId: string]: {
        attributeNames: string[]
        restrictions: {
          credDefId?: string
        }
        nonRevokedPeriod?: {
          to: Date
        }
      }
    }
  },
): V10PresentationSendRequestRequest {
  const requested_attributes: { [key: string]: IndyProofReqAttrSpec } = {}

  for (const [groupId, attributeGroup] of Object.entries(
    requestedProofs.attributeGroupsById,
  )) {
    const restrictions: { [key: string]: string }[] = []
    if (attributeGroup.restrictions.credDefId !== undefined) {
      restrictions.push({
        cred_def_id: attributeGroup.restrictions.credDefId,
      })
    }

    let non_revoked: IndyProofReqAttrSpecNonRevoked | undefined = undefined
    if (attributeGroup.nonRevokedPeriod !== undefined) {
      non_revoked = {
        /**
         * The Aries RFC recommendation is to NOT use the 'from' property of the non-revocation interval.
         * See: https://github.com/hyperledger/aries-rfcs/tree/main/concepts/0441-present-proof-best-practices
         * Therefore, we have hardcoded its value to 'undefined' here.
         */
        from: undefined,
        to: convertDateToEpoch(attributeGroup.nonRevokedPeriod.to),
      }
    }

    requested_attributes[groupId] = {
      names: attributeGroup.attributeNames,
      restrictions: restrictions,
      non_revoked: non_revoked,
    }
  }

  return {
    connection_id: connectionId,
    comment: comment,
    auto_verify: true,
    proof_request: {
      requested_attributes: requested_attributes,
      requested_predicates: {},
      name: name,
    },
  }
}

function transformToIndyPresSpec(presentedProofs: {
  attributeGroupsById: {
    [groupId: string]: {
      credentialId: CredentialID
      revealRawValue: boolean
    }
  }
}): IndyPresSpec {
  const requested_attributes: {
    [key: string]: IndyRequestedCredsRequestedAttr
  } = {}

  for (const [groupId, args] of Object.entries(
    presentedProofs.attributeGroupsById,
  )) {
    requested_attributes[groupId] = {
      cred_id: args.credentialId,
      revealed: args.revealRawValue,
    }
  }

  return {
    requested_attributes: requested_attributes,
    requested_predicates: {},
    self_attested_attributes: {},
  }
}
