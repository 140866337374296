import {
  PresentProof20RecordsGetStateEnum,
  V20PresExRecord,
  V20PresExRecordRoleEnum,
  V20PresExRecordVerifiedEnum,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import {
  CredentialSubject,
  PresentedLdProofVC,
  PresentedProofs,
  ProofPresentation,
  ProofPresentationRole,
} from '../../../domain/entities/W3C'
import { ApiDataIsNotW3cError } from '../../../domain/error-types'
import {
  DataForProofPresentationTableRowView,
  ProofPresentationGatewayInterface,
} from '../../../domain/gateway-interfaces/W3C'
import { convertIso8601ToDate } from '../../../utils/convertIso8601ToDate'
import { CloudAgentAPI } from '../sdk-wrappers/cloudAgent'
import { apiCall } from '../utils'
import { transformToProofExchange } from './ProofExchangeGateway'

export class ProofPresentationGateway
  implements ProofPresentationGatewayInterface
{
  constructor(readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getCompletedProofPresentationsList(): Promise<ProofPresentation[]> {
    const v20PresRecordList = await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsGet({
        state: PresentProof20RecordsGetStateEnum.Done,
      }),
      logErrMsg: `Failed to get proof record list`,
    })
    return transformToProofPresentationList(v20PresRecordList.results ?? [])
  }

  async getProofPresentationDetails(
    presExId: string,
  ): Promise<ProofPresentation> {
    const pres = await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsPresExIdGet(
        { presExId: presExId },
      ),
      logErrMsg: `Failed to get proof record with id ${presExId}`,
    })

    return transformToProofPresentation(pres)
  }

  async deleteProofPresentation(id: string): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsPresExIdDelete(
        {
          presExId: id,
        },
      ),
      logErrMsg: `Failed to delete proof record with id ${id}`,
    })
  }

  async getDataForProofPresentationTableView(): Promise<
    DataForProofPresentationTableRowView[]
  > {
    const v20PresExRecordList = await apiCall({
      run: this.cloudAgentAPIs.presentV20Proofs.presentProof20RecordsGet({
        state: PresentProof20RecordsGetStateEnum.Done,
      }),
      logErrMsg: `Failed to get proof presentations and proof exchanges for table view`,
    })

    return (v20PresExRecordList.results ?? []).map((v20PresExRecord) => {
      return {
        proofPresentation: transformToProofPresentation(v20PresExRecord),
        proofExchange: transformToProofExchange(v20PresExRecord),
      }
    })
  }
}

function transformToProofPresentationList(
  v20PresExRecords: V20PresExRecord[],
): ProofPresentation[] {
  const proofPresentations: ProofPresentation[] = []
  for (const v20PresExRecord of v20PresExRecords) {
    try {
      const proofPresentation = transformToProofPresentation(v20PresExRecord)
      proofPresentations.push(proofPresentation)
    } catch (error) {
      // for this list operation, ignore any proof presentations returned from the API which are not W3C
      if (error instanceof ApiDataIsNotW3cError) {
        continue
      }
      throw error
    }
  }
  return proofPresentations
}

function transformToProofPresentation(
  v20PresExRecord: V20PresExRecord,
): ProofPresentation {
  const sdkPresentation = v20PresExRecord.by_format?.pres as SdkPresentation

  if (sdkPresentation.dif === undefined) {
    throw new ApiDataIsNotW3cError()
  }

  return {
    id: v20PresExRecord.pres_ex_id ?? '',
    connectionId: v20PresExRecord.connection_id ?? '',
    presentedProofs: transformToPresentedProofs(sdkPresentation),
    verified: transformToVerified(v20PresExRecord.verified),
    myRole: transformToRole(v20PresExRecord.role),
    updatedDatetime: convertIso8601ToDate(v20PresExRecord.updated_at),
    debug: { 'SDK::V20PresExRecord': v20PresExRecord },
  }
}

function transformToPresentedProofs(
  sdkPresentation: SdkPresentation,
): PresentedProofs {
  const prefix = '$.verifiableCredential['
  const suffix = ']'

  const sdkVerifiableCredentials: SdkVerifiableCredential[] =
    sdkPresentation.dif?.verifiableCredential ?? []

  const sdkPresentationDescriptors =
    sdkPresentation.dif?.presentation_submission?.descriptor_map ?? []

  const presentedLdProofVCsByInputDescriptorId: {
    [inputDescriptorId: string]: PresentedLdProofVC
  } = {}

  for (const sdkPresentationDescriptor of sdkPresentationDescriptors) {
    if (sdkPresentationDescriptor.id === undefined) {
      continue
    }

    if (
      sdkPresentationDescriptor.format ===
      SdkPresentationSubmissionFormatEnum.ldp_vc
    ) {
      const path = sdkPresentationDescriptor.path ?? ''

      if (path.indexOf(prefix) !== 0) {
        continue
      }

      const indexOfSuffix = path.indexOf(suffix)
      if (indexOfSuffix === -1) {
        continue
      }

      const indexOfLdpVc = Number(path.substring(prefix.length, indexOfSuffix))
      if (indexOfLdpVc < 0 || indexOfLdpVc >= sdkVerifiableCredentials.length) {
        continue
      }

      const sdkVerifiableCredential: SdkVerifiableCredential =
        sdkVerifiableCredentials[indexOfLdpVc]

      presentedLdProofVCsByInputDescriptorId[sdkPresentationDescriptor.id] =
        transformToPresentedLdProofVC(sdkVerifiableCredential)
    }
  }

  return {
    presentedLdProofVCsByInputDescriptorId:
      presentedLdProofVCsByInputDescriptorId,
  }
}

function transformToPresentedLdProofVC(
  sdkVerifiableCredential: SdkVerifiableCredential,
): PresentedLdProofVC {
  return {
    context: sdkVerifiableCredential?.['@context'] ?? [],
    type: sdkVerifiableCredential?.type ?? [],
    issuanceDate: convertIso8601ToDate(sdkVerifiableCredential?.issuanceDate),
    issuerDid: transformToIssuerDid(sdkVerifiableCredential ?? {}),
    credentialSubject: (sdkVerifiableCredential?.credentialSubject ??
      {}) as CredentialSubject,
  }
}

function transformToIssuerDid(
  sdkVerifiableCredential: SdkVerifiableCredential,
): string {
  if (typeof sdkVerifiableCredential.issuer === 'object') {
    return sdkVerifiableCredential.issuer.id ?? ''
  }
  return sdkVerifiableCredential.issuer ?? ''
}

function transformToVerified(
  v20PresExVerified: V20PresExRecordVerifiedEnum | undefined,
): boolean | undefined {
  switch (v20PresExVerified) {
    case V20PresExRecordVerifiedEnum.True:
      return true
    case V20PresExRecordVerifiedEnum.False:
      return false
    default:
      return undefined
  }
}

function transformToRole(role: string | undefined): ProofPresentationRole {
  switch (role) {
    case V20PresExRecordRoleEnum.Prover:
      return ProofPresentationRole.Prover
    case V20PresExRecordRoleEnum.Verifier:
      return ProofPresentationRole.Verifier
    default:
      return ProofPresentationRole.Unknown
  }
}

/**
 * Assume all properties in the SDK response object are optional, in order to avoid crashes.
 */
interface SdkPresentation {
  // AnonCreds AIP-2.0 records will have an 'indy' property instead of 'dif'
  indy?: object
  dif?: {
    '@context'?: string[]
    type?: string[] // expected to always be ['VerifiablePresentation']
    verifiableCredential?: SdkVerifiableCredential[]
    presentation_submission?: {
      id?: string
      descriptor_map?: {
        id?: string // inputDescriptorId
        format?: SdkPresentationSubmissionFormatEnum
        path?: string
      }[]
    }
    proof?: object
  }
}

interface SdkVerifiableCredential {
  '@context'?: string[]
  type?: string[]
  issuer?:
    | {
        id?: string
      }
    | string
  issuanceDate?: string
  credentialSubject?: object
  proof?: object
}

enum SdkPresentationSubmissionFormatEnum {
  ldp = 'ldp',
  ldp_vc = 'ldp_vc',
  ldp_vp = 'ldp_vp',
  jwt = 'jwt',
  jwt_vc = 'jwt_vc',
  jwt_vp = 'jwt_vp',
}
