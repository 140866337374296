import React from "react";
import { CredentialsPageController } from "../../../modules/admin-console/controllers";

export interface CredentialsPageState {
  controller: CredentialsPageController;
}

export const CredentialsPageContext = React.createContext<CredentialsPageState>(
  undefined as never
);
