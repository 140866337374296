/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

/** Basic message record data type */
export type BasicMessage = {
  __typename?: 'BasicMessage';
  /** ID of the connection this message is associated with */
  connectionId: Scalars['ID']['output'];
  /** The message content */
  content: Scalars['String']['output'];
  /** Direction of the message */
  direction: BasicMessageDirection;
  /** Unique ID of the message */
  id: Scalars['ID']['output'];
  /**
   * Aggregated time stamp of the message in ISO 8601 format.
   * The semantics of this timestamp relate to the message direction.
   * - For incoming messages this is the time the service receives the message
   * - For outgoing messages this is the time the service sends the message
   */
  timestamp?: Maybe<Scalars['String']['output']>;
};

/** Send direction of a basic message */
export enum BasicMessageDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

/** Include filter for the basic messages query */
export type BasicMessagesFilter = {
  /** Filter on a specific connection ID */
  connectionId: Scalars['ID']['input'];
};

/** Paged result container of the 'basicMessages' query */
export type BasicMessagesResult = {
  __typename?: 'BasicMessagesResult';
  /** The result items */
  items: Array<BasicMessage>;
  /** The next page token, or null if no more pages exist */
  nextToken?: Maybe<Scalars['String']['output']>;
};

/** Paged result container of the 'broadcastBasicMessage' mutation */
export type BroadcastBasicMessageResult = {
  __typename?: 'BroadcastBasicMessageResult';
  /** The connection IDs of all peers that the message was successfully sent to */
  connectionIds: Array<Scalars['ID']['output']>;
};

/** Connection record data type */
export type Connection = {
  __typename?: 'Connection';
  /** Basic messages associated with the connection */
  basicMessages: BasicMessagesResult;
  /** The time the connection was created in ISO 8601 format */
  createdTime: Scalars['String']['output'];
  /** Unique identifier of the connection */
  id: Scalars['ID']['output'];
  /** The locally assigned label of the connection */
  label: Scalars['String']['output'];
  /** The label provided by the connection's peer */
  theirLabel: Scalars['String']['output'];
  /** The time the connection was last updated in ISO 8601 format */
  updatedTime: Scalars['String']['output'];
};


/** Connection record data type */
export type ConnectionBasicMessagesArgs = {
  page: Page;
};

/** Input args to the 'connections' query */
export type ConnectionsFilter = {
  /**
   * Optional filter on connections related to their with basic messages count
   *  - If true, only include connections that have >= 1 message
   *  - If false, only include connections that have 0 messages
   *  - If null or not supplied (default), include all connections
   */
  hasBasicMessages?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Result type of the 'connections' query */
export type ConnectionsResult = {
  __typename?: 'ConnectionsResult';
  /** The result items */
  items: Array<Connection>;
  /** The next page token, or null if no more pages exist */
  nextToken?: Maybe<Scalars['String']['output']>;
};

/** Result from the delete basic message mutation */
export type DeleteBasicMessagesResult = {
  __typename?: 'DeleteBasicMessagesResult';
  /** The message IDs of all items that were deleted */
  messageIds: Array<Scalars['ID']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Broadcasts a basic message to peers of a set of established connections. A new basic message record is created per connection.
   *
   * Returns a BroadcastBasicMessageResult object with the connection IDs of all peers that the message was successfully sent to.
   */
  broadcastBasicMessage: BroadcastBasicMessageResult;
  /**
   * Deletes basic message records by ID. The messages are deleted locally, not on the other end of the connection.
   *
   * Returns a DeleteBasicMessagesResult object with the message IDs of all messages that were deleted.
   */
  deleteBasicMessages: DeleteBasicMessagesResult;
  /**
   * Deletes _all_ basic message records for a given connection. The messages are deleted locally, not on the other end of the connection.
   *
   * Returns true if the operation completes with records deleted, false if not.
   */
  deleteBasicMessagesForConnection: Scalars['Boolean']['output'];
  /**
   * Deletes a connection.
   *
   * Returns true if the operation completes with records deleted, false if not.
   */
  deleteConnection: Scalars['Boolean']['output'];
  /**
   * Sends a basic message to a peer of and established connection.
   *
   * Returns the BasicMessage record that was created if the message was sent successfully.
   */
  sendBasicMessage: BasicMessage;
};


export type MutationBroadcastBasicMessageArgs = {
  connectionIds: Array<Scalars['ID']['input']>;
  content: Scalars['String']['input'];
};


export type MutationDeleteBasicMessagesArgs = {
  messageIds: Array<Scalars['ID']['input']>;
};


export type MutationDeleteBasicMessagesForConnectionArgs = {
  connectionId: Scalars['ID']['input'];
};


export type MutationDeleteConnectionArgs = {
  connectionId: Scalars['ID']['input'];
};


export type MutationSendBasicMessageArgs = {
  connectionId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
};

/** Page information for queries that support pagination */
export type Page = {
  /** The maximum number of items to return in the page */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** The token of the next page */
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * Gets a page of basic message records.
   *
   * Returns a BasicMessagesResult object with the basic messages that match the supplied input filters.
   */
  basicMessages: BasicMessagesResult;
  /**
   * Gets information about a connection.
   *
   * Returns the Connection record if the connection with the supplied ID exists.
   */
  connection?: Maybe<Connection>;
  /**
   * Gets a page of connections records.
   *
   * Returns a ConnectionsResult object with the connections that match the supplied input filters.
   */
  connections: ConnectionsResult;
};


export type QueryBasicMessagesArgs = {
  filter: BasicMessagesFilter;
  page: Page;
};


export type QueryConnectionArgs = {
  connectionId: Scalars['ID']['input'];
};


export type QueryConnectionsArgs = {
  filter?: InputMaybe<ConnectionsFilter>;
  page: Page;
};

export type SendMessageMutationVariables = Exact<{
  connectionId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendBasicMessage: { __typename?: 'BasicMessage', connectionId: string, content: string } };

export type BroadcastMessageMutationVariables = Exact<{
  connectionIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;


export type BroadcastMessageMutation = { __typename?: 'Mutation', broadcastBasicMessage: { __typename?: 'BroadcastBasicMessageResult', connectionIds: Array<string> } };

export type DeleteMessagesMutationVariables = Exact<{
  connectionId: Scalars['ID']['input'];
}>;


export type DeleteMessagesMutation = { __typename?: 'Mutation', deleteBasicMessagesForConnection: boolean };

export type QueryQueryVariables = Exact<{
  connectionId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type QueryQuery = { __typename?: 'Query', basicMessages: { __typename?: 'BasicMessagesResult', nextToken?: string | null, items: Array<{ __typename?: 'BasicMessage', id: string, content: string, timestamp?: string | null, direction: BasicMessageDirection }> } };


export const SendMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"content"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendBasicMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"connectionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}}},{"kind":"Argument","name":{"kind":"Name","value":"content"},"value":{"kind":"Variable","name":{"kind":"Name","value":"content"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connectionId"}},{"kind":"Field","name":{"kind":"Name","value":"content"}}]}}]}}]} as unknown as DocumentNode<SendMessageMutation, SendMessageMutationVariables>;
export const BroadcastMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"BroadcastMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"connectionIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"content"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"broadcastBasicMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"connectionIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"connectionIds"}}},{"kind":"Argument","name":{"kind":"Name","value":"content"},"value":{"kind":"Variable","name":{"kind":"Name","value":"content"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connectionIds"}}]}}]}}]} as unknown as DocumentNode<BroadcastMessageMutation, BroadcastMessageMutationVariables>;
export const DeleteMessagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteMessages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteBasicMessagesForConnection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"connectionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}}}]}]}}]} as unknown as DocumentNode<DeleteMessagesMutation, DeleteMessagesMutationVariables>;
export const QueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Query"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"basicMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"page"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"connectionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"timestamp"}},{"kind":"Field","name":{"kind":"Name","value":"direction"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<QueryQuery, QueryQueryVariables>;