import React, { useState } from "react";
import { authenticateUser } from "../../modules/session/cognito-srp";
import { Button, Form, Input, Space, message } from "antd";
import "./sign-in.css";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../modules/session/auth-guard";

export const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [signingIn, setSigningIn] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(isAuthenticated());

  type FieldType = {
    username?: string;
    password?: string;
  };

  const signIn = (values: any) => {
    setSigningIn(true);
    authenticateUser(
      values.username,
      values.password,
      (token) => {
        setSigningIn(false);
        localStorage.setItem("accessToken", token);
        setIsSignedIn(true);
        window.location.reload();
      },
      (err) => {
        setSigningIn(false);
        messageApi.open({
          type: "error",
          content: err.message || JSON.stringify(err),
        });
      }
    );
  };

  const signOut = () => {
    localStorage.removeItem("accessToken");
    setIsSignedIn(false);
  };

  return (
    <div className="welcomePage">
      {contextHolder}
      <div className="welcomeCard">
        <div className="branding">
          <img src="/anonyome.png" alt="anonyome" className="logo" />
        </div>
        {!isSignedIn ? (
          <>
            <div className="title">Sign In</div>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={signIn}
              autoComplete="off"
            >
              <Form.Item<FieldType>
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={signingIn}>
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <Space>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  navigate("/admin");
                }}
              >
                Admin
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  navigate("/customer");
                }}
              >
                Customer
              </Button>
            </Space>
            <Button type="default" danger onClick={signOut}>
              Sign out
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
