import {
  PresentProofRecordsGetStateEnum,
  V10PresentationExchange,
  V10PresentationExchangeVerifiedEnum,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import {
  IndyProofReqAttrSpec,
  IndyProofRequestedProofRevealedAttr,
  IndyProofRequestedProofRevealedAttrGroup,
} from '@sudoplatform-labs/sudo-di-cloud-agent/lib/models'
import {
  ProofPresentation,
  ProofPresentationID,
  RevealedAttributeGroup,
  UnrevealedAttributeGroup,
} from '../../domain/entities'
import { ProofPresentationGatewayInterface } from '../../domain/gateway-interfaces'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'
import { fetchFilteredProofExchangeRecords } from './sdk-wrappers/ProofPresentation'
import { apiCall } from './utils'

export class ProofPresentationGateway
  implements ProofPresentationGatewayInterface
{
  constructor(readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getProofPresentationsList(): Promise<ProofPresentation[]> {
    const v10PresentationExchanges: V10PresentationExchange[] =
      await fetchFilteredProofExchangeRecords(this.cloudAgentAPIs, {
        states: [
          PresentProofRecordsGetStateEnum.Verified,
          PresentProofRecordsGetStateEnum.PresentationAcked,
        ],
      })

    return transformToProofPresentationList(v10PresentationExchanges)
  }

  async deleteProofPresentationById(id: ProofPresentationID): Promise<void> {
    await apiCall({
      run: this.cloudAgentAPIs.presentV10Proofs.presentProofRecordsPresExIdDelete(
        {
          presExId: id,
        },
      ),
      logErrMsg: `Failed to delete proof presentation with id ${id}`,
    })
  }
}

function transformToProofPresentationList(
  v10PresentationExchanges: V10PresentationExchange[],
): ProofPresentation[] {
  return v10PresentationExchanges.map((v10PresentationExchange) => {
    return transformToProofPresentation(v10PresentationExchange)
  })
}

function transformToProofPresentation(
  v10PresentationExchange: V10PresentationExchange,
): ProofPresentation {
  const requested_proof = v10PresentationExchange?.presentation?.requested_proof

  const revealedAttributeGroups = transformToRevealedAttributeGroupList(
    requested_proof?.revealed_attr_groups ?? {},
    requested_proof?.revealed_attrs ?? {},
    v10PresentationExchange.presentation_request?.requested_attributes ?? {},
  )

  const unrevealedAttributeGroups = transformToUnrevealedAttributeGroupList(
    v10PresentationExchange.presentation?.requested_proof?.unrevealed_attrs ??
      {},
  )

  return {
    /**
     * ProofPresentations do not have IDs, so we must use their associated
     * ProofExchanges to identify them.
     */
    id: v10PresentationExchange.presentation_exchange_id ?? '',
    connectionId: v10PresentationExchange.connection_id ?? '',
    presentedProofs: {
      revealedAttributeGroups: revealedAttributeGroups,
      unrevealedAttributeGroups: unrevealedAttributeGroups,
    },
    verified: transformToVerified(v10PresentationExchange.verified),
    debug: { 'SDK::V10PresentationExchange': v10PresentationExchange },
  }
}

function transformToVerified(
  v10PresentationVerified: V10PresentationExchangeVerifiedEnum | undefined,
): boolean | undefined {
  switch (v10PresentationVerified) {
    case V10PresentationExchangeVerifiedEnum.True:
      return true
    case V10PresentationExchangeVerifiedEnum.False:
      return false
    default:
      return undefined
  }
}

function transformToRevealedAttributeGroupList(
  revealed_attr_groups: {
    [key: string]: IndyProofRequestedProofRevealedAttrGroup
  },
  revealed_attrs: {
    [key: string]: IndyProofRequestedProofRevealedAttr
  },
  requested_attributes: {
    [key: string]: IndyProofReqAttrSpec
  },
): RevealedAttributeGroup[] {
  const attributeGroups = Object.entries(revealed_attr_groups).map(
    (
      entry: [string, IndyProofRequestedProofRevealedAttrGroup],
    ): RevealedAttributeGroup => {
      const attributeValuesByName: { [name: string]: string } = {}

      const values = entry[1].values ?? {}
      for (const [attributeName, value] of Object.entries(values)) {
        attributeValuesByName[attributeName] = value.raw ?? ''
      }

      return {
        groupId: entry[0],
        attributeValuesByName: attributeValuesByName,
      }
    },
  )

  const singleAttributeGroups = Object.entries(revealed_attrs).map(
    (
      entry: [string, IndyProofRequestedProofRevealedAttr],
    ): RevealedAttributeGroup => {
      const groupId = entry[0]
      const attributeName = requested_attributes[groupId].name ?? ''

      return {
        groupId: groupId,
        attributeValuesByName: {
          [attributeName]: entry[1].raw ?? '',
        },
      }
    },
  )

  return attributeGroups.concat(singleAttributeGroups)
}

function transformToUnrevealedAttributeGroupList(
  unrevealed_attrs: object,
): UnrevealedAttributeGroup[] {
  return Object.keys(unrevealed_attrs).map((groupId: string) => {
    return {
      groupId: groupId,
    }
  })
}
