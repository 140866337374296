import { ConnectionExchange } from '../../entities'
import { ConnectionExchangeGatewayInterface } from '../../gateway-interfaces'

export class GetConnectionExchangeList {
  constructor(
    private readonly connectionGateway: ConnectionExchangeGatewayInterface,
  ) {}

  async execute(): Promise<ConnectionExchange[]> {
    return await this.connectionGateway.getConnectionExchangeList()
  }
}
