import {
  CredAttrSpec,
  IssueCredentialRecordsGetStateEnum,
  V10CredentialExchange,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import { V10CredentialExchangeRoleEnum } from '@sudoplatform-labs/sudo-di-cloud-agent/lib/models/V10CredentialExchange'
import {
  ConnectionID,
  Credential,
  CredentialExchange,
  CredentialExchangeID,
  CredentialExchangeRole,
  CredentialExchangeState,
} from '../../domain/entities'
import { ConnectionNotFoundError } from '../../domain/error-types'
import { CredentialExchangeGatewayInterface } from '../../domain/gateway-interfaces'
import { convertIso8601ToDate } from '../../utils/convertIso8601ToDate'
import {
  deleteCredentialExchangeRecord,
  fetchCredentialExchangeRecord,
  fetchFilteredCredentialExchangeRecords,
  issueCredentialSendOffer,
  requestProposedCredential,
  sendProblemReport,
  storeCredential,
} from './sdk-wrappers/CredentialIssuance'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'

export class CredentialExchangeGateway
  implements CredentialExchangeGatewayInterface
{
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getCredExById(id: CredentialExchangeID): Promise<CredentialExchange> {
    const v10CredentialExchange = await fetchCredentialExchangeRecord(
      this.cloudAgentAPIs,
      id,
    )
    return transformToCredentialExchange(v10CredentialExchange)
  }

  async getInProgressCredExList(): Promise<CredentialExchange[]> {
    const v10CredentialExchanges = await fetchFilteredCredentialExchangeRecords(
      this.cloudAgentAPIs,
      {
        states: [
          IssueCredentialRecordsGetStateEnum.ProposalSent,
          IssueCredentialRecordsGetStateEnum.ProposalReceived,
          IssueCredentialRecordsGetStateEnum.OfferSent,
          IssueCredentialRecordsGetStateEnum.OfferReceived,
          IssueCredentialRecordsGetStateEnum.RequestSent,
          IssueCredentialRecordsGetStateEnum.RequestReceived,
          IssueCredentialRecordsGetStateEnum.CredentialReceived,
          IssueCredentialRecordsGetStateEnum.Abandoned,
        ],
      },
    )

    return transformToCredentialExchangeList(v10CredentialExchanges)
  }

  async getCompletedCredExList(): Promise<CredentialExchange[]> {
    const v10CredentialExchanges = await fetchFilteredCredentialExchangeRecords(
      this.cloudAgentAPIs,
      {
        states: [
          IssueCredentialRecordsGetStateEnum.CredentialIssued,
          IssueCredentialRecordsGetStateEnum.CredentialAcked,
          IssueCredentialRecordsGetStateEnum.CredentialRevoked,
        ],
      },
    )

    return transformToCredentialExchangeList(v10CredentialExchanges)
  }

  async sendCredentialOffer(args: {
    connectionId: ConnectionID
    credential: Pick<Credential, 'credDefId' | 'attributeValuesByName'>
    comment?: string
  }): Promise<void> {
    const credAttrSpecs: CredAttrSpec[] = Object.entries(
      args.credential.attributeValuesByName,
    ).map(([name, value]) => {
      return { name: name, value: value }
    })

    try {
      await issueCredentialSendOffer(this.cloudAgentAPIs, {
        credential_definition_id: args.credential.credDefId,
        connection_id: args.connectionId,
        comment: args.comment,
        proposal: { attributes: credAttrSpecs },
        auto_remove: false,
        auto_issue: true,
      })
    } catch (error) {
      if ((error as Error).message.includes(`400: Record not found`)) {
        throw new ConnectionNotFoundError()
      }
      if (
        // This case (most likely) handles the case in which the custom connection ID is not a valid UUID, although another error could be the cause as well.
        (error as Error).message.includes(
          `Response constructor: Body has already been consumed.`,
        )
      ) {
        throw new ConnectionNotFoundError()
      }
      throw error
    }
  }

  async sendRequestForOfferedCredential(
    credExId: CredentialExchangeID,
  ): Promise<void> {
    await requestProposedCredential(this.cloudAgentAPIs, credExId)
  }

  async storeReceivedCredential(credExId: CredentialExchangeID): Promise<void> {
    await storeCredential(this.cloudAgentAPIs, credExId)
  }

  async sendProblemReport(
    credExId: CredentialExchangeID,
    description: string,
  ): Promise<void> {
    await sendProblemReport(this.cloudAgentAPIs, credExId, description)
  }

  async deleteCredExById(id: CredentialExchangeID): Promise<void> {
    await deleteCredentialExchangeRecord(this.cloudAgentAPIs, id)
  }
}

function transformToCredentialExchangeList(
  v10CredentialExchanges: V10CredentialExchange[],
): CredentialExchange[] {
  const credentialExchanges = []
  for (const v10CredentialExchange of v10CredentialExchanges) {
    const credentialExchange = transformToCredentialExchange(
      v10CredentialExchange,
    )
    credentialExchanges.push(credentialExchange)
  }
  return credentialExchanges
}

function transformToCredentialExchange(
  v10CredentialExchange: V10CredentialExchange,
): CredentialExchange {
  return {
    updatedDatetime: convertIso8601ToDate(v10CredentialExchange.updated_at),
    id: v10CredentialExchange.credential_exchange_id ?? '',
    credentialId: v10CredentialExchange.credential_id ?? '',
    credentialOffer: {
      schemaId: v10CredentialExchange.credential_offer?.schema_id ?? '',
      credDefId: v10CredentialExchange.credential_offer?.cred_def_id ?? '',
      attributes:
        v10CredentialExchange.credential_offer_dict?.credential_preview
          ?.attributes ?? [],
    },
    connectionId: v10CredentialExchange.connection_id ?? '',
    comment: v10CredentialExchange.credential_offer_dict?.comment ?? '',
    state: transformState(v10CredentialExchange.state),
    myRole: transformRole(v10CredentialExchange.role),
    debug: { 'SDK::V10CredentialExchange': v10CredentialExchange },
  }
}

function transformState(state?: string): CredentialExchangeState {
  switch (state) {
    case IssueCredentialRecordsGetStateEnum.ProposalSent:
      return CredentialExchangeState.ProposalSent
    case IssueCredentialRecordsGetStateEnum.ProposalReceived:
      return CredentialExchangeState.ProposalReceived
    case IssueCredentialRecordsGetStateEnum.OfferSent:
      return CredentialExchangeState.OfferSent
    case IssueCredentialRecordsGetStateEnum.OfferReceived:
      return CredentialExchangeState.OfferReceived
    case IssueCredentialRecordsGetStateEnum.RequestSent:
      return CredentialExchangeState.RequestSent
    case IssueCredentialRecordsGetStateEnum.RequestReceived:
      return CredentialExchangeState.RequestReceived
    case IssueCredentialRecordsGetStateEnum.CredentialIssued:
      return CredentialExchangeState.CredentialIssued
    case IssueCredentialRecordsGetStateEnum.CredentialReceived:
      return CredentialExchangeState.CredentialReceived
    case IssueCredentialRecordsGetStateEnum.CredentialAcked:
      return CredentialExchangeState.CredentialAcked
    case IssueCredentialRecordsGetStateEnum.CredentialRevoked:
      return CredentialExchangeState.CredentialRevoked
    case IssueCredentialRecordsGetStateEnum.Abandoned:
      return CredentialExchangeState.Abandoned
  }
  return CredentialExchangeState.Unknown
}

function transformRole(
  role?: V10CredentialExchangeRoleEnum,
): CredentialExchangeRole {
  switch (role) {
    case V10CredentialExchangeRoleEnum.Holder:
      return CredentialExchangeRole.Holder
    case V10CredentialExchangeRoleEnum.Issuer:
      return CredentialExchangeRole.Issuer
  }
  return CredentialExchangeRole.Unknown
}
