import { ConnectionID, CredDefID, SchemaID } from '../entities'

export type ProofExchangeID = string

export type ProofRestriction = {
  readonly credDefId?: CredDefID
  readonly schemaId?: SchemaID
  readonly schemaIssuerDid?: string
  readonly schemaName?: string
  readonly schemaVersion?: string
  readonly issuerDid?: string
  readonly customCredentialAttributeName?: string
}

export type NonRevokedPeriod = {
  readonly fromDateTime?: Date
  readonly toDateTime?: Date
}

export type RequestedAttributeGroup = {
  readonly groupId: string
  readonly attributeNames: string[]
  readonly nonRevokedPeriod?: NonRevokedPeriod
  readonly restrictions: ProofRestriction[]
}

/**
 * TODO: Add support for other proof types:
 *  readonly selfAttestedAttributes: RequestedSelfAttestedAttribute[]
 *  readonly predicates: RequestedPredicate[]
 *  etc.
 */
export type RequestedProofs = {
  readonly attributeGroups: RequestedAttributeGroup[]
}

export enum ProofExchangeRole {
  Unknown = 'Unknown',
  Prover = 'Prover',
  Verifier = 'Verifier',
}

export enum ProofExchangeState {
  Unknown = 'Unknown',
  ProposalSent = 'ProposalSent',
  ProposalReceived = 'ProposalReceived',
  RequestSent = 'RequestSent',
  RequestReceived = 'RequestReceived',
  PresentationSent = 'PresentationSent',
  PresentationReceived = 'PresentationReceived',
  PresentationVerified = 'PresentationVerified',
  PresentationAcked = 'PresentationAcked',
  Abandoned = 'Abandoned',
}

/**
 * The ProofExchange entity contains a request for proofs,
 * but contains no values received in response to the request.
 * A ProofExchange results in a ProofPresentation,
 * which contains the values received in response to the request.
 */
export type ProofExchange = {
  readonly id: ProofExchangeID
  readonly connectionId: ConnectionID
  readonly name: string
  readonly comment: string
  readonly requestedProofs: RequestedProofs
  readonly myRole: ProofExchangeRole
  readonly state: ProofExchangeState
  readonly updatedDatetime?: Date
  readonly debug?: object
}
