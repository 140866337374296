import { CloudAgentConfig } from '../../domain/entities'
import { CloudAgentConfigGatewayInterface } from '../../domain/gateway-interfaces'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'

import { AdminConfig } from '@sudoplatform-labs/sudo-di-cloud-agent'

import { fetchServerConfig } from './sdk-wrappers/Server'

export class CloudAgentConfigGateway
  implements CloudAgentConfigGatewayInterface
{
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getCloudAgentConfig(): Promise<CloudAgentConfig> {
    const result: AdminConfig = await fetchServerConfig(this.cloudAgentAPIs)
    const configObject = result.config as SdkAdminConfig

    const cloudAgentConfig: CloudAgentConfig = {
      defaultLabel: configObject.default_label ?? '',
      ledgerGenesisUrl: configObject['ledger.genesis_url'] ?? '',
    }

    return cloudAgentConfig
  }
}

interface SdkAdminConfig {
  default_label: string
  'ledger.genesis_url': string
}
