import React, { useCallback, useState } from "react";
import { createWorker } from "tesseract.js";
import { CapturePicture } from "./capture-picture";
import { Modal } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

import { Button, Select, Space } from "antd";

type ModalState = "take-picture-front" | "take-picture-back" | "closed";

export async function testOCR(): Promise<Tesseract.Worker> {
  try {
    const worker = await createWorker("eng");
    return worker;
  } catch (error) {
    throw error;
  }
}

interface CustomerDashboardTabNavigationControl {
  setDone: React.Dispatch<React.SetStateAction<boolean>>;
}

export const IDCheckCard: React.FC<
  CustomerDashboardTabNavigationControl
> = (props) => {

  const cardTitle = "Upload ID";
  const notes =
    "These documents will be securely uploaded to Anonyome Credit Union, and will be verified to confirm your identity and membership.";
  const idTypeFront = "Drivers License Front";
  const idTypeBack = "Drivers License Back";
  const [selectedOption, setSelectedOption] = useState("");
  const [hasCapturedFront, setHasCapturedFront] = useState(false);
  const [hasCapturedBack, setHasCapturedBack] = useState(false);

  const [modalState, setModalState] = useState<ModalState>("closed");

  function updateHasCapturedFront(v:boolean) {
    setHasCapturedFront(v);
    props.setDone(v);
  }
  const closeModal = useCallback(() => {
    setModalState("closed");
  }, []);

  return (
    <div className="cardContent">
      <div className="cardTitle">{cardTitle}</div>
      <p className="notes">{notes}</p>

      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <Select
          className="select"
          defaultValue="Select ID Type"
          onChange={(value) => {
            setSelectedOption(value);
          }}
          options={[
            { value: "option1", label: "Driver's License" },
            { value: "option2", label: "Other ID" },
          ]}
        />

        {selectedOption === 'option1' && (
          <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
            <div className="idContainer">
              <h1 className="subTitle">{idTypeFront}</h1>
              {!hasCapturedFront ? (
                <Space>
                  <Button
                    id="idSelectfileFront-btn"
                    type="primary"
                    className="secondaryButton"
                    disabled={false}
                  >
                    Select File
                  </Button>
                  <Button
                    id="idTakePictureFront-btn"
                    type="primary"
                    onClick={() => setModalState("take-picture-front")}
                    disabled={false}
                  >
                    Take Picture
                  </Button>
                </Space>
              ) : (
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    gap: 8,
                  }}
                >
                  <CheckCircleFilled
                    rev={undefined}
                    style={{ color: "green" }}
                  />
                  <span>Submitted</span>
                  <div className="spacer"></div>
                  <CloseCircleFilled
                    rev={undefined}
                    onClick={() => {
                      updateHasCapturedFront(false);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="idContainer">
              <h1 className="subTitle">{idTypeBack}</h1>
              {!hasCapturedBack ? (
                <Space>
                  <Button
                    id="idSelectfileBack-btn"
                    type="primary"
                    className="secondaryButton"
                    disabled={false}
                  >
                    Select File
                  </Button>
                  <Button
                    id="idTakePictureBack-btn"
                    type="primary"
                    onClick={() => setModalState("take-picture-back")}
                    disabled={false}
                  >
                    Take Picture
                  </Button>
                </Space>
              ) : (
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    gap: 8,
                  }}
                >
                  <CheckCircleFilled
                    rev={undefined}
                    style={{ color: "green" }}
                  />
                  <span>Submitted</span>
                  <div className="spacer"></div>
                  <CloseCircleFilled
                    rev={undefined}
                    onClick={() => {
                      setHasCapturedBack(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {selectedOption === 'option2' && (
          <div>
            Coming soon ...
          </div>
        )}

        <Modal
          open={modalState !== "closed"}
          onCancel={closeModal}
          footer={null}
          width={"50%"}
        >
          <CapturePicture
            setHasCaptured={
              modalState === "take-picture-front"
                ? updateHasCapturedFront
                : setHasCapturedBack
            }
            modalState={modalState}
            onClose={closeModal}
          />
        </Modal>
      </div>
    </div>
  );
};
