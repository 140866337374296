import React from 'react'

export const SpinnerOverlay: React.FC = () => {
  return (
    <div className="spinner-overlay">
      <div
        className="spinner-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          alignItems: 'center',
        }}
      >
        <div
          className="spinner"
          style={{ transform: 'translateX(-50%)', left: '50%' }}
        />
        <p>Processing...</p>
      </div>
    </div>
  )
}
