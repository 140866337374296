import {
  Configuration,
  ConnectionApi,
  CredentialDefinitionApi,
  CredentialsApi,
  IntroductionApi,
  IssueCredentialV10Api,
  IssueCredentialV20Api,
  LedgerApi,
  PresentProofV10Api,
  PresentProofV20Api,
  RevocationApi,
  SchemaApi,
  ServerApi,
  TrustpingApi,
  WalletApi,
} from "@sudoplatform-labs/sudo-di-cloud-agent";
import {
  ConnectionExchangeGateway,
  ConnectionGateway,
  CredDefGateway,
  CredentialExchangeGateway,
  CredentialGateway,
  ProofExchangeGateway,
  ProofPresentationGateway,
  SchemaGateway,
  TaaGateway,
} from "./admin-console/gateways/sudo-di-cloud-agent";
import {
  CredentialExchangeGateway as W3CCredentialExchangeGateway,
  CredentialGateway as W3CCredentialGateway,
  ProofExchangeGateway as W3CProofExchangeGateway,
  ProofPresentationGateway as W3CProofPresentationGateway,
} from "./admin-console/gateways/sudo-di-cloud-agent/W3C";
import { CloudAgentConfigGateway } from "./admin-console/gateways/sudo-di-cloud-agent/CloudAgentConfigGateway";
import { MessagesGateway } from "./admin-console/gateways/sudo-di-cloud-agent/MessagesGateway";
import { CloudAgentAPI } from "./admin-console/gateways/sudo-di-cloud-agent/sdk-wrappers/cloudAgent";
import { CloudAgentGateways } from "./admin-console/gateways/sudo-di-cloud-agent/CloudAgentGateways";
import { DIDGateway } from "./admin-console/gateways/sudo-di-cloud-agent/DIDGateway";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { getAccessToken } from "./session/auth-guard";
import { Environment } from "../environment";

const createCloudAgentAPIsAndGateways = (): {
  cloudAgentAPIs: CloudAgentAPI;
  cloudAgentGateways: CloudAgentGateways;
} => {
  const accessToken = getAccessToken()!;

  const apolloClient = new ApolloClient({
    uri: Environment.cloudAgent.graphQlUrl,
    cache: new InMemoryCache(),
    headers: { Authorization: accessToken },
  });

  const cloudAgentConfiguration = new Configuration({
    basePath: Environment.cloudAgent.adminBaseUrl,
    headers: { Authorization: accessToken },
  });

  const cloudAgentAPIs: CloudAgentAPI = {
    wallet: new WalletApi(cloudAgentConfiguration),
    ledger: new LedgerApi(cloudAgentConfiguration),
    introductions: new IntroductionApi(cloudAgentConfiguration),
    connections: new ConnectionApi(cloudAgentConfiguration),
    ping: new TrustpingApi(cloudAgentConfiguration),
    defineSchemas: new SchemaApi(cloudAgentConfiguration),
    defineCredentials: new CredentialDefinitionApi(cloudAgentConfiguration),
    issueV10Credentials: new IssueCredentialV10Api(cloudAgentConfiguration),
    issueV20Credentials: new IssueCredentialV20Api(cloudAgentConfiguration),
    revocations: new RevocationApi(cloudAgentConfiguration),
    credentials: new CredentialsApi(cloudAgentConfiguration),
    presentV10Proofs: new PresentProofV10Api(cloudAgentConfiguration),
    presentV20Proofs: new PresentProofV20Api(cloudAgentConfiguration),
    httpOptionOverrides: {
      httpPostOptionOverrides: {
        headers: { "Content-Type": "application/json" },
      },
    },
    server: new ServerApi(cloudAgentConfiguration),
  };

  const cloudAgentGateways: CloudAgentGateways = {
    schemaGateway: new SchemaGateway(cloudAgentAPIs),
    credDefGateway: new CredDefGateway(cloudAgentAPIs),
    credentialGateway: new CredentialGateway(cloudAgentAPIs),
    credExGateway: new CredentialExchangeGateway(cloudAgentAPIs),
    connectionGateway: new ConnectionGateway(cloudAgentAPIs),
    connectionExGateway: new ConnectionExchangeGateway(cloudAgentAPIs),
    proofExGateway: new ProofExchangeGateway(cloudAgentAPIs),
    proofPresGateway: new ProofPresentationGateway(cloudAgentAPIs),
    taaGateway: new TaaGateway(cloudAgentAPIs),
    didGateway: new DIDGateway(cloudAgentAPIs),
    cloudAgentConfigGateway: new CloudAgentConfigGateway(cloudAgentAPIs),
    messagesGateway: new MessagesGateway(apolloClient),
    w3cCredExGateway: new W3CCredentialExchangeGateway(cloudAgentAPIs),
    w3cCredentialGateway: new W3CCredentialGateway(cloudAgentAPIs),
    w3cProofExGateway: new W3CProofExchangeGateway(cloudAgentAPIs),
    w3cProofPresGateway: new W3CProofPresentationGateway(cloudAgentAPIs),
  };

  return { cloudAgentAPIs, cloudAgentGateways };
};

const cloudAgent = createCloudAgentAPIsAndGateways();

export const cloudAgentAPIs = cloudAgent.cloudAgentAPIs;
export const cloudAgentGateways = cloudAgent.cloudAgentGateways;
