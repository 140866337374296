import {
  CloudAgentConfig,
  ConnectionExchange,
  ConnectionExchangeRole,
  ConnectionExchangeState,
  ConnectionInvitation,
} from '../domain/entities'
import {
  CloudAgentConfigGatewayInterface,
  ConnectionExchangeGatewayInterface,
  ConnectionGatewayInterface,
} from '../domain/gateway-interfaces'
import {
  AcceptConnectionInvitation,
  CreateConnectionInvitation,
  DeleteConnectionExchange,
  GetConnectionExchangeList,
  GetConnectionList,
} from '../domain/use-cases'
import { GetCloudAgentConfig } from '../domain/use-cases/cloudAgentConfig/GetCloudAgentConfig'
import {
  ConnectionExchangeListItemViewModel,
  ConnectionExchangeListViewModel,
  ConnectionExchangeRoleViewModel,
  ConnectionExchangeStateViewModel,
  ConnectionInvitationViewModel,
} from './view-models'

export class ConnectionExchangePageController {
  constructor(
    private readonly connectionExchangesGateway: ConnectionExchangeGatewayInterface,
    private readonly connectionGateway: ConnectionGatewayInterface,
    private readonly cloudAgentConfigGateway: CloudAgentConfigGatewayInterface,
  ) {}

  async deleteConnectionExchange(id: string): Promise<void> {
    await new DeleteConnectionExchange(this.connectionExchangesGateway).execute(
      id,
    )
  }

  async createConnectionInvitation(
    name: string,
    label?: string,
  ): Promise<ConnectionInvitationViewModel> {
    const invitation: ConnectionInvitation =
      await new CreateConnectionInvitation(
        this.connectionExchangesGateway,
      ).execute(name, label)
    return transformToInvitationResultViewModel(invitation)
  }

  async acceptConnectionInvitation(
    name: string,
    invitationViewModel: ConnectionInvitationViewModel,
  ): Promise<void> {
    const invitation: ConnectionInvitation = {
      connectionExchangeId: invitationViewModel.connectionExchangeId,
      type: invitationViewModel.type,
      myLabel: invitationViewModel.myLabel,
      recipientKeys: invitationViewModel.recipientKeys,
      endpoint: invitationViewModel.endpoint,
      invitationUrl: invitationViewModel.invitationUrl,
      routingKeys: invitationViewModel.routingKeys,
      did: invitationViewModel.did,
      imageUrl: invitationViewModel.imageUrl,
    }

    await new AcceptConnectionInvitation(
      this.connectionExchangesGateway,
    ).execute(name, invitation)
  }
  async getConnectionExchangeList(): Promise<ConnectionExchangeListViewModel> {
    const connectionExchangeList: ConnectionExchangeListItemViewModel[] = []

    const exchanges: ConnectionExchange[] = await new GetConnectionExchangeList(
      this.connectionExchangesGateway,
    ).execute()

    exchanges.forEach((ex) => {
      connectionExchangeList.push({
        connectionId: ex.id,
        name: ex.name,
        myRole: transformRoleToViewModel(ex.myRole),
        state: transformToConnectionExchangeStateViewModel(ex.state),
        updatedDatetime: ex.updatedDateTime,
        debug: ex.debug,
      })
    })

    return { items: connectionExchangeList }
  }

  convertInvitationString(
    invitationUrl: string,
  ): ConnectionInvitation | undefined {
    // Locate the URL "c_i" query param, decode the
    // Base64 formated invitation
    const inviteParam = invitationUrl.replace(/(https?:\/\/.*)?\?c_i=/i, '')
    let inviteTxt
    let parsedText
    try {
      inviteTxt = inviteParam ? atob(inviteParam) : ''
      parsedText = JSON.parse(inviteTxt)
    } catch {
      return undefined
    }

    const connectionInvitation: ConnectionInvitation = {
      connectionExchangeId: parsedText['@id'],
      myLabel: parsedText['label'],
      recipientKeys: parsedText['recipientKeys'],
      endpoint: parsedText['serviceEndpoint'],
      type: parsedText['@type'],
      invitationUrl: invitationUrl,
      routingKeys: parsedText['routingKeys'] ?? undefined,
      did: parsedText['did'] ?? undefined,
      imageUrl: parsedText['imageUrl'] ?? undefined,
    }
    return connectionInvitation
  }

  async getConnectionNames(): Promise<string[]> {
    const exchanges: ConnectionExchange[] = await new GetConnectionExchangeList(
      this.connectionExchangesGateway,
    ).execute()
    const connectionList = await new GetConnectionList(
      this.connectionGateway,
    ).execute()

    const connectionNames: string[] = []

    exchanges.forEach((exchange) => {
      connectionNames.push(exchange.name)
    })
    connectionList.forEach((connection) => {
      connectionNames.push(connection.name)
    })

    return connectionNames
  }

  async getDefaultLabel(): Promise<string> {
    const cloudAgentConfig: CloudAgentConfig = await new GetCloudAgentConfig(
      this.cloudAgentConfigGateway,
    ).execute()
    return cloudAgentConfig.defaultLabel
  }
}

function transformRoleToViewModel(
  role: ConnectionExchangeRole,
): ConnectionExchangeRoleViewModel {
  switch (role) {
    case ConnectionExchangeRole.Invitee:
      return ConnectionExchangeRoleViewModel.Invitee
    case ConnectionExchangeRole.Inviter:
      return ConnectionExchangeRoleViewModel.Inviter
    case ConnectionExchangeRole.Requester:
      return ConnectionExchangeRoleViewModel.Requester
    case ConnectionExchangeRole.Responder:
      return ConnectionExchangeRoleViewModel.Responder
    default:
      return ConnectionExchangeRoleViewModel.Unknown
  }
}

function transformToConnectionExchangeStateViewModel(
  state: ConnectionExchangeState,
): ConnectionExchangeStateViewModel {
  switch (state) {
    case ConnectionExchangeState.Abandoned:
      return ConnectionExchangeStateViewModel.Abandoned
    case ConnectionExchangeState.Request:
      return ConnectionExchangeStateViewModel.Request
    case ConnectionExchangeState.Error:
      return ConnectionExchangeStateViewModel.Error
    case ConnectionExchangeState.Start:
      return ConnectionExchangeStateViewModel.Start
    case ConnectionExchangeState.Init:
      return ConnectionExchangeStateViewModel.Init
    case ConnectionExchangeState.Invitation:
      return ConnectionExchangeStateViewModel.Invitation
    case ConnectionExchangeState.Response:
      return ConnectionExchangeStateViewModel.Response
    default:
      return ConnectionExchangeStateViewModel.Unknown
  }
}

function transformToInvitationResultViewModel(
  invitationResult: ConnectionInvitation,
): ConnectionInvitationViewModel {
  return {
    connectionExchangeId: invitationResult.connectionExchangeId,
    invitationUrl: invitationResult.invitationUrl,
    type: invitationResult.type,
    myLabel: invitationResult.myLabel,
    recipientKeys: invitationResult.recipientKeys,
    endpoint: invitationResult.endpoint,
  }
}
