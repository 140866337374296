// We use snake_case in the models modules for protocol related
// fields because the Aries RFCs
// define the protocol using that schema.  Originally I attempted to
// convert to camel case here to isolate that peculiarity, however that
// resulted in confusion when comparing to on-the-wire data. In addition
// because of an issue with OpenAPI code generation of typescript-fetch,
// if we don't use 'orginal' for the names output the generated
// api doesn't match the json objects returned in responses.
/*
 * This module contains all routines and definitions required to
 * interface with the Cloud Agent service for activities related to
 * Verifiable Credential Issuance.
 */
import { CloudAgentAPI } from "./cloudAgent";
import {
  CredentialPreview,
  CredentialRevokedCredentialIdGetRequest,
  CredRevokedResult,
  CredRevRecordResult,
  IndyCredInfo,
  IssueCredentialRecordsGetRoleEnum,
  IssueCredentialRecordsGetStateEnum,
  RevocationCredentialRecordGetRequest,
  RevokeRequest,
  V10CredentialExchange,
  V10CredentialFreeOfferRequest,
} from "@sudoplatform-labs/sudo-di-cloud-agent";
import { reportCloudAgentError } from "./utils/errorlog";

export type InitiatorValues = "self" | "external";

export type CredentialRequestParams = {
  schema_name?: string; // Name of a schema to request credentials for
  schema_version?: string; // An explicit schema version to request with name
  schema_creator_did?: string; // Explicit creator of schema qualification
  schema_id?: string; // Explicit schema Id to request credentials for
  credential_definition_id?: string; // Explicit issuer bound credential definition to request
  credential_issuer_did?: string; // Explicit issuer of Credential qualification
  connection_id: string; // The DIDComm connection to send request over
  proposal?: CredentialPreview; // Explicit attributes requested in credential
  comment?: string; // Human readable comment to send with the request to issuer
  trace?: boolean; // Whether to trace the agent protocol actions
  auto_remove?: boolean; // Whether to remove the credential exchange record on completion
  auto_issue?: boolean; // Whether to automatically issue the credential after offer
};

export type CredentialExchangeRecordFilterParams = {
  connection?: string; // DIDComm conneciton Id
  thread?: string; // Protocol Thread Id
  role?: IssueCredentialRecordsGetRoleEnum;
  states?: IssueCredentialRecordsGetStateEnum[];
};

export async function deleteCredential(
  agent: CloudAgentAPI,
  id: string // Credential Id
): Promise<void> {
  try {
    await agent.credentials.credentialCredentialIdDelete({ credentialId: id });
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Delete credential: ${id}`,
      error as Response
    );
  }
}

export async function deleteCredentialExchangeRecord(
  agent: CloudAgentAPI,
  id: string // Credential Exchange Record Id
): Promise<void> {
  try {
    await agent.issueV10Credentials.issueCredentialRecordsCredExIdDelete({
      credExId: id,
    });
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Delete Credential Exchange Record: ${id}`,
      error as Response
    );
  }
}

export async function sendProblemReport(
  agent: CloudAgentAPI,
  id: string, // Credential Exchange Record Id
  reason: string
): Promise<void> {
  try {
    await agent.issueV10Credentials.issueCredentialRecordsCredExIdProblemReportPost(
      {
        credExId: id,
        body: { description: reason },
      }
    );
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to send problem report for credential exchange with id ${id}`,
      error as Response
    );
  }
}

export async function proposeCredential(
  agent: CloudAgentAPI,
  params: CredentialRequestParams
): Promise<void> {
  try {
    const agentRequest = {
      cred_def_id: params.credential_definition_id,
      issuer_did: params.credential_issuer_did,
      trace: params.trace,
      schema_id: params.schema_id,
      schema_issuer_did: params.schema_creator_did,
      auto_remove: params.auto_remove,
      schema_name: params.schema_name,
      schema_version: params.schema_version,
      credential_proposal: params.proposal,
      comment: params.comment,
      connection_id: params.connection_id,
    };

    await agent.issueV10Credentials.issueCredentialSendProposalPost({
      body: agentRequest,
    });
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Propose credential: ${
        params.credential_definition_id ??
        params.schema_id ??
        params.schema_name ??
        params.connection_id
      }`,
      error as Response
    );
  }
}

export async function issueCredentialSendOffer(
  agent: CloudAgentAPI,
  params: CredentialRequestParams
): Promise<void> {
  try {
    // Type safety so eslint and ts don't complain
    if (params.credential_definition_id === undefined) {
      throw Error("Error: credential_definition_id is undefined");
    }
    if (params.connection_id === undefined) {
      throw Error("Error: connection_id is undefined");
    }
    if (params.proposal === undefined) {
      throw Error("Error: Credential preview is undefined");
    }

    const credentialConnFreeOfferRequest: V10CredentialFreeOfferRequest = {
      auto_remove: params.auto_remove,
      connection_id: params.connection_id,
      cred_def_id: params.credential_definition_id,
      credential_preview: params.proposal,
      comment: params.comment,
      trace: params.trace,
      auto_issue: params.auto_issue,
    };

    await agent.issueV10Credentials.issueCredentialSendOfferPost({
      body: credentialConnFreeOfferRequest,
    });
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Send Offer Credential to ${params.connection_id}:
      ${
        params.credential_definition_id ??
        params.schema_id ??
        params.schema_name ??
        params.connection_id
      }`,
      error as Response
    );
  }
}

export async function offerCredential(
  agent: CloudAgentAPI,
  id: string // Credential Exchange Record Id
): Promise<void> {
  try {
    await agent.issueV10Credentials.issueCredentialRecordsCredExIdSendOfferPost(
      {
        credExId: id,
      }
    );
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Offer Credential : ${id}`,
      error as Response
    );
  }
}

export async function requestProposedCredential(
  agent: CloudAgentAPI,
  id: string // Credential Exchange Record Id
): Promise<void> {
  try {
    await agent.issueV10Credentials.issueCredentialRecordsCredExIdSendRequestPost(
      {
        credExId: id,
      }
    );
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Request proposed Credential : ${id}`,
      error as Response
    );
  }
}

export async function issueCredential(
  agent: CloudAgentAPI,
  id: string, // Credential Exchange Record Id
  comment?: string // Optional comment
): Promise<void> {
  try {
    await agent.issueV10Credentials.issueCredentialRecordsCredExIdIssuePost({
      credExId: id,
      body: { comment: comment },
    });
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Issue requested Credential : ${id}`,
      error as Response
    );
  }
}

export async function storeCredential(
  agent: CloudAgentAPI,
  id: string // Credential Exchange Record Id
): Promise<void> {
  try {
    await agent.issueV10Credentials.issueCredentialRecordsCredExIdStorePost({
      credExId: id,
    });
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Store Credential : ${id}`,
      error as Response
    );
  }
}

export async function revokeCredential(
  agent: CloudAgentAPI,
  params: RevokeRequest
): Promise<void> {
  try {
    await agent.revocations.revocationRevokePost({ body: params });
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to Revoke Credential : ${params.cred_ex_id}`,
      error as Response
    );
  }
}

export async function getIssuerCredentialRevocationStatus(
  agent: CloudAgentAPI,
  params: RevocationCredentialRecordGetRequest
): Promise<CredRevRecordResult> {
  try {
    const result = await agent.revocations.revocationCredentialRecordGet(
      params
    );
    return result;
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to retreive Revocation status : ${params.credExId}`,
      error as Response
    );
  }
}

export async function getHolderCredentialRevocationStatus(
  agent: CloudAgentAPI,
  params: CredentialRevokedCredentialIdGetRequest
): Promise<CredRevokedResult> {
  try {
    const result = await agent.credentials.credentialRevokedCredentialIdGet(
      params
    );
    return result;
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to retreive Revocation status : ${params.credentialId}`,
      error as Response
    );
  }
}

export async function fetchCredentialExchangeRecord(
  agent: CloudAgentAPI,
  credExId: string
): Promise<V10CredentialExchange> {
  try {
    return await agent.issueV10Credentials.issueCredentialRecordsCredExIdGet({
      credExId: credExId,
    });
  } catch (error) {
    throw await reportCloudAgentError(
      `Failed to retrieve Credential Exchange with id ${credExId}`,
      error as Response
    );
  }
}

export async function fetchFilteredCredentialExchangeRecords(
  agent: CloudAgentAPI,
  params: CredentialExchangeRecordFilterParams
): Promise<V10CredentialExchange[]> {
  try {
    const agentResult =
      await agent.issueV10Credentials.issueCredentialRecordsGet({
        connectionId: params.connection,
        role: params.role,
        threadId: params.thread,
      });

    const recordList = agentResult.results ?? [];
    const result = recordList.filter(
      (record) =>
        params.states === undefined ||
        (record.state !== undefined &&
          params.states.includes(
            record.state as IssueCredentialRecordsGetStateEnum
          ))
    );
    return result;
  } catch (error) {
    throw await reportCloudAgentError(
      "Failed to Retrieve Credential Exchange Records from Wallet",
      error as Response
    );
  }
}

export async function fetchAllAgentOwnedCredentialDetails(
  agent: CloudAgentAPI
): Promise<IndyCredInfo[]> {
  try {
    const agentResult = await agent.credentials.credentialsGet({
      count: "100000", // default is 10. Note the lack of sort order
    });
    const result = agentResult.results ?? [];

    return result;
  } catch (error) {
    throw await reportCloudAgentError(
      "Failed to Retrieve Credential List from Wallet",
      error as Response
    );
  }
}
