import {
  IndyCredInfo,
  IndyCredPrecis,
  IssueCredentialRecordsGetStateEnum,
  V10CredentialExchange,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import { IssueCredentialRecordsGetRoleEnum } from '@sudoplatform-labs/sudo-di-cloud-agent/lib/apis/IssueCredentialV10Api'
import {
  IssuedCredential,
  IssuedRevocableCredential,
  OwnedCredential,
  OwnedRevocableCredential,
  ProofExchange,
  RequestedAttributeGroup,
} from '../../domain/entities'
import { CredentialGatewayInterface } from '../../domain/gateway-interfaces'
import {
  deleteCredential,
  deleteCredentialExchangeRecord,
  fetchAllAgentOwnedCredentialDetails,
  fetchFilteredCredentialExchangeRecords,
  getHolderCredentialRevocationStatus,
  getIssuerCredentialRevocationStatus,
  revokeCredential,
} from './sdk-wrappers/CredentialIssuance'
import { fetchCredentialsMatchingProof } from './sdk-wrappers/ProofPresentation'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'

export class CredentialGateway implements CredentialGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getOwnedCredentialList(): Promise<OwnedCredential[]> {
    const indyCredInfos = await fetchAllAgentOwnedCredentialDetails(
      this.cloudAgentAPIs,
    )
    return transformToOwnedCredentialList(indyCredInfos)
  }

  async getIssuedCredentialList(): Promise<IssuedCredential[]> {
    const v10CredentialExchanges = await fetchFilteredCredentialExchangeRecords(
      this.cloudAgentAPIs,
      {
        role: IssueCredentialRecordsGetRoleEnum.Issuer,
        states: [
          IssueCredentialRecordsGetStateEnum.CredentialIssued,
          IssueCredentialRecordsGetStateEnum.CredentialAcked,
          IssueCredentialRecordsGetStateEnum.CredentialRevoked,
        ],
      },
    )
    return transformToIssuedCredentialList(v10CredentialExchanges)
  }

  /**
   * TODO: Note that the API endpoint called by this method currently returns credentials
   *  without taking into account the NonRevokedInterval which applies to a given attributeGroupId.
   */
  async getCredentialOptionsForProofResponse(
    proofExchange: ProofExchange,
  ): Promise<{
    credentialsForAttributeGroupsByGroupId: {
      [groupId: string]: OwnedCredential[]
    }
  }> {
    const indyCredPrecisList: IndyCredPrecis[] =
      await fetchCredentialsMatchingProof(this.cloudAgentAPIs, {
        presentation: proofExchange.id,
      })

    const credentialsByAttributeGroupId: {
      [groupId: string]: OwnedCredential[]
    } = {}

    for (const indyCredPrecis of indyCredPrecisList) {
      const indyCredInfo: IndyCredInfo | undefined = indyCredPrecis.cred_info
      const matchedAttributeGroupIds =
        indyCredPrecis.presentation_referents ?? []

      if (indyCredInfo === undefined || matchedAttributeGroupIds.length === 0) {
        continue
      }

      const credential = transformToOwnedCredential(indyCredInfo)

      for (const groupId of matchedAttributeGroupIds) {
        if (credentialsByAttributeGroupId.hasOwnProperty(groupId)) {
          credentialsByAttributeGroupId[groupId].push(credential)
        } else {
          credentialsByAttributeGroupId[groupId] = [credential]
        }
      }
    }

    return {
      credentialsForAttributeGroupsByGroupId:
        getCredentialsForAttributeGroupsSortedByGroupId(
          proofExchange.requestedProofs.attributeGroups,
          credentialsByAttributeGroupId,
        ),
    }
  }

  async isOwnedCredentialRevoked(
    credential: OwnedRevocableCredential,
  ): Promise<boolean> {
    const credRevokedResult = await getHolderCredentialRevocationStatus(
      this.cloudAgentAPIs,
      {
        credentialId: credential.id,
      },
    )
    return credRevokedResult?.revoked ?? false
  }

  async isIssuedCredentialRevoked(
    credential: IssuedRevocableCredential,
  ): Promise<boolean> {
    const credRevRecordResult = await getIssuerCredentialRevocationStatus(
      this.cloudAgentAPIs,
      { credExId: credential.credExId },
    )
    return credRevRecordResult?.result?.state === 'revoked'
  }

  async revokeIssuedCredential(
    credential: IssuedRevocableCredential,
  ): Promise<void> {
    await revokeCredential(this.cloudAgentAPIs, {
      cred_ex_id: credential.credExId,
      publish: true,
    })
  }

  async deleteOwnedCredential(credential: OwnedCredential): Promise<void> {
    await deleteCredential(this.cloudAgentAPIs, credential.id)
  }

  async deleteIssuedCredential(credential: IssuedCredential): Promise<void> {
    await deleteCredentialExchangeRecord(
      this.cloudAgentAPIs,
      credential.credExId,
    )
  }
}

function transformToOwnedCredentialList(
  indyCredInfos: IndyCredInfo[],
): OwnedCredential[] {
  const credentials = []
  for (const indyCredInfo of indyCredInfos) {
    const credential = transformToOwnedCredential(indyCredInfo)
    credentials.push(credential)
  }
  return credentials
}

function transformToOwnedCredential(
  indyCredInfo: IndyCredInfo,
): OwnedCredential {
  return {
    id: indyCredInfo.referent ?? '',
    schemaId: indyCredInfo.schema_id ?? '',
    credDefId: indyCredInfo.cred_def_id ?? '',
    attributeValuesByName: indyCredInfo.attrs ?? {},
    revocationRegistryId: indyCredInfo.rev_reg_id ?? undefined,
    revocationId: indyCredInfo.cred_rev_id ?? undefined,
    debug: { 'SDK::IndyCredInfo': indyCredInfo },
  }
}

function transformToIssuedCredentialList(
  v10CredentialExchanges: V10CredentialExchange[],
): IssuedCredential[] {
  const credentials = []
  for (const v10CredentialExchange of v10CredentialExchanges) {
    const credential = transformToIssuedCredential(v10CredentialExchange)
    credentials.push(credential)
  }
  return credentials
}

function transformToIssuedCredential(
  v10CredentialExchange: V10CredentialExchange,
): IssuedCredential {
  const indyCredInfo = v10CredentialExchange.credential
  return {
    credExId: v10CredentialExchange.credential_exchange_id ?? '',
    schemaId: indyCredInfo?.schema_id ?? '',
    credDefId: indyCredInfo?.cred_def_id ?? '',
    attributeValuesByName: indyCredInfo?.attrs ?? {},
    revocationRegistryId: indyCredInfo?.rev_reg_id ?? undefined,
    revocationId: indyCredInfo?.cred_rev_id ?? undefined,
    debug: { 'SDK::IndyCredInfo': indyCredInfo },
  }
}

function getCredentialsForAttributeGroupsSortedByGroupId(
  attributeGroups: RequestedAttributeGroup[],
  credentialsByAttributeGroupId: {
    [groupId: string]: OwnedCredential[]
  },
): {
  [groupId: string]: OwnedCredential[]
} {
  const credentialsForAttributeGroupsByGroupId: {
    [groupId: string]: OwnedCredential[]
  } = {}

  for (const attributeGroup of attributeGroups) {
    const groupId = attributeGroup.groupId

    credentialsForAttributeGroupsByGroupId[groupId] =
      credentialsByAttributeGroupId.hasOwnProperty(groupId)
        ? credentialsByAttributeGroupId[groupId]
        : []
  }

  return credentialsForAttributeGroupsByGroupId
}
