import { AdminConfig } from '@sudoplatform-labs/sudo-di-cloud-agent'
import { CloudAgentAPI } from './cloudAgent'
import { reportCloudAgentError } from './utils/errorlog'

export async function fetchServerConfig(
  agent: CloudAgentAPI,
): Promise<AdminConfig> {
  try {
    const config = await agent.server.statusConfigGet()
    return config ?? {}
  } catch (error) {
    throw await reportCloudAgentError(
      'Failed to retrieve server configuration',
      error as Response,
    )
  }
}
