import { Credential } from '../../entities'
import { CredentialGatewayInterface } from '../../gateway-interfaces'

export class GetCredentialList {
  constructor(private readonly credentialGateway: CredentialGatewayInterface) {}

  async execute(): Promise<Credential[]> {
    const ownedCredentials =
      await this.credentialGateway.getOwnedCredentialList()

    const issuedCredentials =
      await this.credentialGateway.getIssuedCredentialList()

    return (ownedCredentials as Credential[]).concat(issuedCredentials)
  }
}
