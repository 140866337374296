export type DID = {
  nym: string
  verKey: string
  keyType: DidKeyType
  posture: DidPosture
  method: DidMethod
  role?: DidRole
}

export enum DidKeyType {
  Unknown = 'Unknown',
  Ed25519 = 'Ed25519',
  Bls12381g2 = 'Bls12381g2',
}

export enum DidPosture {
  Unknown = 'Unknown',
  Public = 'Public',
  Posted = 'Posted',
  WalletOnly = 'Wallet Only',
}

export enum DidMethod {
  Unknown = 'Unknown',
  Sov = 'sov',
  Key = 'key',
}

export enum DidRole {
  Unknown = 'Unknown',
  Endorser = 'Endorser',
  NetworkMonitor = 'Network Monitor',
  RoleRemove = 'Role Remove',
  Steward = 'Steward',
  Trustee = 'Trustee',
  User = 'User',
}
