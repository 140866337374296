// We use snake_case in the models modules for protocol related
// fields because the Aries RFCs
// define the protocol using that schema.  Originally I attempted to
// convert to camel case here to isolate that peculiarity, however that
// resulted in confusion when comparing to on-the-wire data. In addition
// because of an issue with OpenAPI code generation of typescript-fetch,
// if we don't use 'orginal' for the names output the generated
// api doesn't match the json objects returned in responses.
/*
 * This module contains all routines and definitions required to
 * interface with the Cloud Agent service for activities related to
 * Verifiable Credential Schemas.
 */
import { Schema, SchemaGetResult } from '@sudoplatform-labs/sudo-di-cloud-agent'
import { CloudAgentAPI } from './cloudAgent'
import { reportCloudAgentError } from './utils/errorlog'

export type SchemaId = string

export type SchemaCreateParams = {
  name: string // Human readable, descriptive name for the schema
  version: string // Schema Version, which must be changed to modify any attributes
  attributes: string[] // Schema attribute names in csv format
}

export async function createSchema(
  agent: CloudAgentAPI,
  params: SchemaCreateParams,
): Promise<void> {
  try {
    // Create new schema
    await agent.defineSchemas.schemasPost({
      createTransactionForEndorser: false, // We currently don't support using an endorser
      body: {
        schema_version: params.version,
        schema_name: params.name,
        attributes: params.attributes,
      },
    })
  } catch (error) {
    throw await reportCloudAgentError(
      'Failed to Create Schema on Ledger',
      error as Response,
    )
  }
}

export async function fetchSchemaIds(
  agent: CloudAgentAPI,
  id?: string, // Schema Id
  did?: string, // Schema creator DID
  name?: string, // Schema Name
  version?: string, // Schema Version
): Promise<SchemaId[]> {
  try {
    const schemaList = await agent.defineSchemas.schemasCreatedGet({
      schemaId: id,
      schemaIssuerDid: did,
      schemaName: name,
      schemaVersion: version,
    })
    return schemaList.schema_ids ?? []
  } catch (error) {
    throw reportCloudAgentError(
      `Schema not found for creatorDID: ${did} schemaName: ${name} schemaVersion: ${version}`,
      error as Response,
    )
  }
}

export async function fetchSchemaDetails(
  agent: CloudAgentAPI,
  id: SchemaId, // Schema Id
): Promise<Schema> {
  try {
    const ledgerSchema: SchemaGetResult =
      await agent.defineSchemas.schemasSchemaIdGet({
        schemaId: id,
      })

    return ledgerSchema.schema ?? {}
  } catch (error) {
    throw await reportCloudAgentError(
      `Schema ${id} NOT FOUND on Ledger`,
      error as Response,
    )
  }
}

export async function fetchAllAgentSchemaIds(
  agent: CloudAgentAPI,
): Promise<SchemaId[]> {
  try {
    const schemaList = await agent.defineSchemas.schemasCreatedGet({})
    const schemaResults: SchemaId[] = schemaList.schema_ids ?? []
    return schemaResults
  } catch (error) {
    throw await reportCloudAgentError(
      'Failed to Retrieve Defined Schema Identifiers from Cloud Agent',
      error as Response,
    )
  }
}

export async function fetchAllAgentSchemaDetails(
  agent: CloudAgentAPI,
): Promise<Schema[]> {
  const schemaList = await fetchAllAgentSchemaIds(agent)
  const result = Promise.all(
    schemaList.map((id) => fetchSchemaDetails(agent, id)),
  )

  return result
}
