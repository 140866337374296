export const Environment = {
  cognito: {
    userPoolId: "us-east-1_eCBGXBhjq",
    clientId: "1o7rm6mt08kk4loqd6ogecp5do",
  },
  cloudAgent: {
    adminBaseUrl:
      "https://cloudagent-admin-api.ino-cu.research.sudoplatform.com",
    graphQlUrl:
      "https://3gk3uxnbjfdghgorg7fhtkkffy.appsync-api.us-east-1.amazonaws.com/graphql",
  },
};
