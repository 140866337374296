import {
  WalletDidGetMethodEnum,
  DID as SdkDID,
  DIDKeyTypeEnum,
  DIDPostureEnum,
  GetNymRoleResponseRoleEnum,
  WalletDidCreatePostRequest,
  DIDCreateOptionsKeyTypeEnum,
  DIDResult,
  LedgerRegisterNymPostRequest,
  TxnOrRegisterLedgerNymResponse,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import {
  DID,
  DidKeyType,
  DidMethod,
  DidPosture,
  DidRole,
} from '../../domain/entities'
import { DIDGatewayInterface } from '../../domain/gateway-interfaces/DIDGatewayInterface'
import { CloudAgentAPI } from './sdk-wrappers/cloudAgent'
import { reportCloudAgentError } from './sdk-wrappers/utils/errorlog'

export class DIDGateway implements DIDGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}
  async getPublicDid(): Promise<DID> {
    let didResult
    try {
      didResult = (await this.cloudAgentAPIs.wallet.walletDidPublicGet()).result
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed fetch public DID`,
        error as Response,
      )
    }

    let nymRole
    try {
      nymRole = await this.cloudAgentAPIs.ledger.ledgerGetNymRoleGet({
        did: didResult?.did ?? '',
      })
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to get NYM Role for ${didResult?.did}}`,
        error as Response,
      )
    }

    return {
      nym: didResult?.did ?? '',
      verKey: didResult?.verkey ?? '',
      posture: transformToDidPosture(didResult?.posture),
      keyType: transformToDidKeyType(didResult?.key_type),
      role: transformToDidRole(nymRole.role),
      method: transformToDidMethod(didResult?.method),
    }
  }

  async getDidList(): Promise<DID[]> {
    const didList: DID[] = []
    let didResults
    try {
      didResults =
        (await this.cloudAgentAPIs.wallet.walletDidGet({})).results ?? []
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to get did list`,
        error as Response,
      )
    }

    for (let i = 0; i < didResults.length; i++) {
      const didResult = didResults[i]
      didList.push({
        nym: didResult?.did ?? '',
        verKey: didResult?.verkey ?? '',
        posture: transformToDidPosture(didResult?.posture),
        keyType: transformToDidKeyType(didResult?.key_type),
        method: transformToDidMethod(didResult?.method),
      })
    }
    return didList
  }

  async createDid(keyType: DidKeyType, method: DidMethod): Promise<DID> {
    const requestParams: WalletDidCreatePostRequest = {
      body: {
        method: transformToWalletDidGetMethodEnum(method),
        options: { key_type: tranformToDIDCreateOptionsKeyTypeEnum(keyType) },
      },
    }
    let didResult: DIDResult

    try {
      didResult = await this.cloudAgentAPIs.wallet.walletDidCreatePost(
        requestParams,
      )
      transformToDID(didResult.result)
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to create DID`,
        error as Response,
      )
    }
    const did = transformToDID(didResult.result)
    if (did === undefined) {
      throw new Error('There was a problem creating the did, please try again')
    }
    return did
  }

  async writeDidToLedger(did: DID): Promise<boolean> {
    const requestParams: LedgerRegisterNymPostRequest = {
      did: did.nym,
      verkey: did.verKey,
    }
    const response: TxnOrRegisterLedgerNymResponse =
      await this.cloudAgentAPIs.ledger.ledgerRegisterNymPost(requestParams)
    return response.success ?? false
  }
}

function tranformToDIDCreateOptionsKeyTypeEnum(
  keyType: DidKeyType,
): DIDCreateOptionsKeyTypeEnum {
  switch (keyType) {
    case DidKeyType.Bls12381g2:
      return DIDCreateOptionsKeyTypeEnum.Bls12381g2
    case DidKeyType.Ed25519:
      return DIDCreateOptionsKeyTypeEnum.Ed25519
    default: // Default to ed25519 (should never occur, this is to satisfy the return type)
      return DIDCreateOptionsKeyTypeEnum.Ed25519
  }
}

function transformToDidMethod(method?: string): DidMethod {
  switch (method) {
    case 'sov':
      return DidMethod.Sov
    case 'key':
      return DidMethod.Key
    default:
      return DidMethod.Unknown
  }
}

function transformToDidRole(role?: GetNymRoleResponseRoleEnum): DidRole {
  switch (role) {
    case GetNymRoleResponseRoleEnum.Endorser:
      return DidRole.Endorser
    case GetNymRoleResponseRoleEnum.NetworkMonitor:
      return DidRole.NetworkMonitor
    case GetNymRoleResponseRoleEnum.RoleRemove:
      return DidRole.RoleRemove
    case GetNymRoleResponseRoleEnum.Steward:
      return DidRole.Steward
    case GetNymRoleResponseRoleEnum.Trustee:
      return DidRole.Trustee
    case GetNymRoleResponseRoleEnum.User:
      return DidRole.User
    default:
      return DidRole.Unknown
  }
}

function transformToDID(sdkDid: SdkDID | undefined): DID | undefined {
  if (sdkDid === undefined) {
    return undefined
  }
  return {
    nym: sdkDid.did ?? '',
    verKey: sdkDid.verkey ?? '',
    keyType: transformToDidKeyType(sdkDid.key_type),
    posture: transformToDidPosture(sdkDid.posture),
    method: transformToDidMethod(sdkDid.method),
  }
}

function transformToDidKeyType(keyType?: DIDKeyTypeEnum): DidKeyType {
  switch (keyType) {
    case DIDKeyTypeEnum.Bls12381g2:
      return DidKeyType.Bls12381g2
    case DIDKeyTypeEnum.Ed25519:
      return DidKeyType.Ed25519
    default:
      return DidKeyType.Unknown
  }
}

function transformToDidPosture(posture?: DIDPostureEnum): DidPosture {
  switch (posture) {
    case DIDPostureEnum.Posted:
      return DidPosture.Posted
    case DIDPostureEnum.Public:
      return DidPosture.Public
    case DIDPostureEnum.WalletOnly:
      return DidPosture.WalletOnly
    default:
      return DidPosture.Unknown
  }
}

function transformToWalletDidGetMethodEnum(
  method?: DidMethod,
): WalletDidGetMethodEnum | undefined {
  switch (method) {
    case DidMethod.Key:
      return WalletDidGetMethodEnum.Key
    case DidMethod.Sov:
      return WalletDidGetMethodEnum.Sov
  }
}
