import React, { PropsWithChildren, useMemo } from "react";
import { CredentialsPageController } from "../../../modules/admin-console/controllers";
import { CredentialsPageContext } from "./credentials-page-context";
import { cloudAgentGateways } from "../../../modules/cloudAgent";

export const CredentialsPage: React.FC<PropsWithChildren> = ({ children }) => {
  const controller = useMemo(() => {
    return new CredentialsPageController(
      cloudAgentGateways.credentialGateway,
      cloudAgentGateways.credExGateway,
      cloudAgentGateways.connectionGateway,
      cloudAgentGateways.credDefGateway
    );
  }, []);

  return (
    <CredentialsPageContext.Provider
      value={{
        controller: controller,
      }}
    >
      {children}
    </CredentialsPageContext.Provider>
  );
};
