import { ConnectionID } from '..'
import { Credential } from './Credential'

export type CredentialExchangeID = string

export type CredentialExchange = {
  readonly updatedDatetime?: Date
  readonly id: CredentialExchangeID
  readonly credentialProposal?: Credential
  readonly credentialOffer?: Credential
  readonly credentialRequest?: Credential
  readonly connectionId: ConnectionID
  readonly state: CredentialExchangeState
  readonly myRole: CredentialExchangeRole
  readonly credentialId?: string // if the credential exchange is for a held credential, this is a reference to the stored credential
  readonly debug?: object
}

export enum CredentialExchangeState {
  Unknown = 'Unknown',
  ProposalSent = 'ProposalSent',
  ProposalReceived = 'ProposalReceived',
  OfferSent = 'OfferSent',
  OfferReceived = 'OfferReceived',
  RequestSent = 'RequestSent',
  RequestReceived = 'RequestReceived',
  CredentialIssued = 'CredentialIssued',
  CredentialReceived = 'CredentialReceived',
  Done = 'Done',
  CredentialRevoked = 'CredentialRevoked',
  Deleted = 'Deleted',
  Abandoned = 'Abandoned',
}

export enum CredentialExchangeRole {
  Unknown = 'Unknown',
  Holder = 'Holder',
  Issuer = 'Issuer',
}
