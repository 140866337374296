import { IssuedRevocableCredential } from '../../entities'
import { CredentialGatewayInterface } from '../../gateway-interfaces'

export class RevokeCredential {
  constructor(private readonly credentialGateway: CredentialGatewayInterface) {}

  async execute(credential: IssuedRevocableCredential): Promise<void> {
    return await this.credentialGateway.revokeIssuedCredential(credential)
  }
}
