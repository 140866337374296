import { Button } from 'antd'
import React from 'react'
import { cloudAgentGateways } from '../../../../../modules/cloudAgent';

interface TableRefreshProps {
  onRefresh: () => void
}

export const TableClearAllButton: React.FC<TableRefreshProps> = (props) => {
  
  const { onRefresh } = props

  async function clearAll () {
    const doDelete = true;
  
    const proofExchanges = await cloudAgentGateways.proofExGateway.getInProgressProofExchangeList();
    console.log(' to remove ', proofExchanges.length, ' proofExchanges.');
    proofExchanges.forEach(function(proofExchange) {
      if (doDelete) {
        console.log('remove: ', proofExchange.id);
        cloudAgentGateways.proofExGateway.deleteProofExchangeById(proofExchange.id);
      } else 
        console.log('debug');
    });
  
    const proofPresentations = await cloudAgentGateways.proofPresGateway
          .getProofPresentationsList();
    console.log('remove ', proofPresentations.length, ' proofPresentations.');
    proofPresentations.forEach(function(proofPresentation) {
      if (doDelete) {
        console.log('remove: ', proofPresentation.id);
        cloudAgentGateways.proofPresGateway.deleteProofPresentationById(proofPresentation.id);
      } else 
        console.log('debug');
    });
  
    const credExchanges = await cloudAgentGateways.credExGateway.getInProgressCredExList();
    console.log('remove ', credExchanges.length, ' credExchanges.');
    credExchanges.forEach(function(credExchange) {
      if (doDelete) {
        console.log('remove: ', credExchange.id);
        cloudAgentGateways.credExGateway.deleteCredExById(credExchange.id);
      } else 
        console.log('debug');
    });
  
    const ownedCredentials = await cloudAgentGateways.credentialGateway.getOwnedCredentialList();
    console.log('remove ', ownedCredentials.length, ' ownedCredentials.');
    ownedCredentials.forEach(function(ownedCredential) {
      if (doDelete) {
        console.log('remove owned credential: ', ownedCredential);
        cloudAgentGateways.credentialGateway.deleteOwnedCredential(ownedCredential);
      } else 
        console.log('debug');
    })
    
    const issuedCredentials = await cloudAgentGateways.credentialGateway.getIssuedCredentialList();
    console.log('remove ', issuedCredentials.length, ' issuedCredentials.');
    issuedCredentials.forEach(function(issuedCredential) {
      if (doDelete) {
        console.log('remove issued credential: ', issuedCredential);
        cloudAgentGateways.credentialGateway.deleteIssuedCredential(issuedCredential);
      } else 
        console.log('debug');
    })
  
    const connExchanges = await cloudAgentGateways.connectionExGateway.getConnectionExchangeList();
    console.log('remove ', connExchanges.length, ' connExchanges.');
    connExchanges.forEach(function(connExchange) {
      if (doDelete) {
        console.log('remove: ', connExchange.id);
        cloudAgentGateways.connectionExGateway.deleteConnectionExchange(connExchange.id);
      } else 
        console.log('debug');
    });
  
    const conns = await cloudAgentGateways.connectionGateway.getConnectionList();
    console.log('remove ', conns.length, ' conns.');
    conns.forEach(function(conn) {
      if (doDelete) {
        console.log('remove: ', conn.id);
        cloudAgentGateways.connectionGateway.deleteConnection(conn.id);
      } else 
        console.log('debug');      
    });
  }

  return (
    <Button danger
      type="primary"
      shape="round"
      onClick={() => {
        clearAll();
        onRefresh();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }}
    >
      Clear All
    </Button>
  )
}
