import React, { ReactNode, KeyboardEvent, useEffect, useState } from "react";
import { CredentialExpandableProps } from "./credential-expandable";
import { LockOutlined, SendOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Input } from "antd";
import { Message } from "../../../../../modules/admin-console/domain/entities";
import "./styles.css";
import { cloudAgentGateways } from "../../../../../modules/cloudAgent";

export const CredentialExpandableChat: React.FC<CredentialExpandableProps> = (
  props
) => {
  const connectionId = props.rowData.connectionId;

  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      // refresh messages every 2 seconds
      cloudAgentGateways.messagesGateway
        .getMessagesByConnectionId(connectionId, 20)
        .then((messageRows) => {
          // console.log("Got messages:", messageRows);
          const newMessages = messageRows.messages.sort((a, b) => {
            if (a.timestamp && b.timestamp) {
              return a.timestamp < b.timestamp ? -1 : 1;
            }
            return 0;
          });
          if (JSON.stringify(messages) !== JSON.stringify(newMessages))
            setMessages(newMessages);
        })
        .catch((e) => {
          console.error(e);
        });
    }, 2000);
    return () => clearInterval(interval);
  }, [connectionId, messages]);

  const sendMessage = (): void => {
    console.log(newMessage);
    cloudAgentGateways.messagesGateway
      .sendMessage(connectionId, newMessage)
      .then(() => {
        setNewMessage("");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const renderMessages = (): ReactNode => {
    const messageRows: ReactNode[] = [];
    if (!messages.length) {
      messageRows.push(
        <div
          style={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          No message history
        </div>
      );
      return messageRows;
    }

    for (const message of messages) {
      const content: ReactNode[] = [];
      for (const line of message.content.split("\n")) {
        if (content.length) {
          content.push(<br />);
        }
        content.push(line);
      }
      messageRows.push(
        <div
          className={
            message.direction === "OUTBOUND"
              ? "msgOutboundContainer"
              : "msgInboundContainer"
          }
        >
          <div
            className={
              message.direction === "OUTBOUND" ? "msgOutbound" : "msgInbound"
            }
          >
            {message.timestamp ? (
              <div style={{ fontSize: 11 }}>
                {message.timestamp.toLocaleString()}
              </div>
            ) : null}
            <div style={{ fontSize: 14 }}>{content}</div>
          </div>
        </div>
      );
    }
    return messageRows;
  };

  return (
    <div className="container" style={{ maxWidth: "min(400px, 50%)" }}>
      <div className="sectionHeader">
        <div className="encryptedChatHeaderIcon">
          <LockOutlined rev={undefined} />
        </div>
        Encrypted Chat
        <div className="spacer"></div>
        <Button type="primary" danger>
          End Chat
        </Button>
      </div>
      <div
        className="encryptedChatBody"
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        {/* TODO: scroll messages to bottom */}
        {renderMessages()}
      </div>
      <div className="encryptedChatFooter">
        <Input
          placeholder="Send Secure Message"
          suffix={<SendOutlined rev={undefined} onClick={sendMessage} />}
          style={{
            // backgroundColor: 'rgba(247,247,248,1)',
            // borderWidth: 0,
            fontWeight: 400,
            borderRadius: "1em",
            height: "2em",
            lineHeight: "2em",
            cursor: "pointer",
          }}
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};
