import React, { PropsWithChildren, useMemo } from "react";
import { ConnectionExchangePageController } from "../../../modules/admin-console/controllers";
import { ConnectionExchangePageContext } from "./connections-page-context";
import { cloudAgentGateways } from "../../../modules/cloudAgent";

export const ConnectionExchangePage: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const controller = useMemo(() => {
    return new ConnectionExchangePageController(
      cloudAgentGateways.connectionExGateway,
      cloudAgentGateways.connectionGateway,
      cloudAgentGateways.cloudAgentConfigGateway
    );
  }, []);

  return (
    <ConnectionExchangePageContext.Provider
      value={{
        controller: controller,
      }}
    >
      {children}
    </ConnectionExchangePageContext.Provider>
  );
};
