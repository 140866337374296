import { ConnectionInvitation } from '../../entities'
import { ConnectionExchangeGatewayInterface } from '../../gateway-interfaces'

export class CreateConnectionInvitation {
  constructor(
    private readonly connectionExchangeGateway: ConnectionExchangeGatewayInterface,
  ) {}

  async execute(name: string, label?: string): Promise<ConnectionInvitation> {
    return await this.connectionExchangeGateway.createConnectionInvitation(
      name,
      label,
    )
  }
}
