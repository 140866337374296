import React, { useEffect, useState } from "react";
import { ConnectionExchangePage } from "../../components/context/connections/connections-page";
import { IDCheckCard } from "./pages/id-check/id-check";
import { VideoSelfieCard } from "./pages/video-selfie/video-selfie";
import { FinishedCard } from "./pages/finished/finished";
import { Button } from "antd";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../modules/session/auth-guard";

const NoQRURL = "no url";

export const CustomerDashboard: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
  }, [navigate]);

  class CustomerDashboardTab {
    id = "tabName";
    title = "Tab Name";
  }
  const dashboardTabs: CustomerDashboardTab[] = [
    { id: "idCheck", title: "ID Check" },
    { id: "videoSelfie", title: "Video Selfie" },
    { id: "finished", title: "Finished" },
  ];

  const [currentTab, setCurrentTab] = useState(0);
  const [idCheckDone, setIdCheckDone] = useState(false);

  return (
    <ConnectionExchangePage>
      {/* this div will be drawn fullscreen on top of the admin console UI */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          minHeight: "100vh",
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: "rgba(242,242,245,1)",
        }}
      >
        {/* PAGE HEADER */}
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            gap: 16,
            backgroundColor: "rgba(0,21,33,1)",
            color: "white",
            fontSize: 24,
            fontWeight: 600,
            padding: "16px 32px",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          {/* <img
                src="/images/anonyome.png"
                width={48}
                height={48}
                style={{ boxShadow: 'rgba(255,255,255,0.1) 0 0 20px 5px' }}
              /> */}
          Anonyome Credit Union
        </div>
        {/* PAGE BODY */}
        <div
          style={{
            textAlign: "center",
            width: 720,
            margin: "200px auto",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              padding: "0 2em 1em 2em",
            }}
          >
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Secure Verification
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
              {dashboardTabs.map((tab, tabId) => {
                return (
                  <div
                    key={tab.id}
                    style={{
                      padding: "0 1em .2em 1em",
                      display: "flex",
                      fontWeight: 600,
                      verticalAlign: "center",
                      border: "none",
                      backgroundColor: "transparent",
                      color: tabId <= currentTab ? "black" : "darkgray",
                      borderBottom: `2px solid ${
                        tabId <= currentTab ? "black" : "darkgray"
                      }`,
                    }}
                  >
                    {tab.title}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Card */}
          <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {/* card body */}
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "2em 2em 0 0",
                padding: "2em",
                minHeight: 300,
              }}
            >
              {currentTab === 0 ? (
                <IDCheckCard setDone={setIdCheckDone} /> 
              ) : null}
              {currentTab === 1 ? (
                <VideoSelfieCard setCurrentTab={setCurrentTab} />
              ) : null}
              {currentTab === 2 ? (
                <FinishedCard invitationURL={NoQRURL} />
              ) : null}
            </div>
            {/* card footer */}

            <div
              style={{
                display: "flex",
                borderRadius: "0 0 2em 2em",
                padding: ".5em 1em",
                backgroundColor: "white",
              }}
            >
              <Button
                disabled={currentTab <= 0}
                type="text"
                shape="round"
                onClick={() => {
                  setCurrentTab((currentTab) => currentTab - 1);
                }}
              >
                Back
              </Button>
              <div style={{ flexGrow: 1 }} />
              <Button
                disabled={
                  (currentTab >= dashboardTabs.length - 1) || 
                  (currentTab === 0 && !idCheckDone) ||
                  currentTab === 1
                }
                type="primary"
                shape="round"
                onClick={() => {
                  setCurrentTab((currentTab) => currentTab + 1);
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ConnectionExchangePage>
  );
};
