import { CredDefID, CredentialExchangeID, SchemaID } from './index'

//#region Types

export type CredentialID = string

export type Credential =
  | OwnedCredential
  | IssuedCredential
  | RevocableCredential

export type OwnedCredential = BaseOwnedCredential | OwnedRevocableCredential

export type IssuedCredential = BaseIssuedCredential | IssuedRevocableCredential

export type RevocableCredential =
  | OwnedRevocableCredential
  | IssuedRevocableCredential

export type OwnedRevocableCredential = BaseOwnedCredential &
  BaseRevocableCredential

export type IssuedRevocableCredential = BaseIssuedCredential &
  BaseRevocableCredential

//#endregion Types

//#region Base Interfaces

interface BaseCredential {
  readonly schemaId: SchemaID
  readonly credDefId: CredDefID
  readonly attributeValuesByName: { [name: string]: string }
  readonly debug?: object
}

interface BaseOwnedCredential extends BaseCredential {
  readonly id: CredentialID
}

/**
 * Issued Credentials do not have IDs, so we must use their associated
 * Credential Exchanges to uniquely identify them.
 */
interface BaseIssuedCredential extends BaseCredential {
  readonly credExId: CredentialExchangeID
}

interface BaseRevocableCredential extends BaseCredential {
  readonly revocationRegistryId: string
  /**
   * NOTE: 'revocationId' is optional only because the SDK does not always return it.
   *  My current understanding is that 'revocationId' is only returned for OwnedCredentials
   *  (not IssuedCredentials). I do not yet understand why this is, because an Issuer
   *  must know the 'revocationId' in order to later revoke the credential.
   *  I hope to make 'revocationId' non-optional in the future.
   */
  readonly revocationId?: string
}

//#endregion Base Interfaces

//#region Type Guards

export function isOwnedCredential(
  credential: Credential,
): credential is OwnedCredential {
  return (credential as OwnedCredential).id !== undefined
}

export function isIssuedCredential(
  credential: Credential,
): credential is IssuedCredential {
  return (credential as IssuedCredential).credExId !== undefined
}

/**
 * NOTE: 'revocationId' is not always set, so do not test its value in this function.
 */
export function isRevocableCredential(
  credential: Credential,
): credential is RevocableCredential {
  return (credential as RevocableCredential).revocationRegistryId !== undefined
}

//#endregion Type Guards
