import {
  IssueCredential20RecordsGetRoleEnum,
  V20CredExRecordDetail,
  V20CredExRecordRoleEnum,
  V20CredExRecordStateEnum,
  V20CredOfferRequest,
  IssueCredential20SendOfferPostRequest,
  LDProofVCDetail,
  Credential as v20Credential,
  LDProofVCDetailOptions,
  IssueCredential20RecordsCredExIdSendRequestPostRequest,
  IssueCredential20RecordsCredExIdIssuePostRequest,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import {
  CredentialExchange,
  CredentialExchangeRole,
  CredentialExchangeState,
  ProofType,
  Credential,
  CredentialOffer,
  CredentialSubject,
} from '../../../domain/entities/W3C'
import { convertIso8601ToDate } from '../../../utils/convertIso8601ToDate'
import { CredentialExchangeGatewayInterface } from '../../../domain/gateway-interfaces/W3C'
import { CloudAgentAPI } from '../sdk-wrappers/cloudAgent'
import { reportCloudAgentError } from '../sdk-wrappers/utils/errorlog'
import {
  ApiDataIsNotW3cError,
  DIDNotFoundError,
} from '../../../domain/error-types'

export class CredentialExchangeGateway
  implements CredentialExchangeGatewayInterface
{
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async offerCredential(credOffer: CredentialOffer): Promise<void> {
    try {
      await this.cloudAgentAPIs.issueV20Credentials.issueCredential20SendOfferPost(
        transformToV20CredentialOffer(credOffer),
      )
    } catch (error) {
      const cloudAgentError = await reportCloudAgentError(
        `Failed to Send Offer Credential to ${credOffer.connectionId}`,
        error as Response,
      )

      if (
        cloudAgentError.message.includes(
          `not found. Unable to issue credential with this DID`,
        )
      ) {
        throw new DIDNotFoundError()
      }

      throw await reportCloudAgentError(
        `Failed to Send Offer Credential to ${credOffer.connectionId}`,
        error as Response,
      )
    }
  }

  async requestCredential(credExId: string, holderDid?: string): Promise<void> {
    const requestParams: IssueCredential20RecordsCredExIdSendRequestPostRequest =
      { credExId: credExId, body: { holder_did: holderDid } }

    try {
      await this.cloudAgentAPIs.issueV20Credentials.issueCredential20RecordsCredExIdSendRequestPost(
        requestParams,
      )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to Request Credential for ${credExId}}`,
        error as Response,
      )
    }
  }

  async issueCredential(credExId: string): Promise<void> {
    const requestParams: IssueCredential20RecordsCredExIdIssuePostRequest = {
      credExId: credExId,
      body: {},
    }
    try {
      await this.cloudAgentAPIs.issueV20Credentials.issueCredential20RecordsCredExIdIssuePost(
        requestParams,
      )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to Issue Credential for ${credExId}}`,
        error as Response,
      )
    }
  }

  async getInProgressCredExList(): Promise<CredentialExchange[]> {
    const v20CredentialExchanges =
      await fetchFilteredW3CCredentialExchangeRecords(this.cloudAgentAPIs, {
        states: [
          V20CredExRecordStateEnum.ProposalSent,
          V20CredExRecordStateEnum.ProposalReceived,
          V20CredExRecordStateEnum.OfferSent,
          V20CredExRecordStateEnum.OfferReceived,
          V20CredExRecordStateEnum.RequestSent,
          V20CredExRecordStateEnum.RequestReceived,
          V20CredExRecordStateEnum.CredentialIssued,
          V20CredExRecordStateEnum.CredentialReceived,
          V20CredExRecordStateEnum.Abandoned,
        ],
      })

    return transformToCredentialExchangeList(v20CredentialExchanges)
  }

  async getCompletedCredExList(): Promise<CredentialExchange[]> {
    const v20CredentialExchanges =
      await fetchFilteredW3CCredentialExchangeRecords(this.cloudAgentAPIs, {
        states: [
          V20CredExRecordStateEnum.Deleted,
          V20CredExRecordStateEnum.Done,
          V20CredExRecordStateEnum.CredentialRevoked,
        ],
      })

    return transformToCredentialExchangeList(v20CredentialExchanges)
  }

  // Ideally we would't want to make calls like this out to any website
  // In future the GraphQL CloudAgent controller should be able to provide contexts
  async fetchJSONContext(contextUrl: string): Promise<object> {
    return fetch(contextUrl, {
      headers: {
        Accept: 'application/ld+json', // Only allow for JSON-LD
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error
      })
  }

  async getCredExById(id: string): Promise<CredentialExchange> {
    let v20CredEx
    try {
      v20CredEx =
        await this.cloudAgentAPIs.issueV20Credentials.issueCredential20RecordsCredExIdGet(
          {
            credExId: id,
          },
        )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to get Credential Exchange for ${id}}`,
        error as Response,
      )
    }

    return transformToCredentialExchange(v20CredEx)
  }

  async deleteCredExById(id: string): Promise<void> {
    try {
      await this.cloudAgentAPIs.issueV20Credentials.issueCredential20RecordsCredExIdDelete(
        { credExId: id },
      )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to delete Credential Exchange for ${id}}`,
        error as Response,
      )
    }
  }

  async sendProblemReport(id: string, problemReport: string): Promise<void> {
    try {
      await this.cloudAgentAPIs.issueV20Credentials.issueCredential20RecordsCredExIdProblemReportPost(
        { credExId: id, body: { description: problemReport } },
      )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to send problem report for ${id}}`,
        error as Response,
      )
    }
  }
}

type CredentialExchangeRecordFilterParams = {
  connection?: string
  role?: IssueCredential20RecordsGetRoleEnum
  states?: V20CredExRecordStateEnum[]
}

async function fetchFilteredW3CCredentialExchangeRecords(
  agent: CloudAgentAPI,
  params: CredentialExchangeRecordFilterParams,
): Promise<V20CredExRecordDetail[]> {
  try {
    const agentResult =
      await agent.issueV20Credentials.issueCredential20RecordsGet({
        connectionId: params.connection,
        role: params.role,
      })

    const recordList = agentResult.results ?? []
    const result = recordList.filter(
      (record) =>
        params.states === undefined ||
        (record.cred_ex_record?.state !== undefined &&
          params.states.includes(
            record.cred_ex_record.state as V20CredExRecordStateEnum,
          )),
    )

    const w3cCreds = result.filter((cred) => cred.indy === undefined)

    return w3cCreds
  } catch (error) {
    throw await reportCloudAgentError(
      'Failed to Retrieve Credential Exchange Records from Wallet',
      error as Response,
    )
  }
}

function transformToCredentialExchangeList(
  v20CredentialExchanges: V20CredExRecordDetail[],
): CredentialExchange[] {
  const credentialExchanges: CredentialExchange[] = []
  for (const v20CredentialExchange of v20CredentialExchanges) {
    try {
      const credentialExchange = transformToCredentialExchange(
        v20CredentialExchange,
      )
      credentialExchanges.push(credentialExchange)
    } catch (error) {
      // for this list operation, ignore any credential exchanges returned from the API which are not W3C
      if (error instanceof ApiDataIsNotW3cError) {
        continue
      }
      throw error
    }
  }
  return credentialExchanges
}

function transformToCredentialExchange(
  v20CredentialExchange: V20CredExRecordDetail,
): CredentialExchange {
  const credExId = v20CredentialExchange.cred_ex_record?.cred_ex_id

  // Credential Proposal
  let credProposal: Credential | undefined = undefined
  if (
    v20CredentialExchange.cred_ex_record?.by_format?.cred_proposal !== undefined
  ) {
    const V20CredProposal: SdkCredentialObject = v20CredentialExchange
      .cred_ex_record.by_format.cred_proposal as SdkCredentialObject

    credProposal = transformToCredential(V20CredProposal, credExId)
  }

  // Credential Offer
  let credOffer: Credential | undefined = undefined
  if (
    v20CredentialExchange.cred_ex_record?.by_format?.cred_offer !== undefined
  ) {
    const V20CredOffer: SdkCredentialObject = v20CredentialExchange
      .cred_ex_record.by_format.cred_offer as SdkCredentialObject

    credOffer = transformToCredential(V20CredOffer, credExId)
  }

  // Credential Request
  let credRequest: Credential | undefined = undefined
  if (
    v20CredentialExchange.cred_ex_record?.by_format?.cred_request !== undefined
  ) {
    const V20CredRequest: SdkCredentialObject = v20CredentialExchange
      .cred_ex_record.by_format.cred_request as SdkCredentialObject

    credRequest = transformToCredential(V20CredRequest, credExId)
  }

  return {
    updatedDatetime: convertIso8601ToDate(
      v20CredentialExchange.cred_ex_record?.updated_at,
    ),
    id: v20CredentialExchange.cred_ex_record?.cred_ex_id ?? '',
    connectionId: v20CredentialExchange.cred_ex_record?.connection_id ?? '',
    credentialProposal: credProposal,
    credentialOffer: credOffer,
    credentialRequest: credRequest,
    credentialId: v20CredentialExchange.ld_proof?.cred_id_stored,
    state: transformToState(v20CredentialExchange.cred_ex_record?.state),
    myRole: transformToRole(v20CredentialExchange.cred_ex_record?.role),
    debug: { 'SDK::v20CredentialExchange': v20CredentialExchange },
  }
}

function transformToCredential(
  credObj: SdkCredentialObject,
  credExId: string | undefined,
): Credential {
  if (credObj.ld_proof === undefined) {
    throw new ApiDataIsNotW3cError()
  }

  return {
    credExId: credExId ?? '',
    context: credObj.ld_proof.credential?.['@context'] ?? [],
    type: credObj.ld_proof.credential?.type ?? [],
    issuerDid: transformToIssuerDid(credObj.ld_proof.credential ?? {}),
    issuanceDate: convertIso8601ToDate(
      credObj.ld_proof.credential?.issuanceDate,
    ),
    credentialSubject: (credObj.ld_proof.credential?.credentialSubject ??
      {}) as CredentialSubject,
    proofType: transformToProofType(credObj.ld_proof.options?.proofType),
    debug: credObj,
  }
}

function transformToIssuerDid(sdkCredValue: SdkCredValue): string {
  if (typeof sdkCredValue.issuer === 'object') {
    return sdkCredValue.issuer.id ?? ''
  }
  return sdkCredValue.issuer ?? ''
}

function transformToProofType(type?: string): ProofType {
  switch (type) {
    case ProofType.BbsBlsSignature2020:
      return ProofType.BbsBlsSignature2020
    case ProofType.Ed25519Signature2018:
      return ProofType.Ed25519Signature2018
    default:
      return ProofType.Unknown
  }
}

function transformToState(state?: string): CredentialExchangeState {
  switch (state) {
    case V20CredExRecordStateEnum.ProposalSent:
      return CredentialExchangeState.ProposalSent
    case V20CredExRecordStateEnum.ProposalReceived:
      return CredentialExchangeState.ProposalReceived
    case V20CredExRecordStateEnum.OfferSent:
      return CredentialExchangeState.OfferSent
    case V20CredExRecordStateEnum.OfferReceived:
      return CredentialExchangeState.OfferReceived
    case V20CredExRecordStateEnum.RequestSent:
      return CredentialExchangeState.RequestSent
    case V20CredExRecordStateEnum.RequestReceived:
      return CredentialExchangeState.RequestReceived
    case V20CredExRecordStateEnum.CredentialIssued:
      return CredentialExchangeState.CredentialIssued
    case V20CredExRecordStateEnum.CredentialReceived:
      return CredentialExchangeState.CredentialReceived
    case V20CredExRecordStateEnum.Done:
      return CredentialExchangeState.Done
    case V20CredExRecordStateEnum.CredentialRevoked:
      return CredentialExchangeState.CredentialRevoked
    case V20CredExRecordStateEnum.Deleted:
      return CredentialExchangeState.Deleted
    case V20CredExRecordStateEnum.Abandoned:
      return CredentialExchangeState.Abandoned
  }
  return CredentialExchangeState.Unknown
}

function transformToRole(role?: string): CredentialExchangeRole {
  switch (role) {
    case V20CredExRecordRoleEnum.Holder:
      return CredentialExchangeRole.Holder
    case V20CredExRecordRoleEnum.Issuer:
      return CredentialExchangeRole.Issuer
  }
  return CredentialExchangeRole.Unknown
}

function transformToV20CredentialOffer(
  credOffer: CredentialOffer,
): IssueCredential20SendOfferPostRequest {
  // This context is required for a valid VC and must come first
  credOffer.context.unshift('https://www.w3.org/2018/credentials/v1')

  // Necessary to work around the SDK since it only accepts object[]
  const contextStringArray: String[] = credOffer.context.map((context) => {
    return new String(context)
  })

  const v20Credential: v20Credential = {
    context: contextStringArray, // currently this is just a string array, however custom contexts can be constructed as objects
    type: ['VerifiableCredential', ...credOffer.type],
    // TODO: Add credentialSchema once supported in ACA-py

    /**  
      Note that the type is also added in the credentialSubject
      since currently ACA-Py may have an error for attributes
      which cannot be mapped to context definitions. It's suspected
      that this issue relates to the comment left in /aries_cloudagent/vc/ld_proofs/check.py
      which reads:
      # FIXME: this doesn't work with nested @context structures... 
    */
    credentialSubject: {
      type: credOffer.type,
      ...credOffer.attributes,
    },
    issuanceDate: new Date().toISOString(),
    issuer: new String(credOffer.issuerDid),
  }

  const options: LDProofVCDetailOptions = {
    proofType: credOffer.proofType,
  }

  const ld_proof: LDProofVCDetail = {
    credential: v20Credential,
    options: options,
  }
  const body: V20CredOfferRequest = {
    connection_id: credOffer.connectionId,
    filter: { ld_proof },
  }
  return {
    body: body,
  }
}

/**
 * Assume all properties in the SDK response object are optional, in order to avoid crashes.
 */
interface SdkCredentialObject {
  // AnonCreds AIP-2.0 records will have an 'indy' property instead of 'ld_proof'
  indy?: object
  ld_proof?: {
    credential?: SdkCredValue
    options?: {
      proofType?: string
    }
  }
}

interface SdkCredValue {
  '@context'?: string[]
  type?: string[]
  issuanceDate?: string
  issuer?:
    | {
        id?: string
      }
    | string
  credentialSubject?: object
}
