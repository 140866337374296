import {
  CredentialW3cCredentialIdGetRequest,
  IssueCredential20RecordsCredExIdGetRequest,
  IssueCredential20RecordsGetRoleEnum,
  V20CredExRecordDetail,
  V20CredExRecordStateEnum,
  VCRecord,
} from '@sudoplatform-labs/sudo-di-cloud-agent'
import {
  CredentialSubject,
  IssuedCredential,
  OwnedCredential,
  ProofType,
} from '../../../domain/entities/W3C'
import { ApiDataIsNotW3cError } from '../../../domain/error-types'
import { CredentialGatewayInterface } from '../../../domain/gateway-interfaces/W3C'
import { CloudAgentAPI } from '../sdk-wrappers/cloudAgent'
import { convertIso8601ToDate } from '../../../utils/convertIso8601ToDate'
import { reportCloudAgentError } from '../sdk-wrappers/utils/errorlog'

export class CredentialGateway implements CredentialGatewayInterface {
  constructor(private readonly cloudAgentAPIs: CloudAgentAPI) {}

  async getOwnedCredentialList(): Promise<OwnedCredential[]> {
    let v20CredentialRecords = []
    try {
      v20CredentialRecords =
        (
          await this.cloudAgentAPIs.credentials.credentialsW3cPost({
            count: undefined, // does not work - use 'max_results' instead
            body: {
              max_results: 100000, // default is 100. Note the lack of sort order
            },
          })
        ).results ?? []
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to fetch w3c credentials.`,
        error as Response,
      )
    }
    return transformToOwnedCredentialList(v20CredentialRecords)
  }

  async getIssuedCredentialList(): Promise<IssuedCredential[]> {
    let v20CredentialExchanges = []
    try {
      v20CredentialExchanges = await fetchFilteredW3CCredentialExchangeRecords(
        this.cloudAgentAPIs,
        {
          role: IssueCredential20RecordsGetRoleEnum.Issuer,
          states: [
            V20CredExRecordStateEnum.Deleted,
            V20CredExRecordStateEnum.Done,
            V20CredExRecordStateEnum.CredentialRevoked,
          ],
        },
      )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to fetch 'completed' w3c credential exchanges.`,
        error as Response,
      )
    }

    return transformToIssuedCredentialList(v20CredentialExchanges)
  }

  async getOwnedCredential(id: string): Promise<OwnedCredential> {
    let v20Credential
    const requestParams: CredentialW3cCredentialIdGetRequest = {
      credentialId: id,
    }
    try {
      v20Credential =
        await this.cloudAgentAPIs.credentials.credentialW3cCredentialIdGet(
          requestParams,
        )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to fetch owned w3c credential ${id}.`,
        error as Response,
      )
    }
    return transformToOwnedCredential(v20Credential)
  }

  async getIssuedCredential(id: string): Promise<IssuedCredential> {
    let v20CredentialExchange
    const requestParams: IssueCredential20RecordsCredExIdGetRequest = {
      credExId: id,
    }
    try {
      v20CredentialExchange =
        await this.cloudAgentAPIs.issueV20Credentials.issueCredential20RecordsCredExIdGet(
          requestParams,
        )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to fetch issued w3c credential ${id}.`,
        error as Response,
      )
    }
    return transformToIssuedCredential(v20CredentialExchange)
  }

  async deleteOwnedCredential(id: string): Promise<void> {
    try {
      await this.cloudAgentAPIs.credentials.credentialW3cCredentialIdDelete({
        credentialId: id,
      })
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to delete credential ${id}.`,
        error as Response,
      )
    }
  }

  async deleteIssuedCredential(id: string): Promise<void> {
    try {
      await this.cloudAgentAPIs.issueV20Credentials.issueCredential20RecordsCredExIdDelete(
        {
          credExId: id,
        },
      )
    } catch (error) {
      throw await reportCloudAgentError(
        `Failed to delete credential exchange ${id}.`,
        error as Response,
      )
    }
  }
}

type CredentialExchangeRecordFilterParams = {
  connection?: string
  role?: IssueCredential20RecordsGetRoleEnum
  states?: V20CredExRecordStateEnum[]
}

async function fetchFilteredW3CCredentialExchangeRecords(
  agent: CloudAgentAPI,
  params: CredentialExchangeRecordFilterParams,
): Promise<V20CredExRecordDetail[]> {
  try {
    const agentResult =
      await agent.issueV20Credentials.issueCredential20RecordsGet({
        connectionId: params.connection,
        role: params.role,
      })

    const recordList = agentResult.results ?? []
    const result = recordList.filter(
      (record) =>
        params.states === undefined ||
        (record.cred_ex_record?.state !== undefined &&
          params.states.includes(
            record.cred_ex_record.state as V20CredExRecordStateEnum,
          )),
    )

    const w3cCreds = result.filter((cred) => cred.indy === undefined)

    return w3cCreds
  } catch (error) {
    throw await reportCloudAgentError(
      'Failed to Retrieve Credential Exchange Records from Wallet',
      error as Response,
    )
  }
}

function transformToIssuedCredentialList(
  v20CredentialExchanges: V20CredExRecordDetail[],
): IssuedCredential[] {
  const credentials: IssuedCredential[] = []
  for (const v20CredentialExchange of v20CredentialExchanges) {
    try {
      const credential = transformToIssuedCredential(v20CredentialExchange)
      credentials.push(credential)
    } catch (error) {
      // for this list operation, ignore any credentials returned from the API which are not W3C
      if (error instanceof ApiDataIsNotW3cError) {
        continue
      }
      throw error
    }
  }
  return credentials
}

function transformToIssuedCredential(
  v20CredentialExchange: V20CredExRecordDetail,
): IssuedCredential {
  const V20Cred: SdkCredentialObject = v20CredentialExchange?.cred_ex_record
    ?.by_format?.cred_request as SdkCredentialObject

  if (V20Cred.ld_proof === undefined) {
    throw new ApiDataIsNotW3cError()
  }

  return {
    credExId: v20CredentialExchange.cred_ex_record?.cred_ex_id ?? '',
    context: V20Cred.ld_proof.credential?.['@context'] ?? [],
    type: V20Cred.ld_proof.credential?.type ?? [],
    issuerDid: transformToIssuerDid(V20Cred.ld_proof.credential ?? {}),
    issuanceDate: convertIso8601ToDate(
      V20Cred.ld_proof.credential?.issuanceDate,
    ),
    credentialSubject: (V20Cred.ld_proof.credential?.credentialSubject ??
      {}) as CredentialSubject,
    proofType: transformToProofType([
      V20Cred.ld_proof.options?.proofType ?? '',
    ]),
    debug: { 'SDK::v20CredentialExchange': v20CredentialExchange },
  }
}

function transformToOwnedCredentialList(
  v20CredentialRecords: VCRecord[],
): OwnedCredential[] {
  const credentials = []
  for (const v20Credential of v20CredentialRecords) {
    const Credential = transformToOwnedCredential(v20Credential)
    credentials.push(Credential)
  }
  return credentials
}

function transformToOwnedCredential(record: VCRecord): OwnedCredential {
  const credValue: SdkCredValue = record.cred_value as SdkCredValue

  return {
    id: record.record_id ?? '',
    context: record.contexts ?? [],
    type: credValue.type ?? [],
    issuerDid: record.issuer_id ?? '',
    issuanceDate: convertIso8601ToDate(credValue.issuanceDate),
    credentialSubject: (credValue.credentialSubject ?? {}) as CredentialSubject,
    proofType: transformToProofType(record.proof_types),
    debug: { 'SDK::CredentialsApi': record },
  }
}

function transformToIssuerDid(sdkCredValue: SdkCredValue): string {
  if (typeof sdkCredValue.issuer === 'object') {
    return sdkCredValue.issuer.id ?? ''
  }
  return sdkCredValue.issuer ?? ''
}

function transformToProofType(proofTypes: string[] | undefined): ProofType {
  if (proofTypes && proofTypes.length > 0) {
    switch (proofTypes[0]) {
      case ProofType.BbsBlsSignature2020:
        return ProofType.BbsBlsSignature2020
      case ProofType.Ed25519Signature2018:
        return ProofType.Ed25519Signature2018
    }
  }
  return ProofType.Unknown
}

/**
 * Assume all properties in the SDK response object are optional, in order to avoid crashes.
 */
interface SdkCredentialObject {
  // AnonCreds AIP-2.0 records will have an 'indy' property instead of 'ld_proof'
  indy?: object
  ld_proof?: {
    credential?: SdkCredValue
    options?: {
      proofType?: string
    }
  }
}

interface SdkCredValue {
  '@context'?: string[]
  type?: string[]
  issuanceDate?: string
  issuer?:
    | {
        id?: string
      }
    | string
  credentialSubject?: object
}
