/**
 * @param epoch A Unix epoch: The number of seconds which have elapsed since 1st January 1970 00:00:00 UTC.
 */
export function convertEpochToDate(
  epoch: number | undefined,
): Date | undefined {
  if (epoch === undefined) {
    return undefined
  }

  const date = new Date(0)
  date.setUTCSeconds(epoch)

  return date
}
