/**
 * @param epoch A date-time in ISO 8601 format. e.g. 2023-06-12T04:51:16.602441Z
 */
export function convertIso8601ToDate(
  epoch: string | undefined,
): Date | undefined {
  if (epoch === undefined) {
    return undefined
  }

  return new Date(epoch)
}
