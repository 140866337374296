import { CredDef, CredDefID } from '../../entities'
import { CredDefNotFoundError } from '../../error-types'
import { CredDefGatewayInterface } from '../../gateway-interfaces'

export class GetCredDefById {
  constructor(private readonly credDefGateway: CredDefGatewayInterface) {}

  async execute(id: CredDefID): Promise<CredDef | undefined> {
    try {
      return await this.credDefGateway.getCredDefByIdFromLedger(id)
    } catch (error) {
      if (error instanceof CredDefNotFoundError) {
        return undefined
      }
      throw error
    }
  }
}
