export type ConnectionExchangeID = string

export type ConnectionExchange = {
  readonly id: ConnectionExchangeID
  readonly name: string
  readonly state: ConnectionExchangeState
  readonly myRole: ConnectionExchangeRole
  readonly updatedDateTime?: Date
  readonly debug?: object
}

export enum ConnectionExchangeRole {
  Unknown = 'Unknown',
  Invitee = 'Invitee',
  Inviter = 'Inviter',
  Requester = 'Requester',
  Responder = 'Responder',
}

export enum ConnectionExchangeState {
  Unknown = 'Unknown',
  Error = 'Error',
  Start = 'Start',
  Init = 'Init',
  Invitation = 'Invitation',
  Response = 'Response',
  Request = 'Request',
  Abandoned = 'Abandoned',
}
