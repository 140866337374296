export type MessageList = {
  messages: Message[]
  nextToken?: string
}

export type Message = {
  readonly id: string
  readonly connectionId: string
  readonly content: string
  readonly timestamp?: Date
  readonly direction: MessageState
  readonly debug?: object
}

export enum MessageState {
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND',
}
