import { createBrowserRouter } from "react-router-dom";
import { SignInPage } from "./routes/sign-in/sign-in";
import { AdminDashboard } from "./routes/admin/admin-dashboard";
import { CustomerDashboard } from "./routes/customer/customer-dashboard";
import React from "react";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <SignInPage />,
  },
  {
    path: "/admin",
    element: <AdminDashboard />,
  },
  {
    path: "/customer",
    element: <CustomerDashboard />,
  },
]);
