import React from "react";
import QRCode from "qrcode.react";

import { createGlobalStyle } from "styled-components";

const FinishedStyle = createGlobalStyle`
.hContainer {
  display: flex;
}

.hBox1 {
  flex: 1;
  padding: 20px;
  margin-top: 30px;
  box-sizing: border-box;
}

.hBox2 {
  flex: 2;
  padding: 20px;
  margin: 10px;
  box-sizing: border-box;
}
`;

interface Props {
  invitationURL: string;
}

export const FinishedCard: React.FC<Props> = (props) => {
  let invitationURL = localStorage.getItem("invitationURL");
  console.log("localStorage.getItem: " + invitationURL);

  const cardTitle = <div>Welcome to Anonyome Credit Union</div>;
  const notes = `
      Congrats! We were able to validate your ID & likeness. We are exited to
      welcome you to our Credit Union. Your identity is important to us. Please
      scan the QR code below in the Anonyome Credit Union App.
  `;
  const infoTitle = `Scan QR Code from the ACU Mobile App`;
  const infoContent = `
      By scanning the QR code your MemberID will be stored securely inside your
      ACU App, and allow us to verify your real identity quickly and easily in
      the future.
  `;

  if (!invitationURL) {
    invitationURL = "no url";
  }

  return (
    <div className="cardContent">
      <FinishedStyle />
      <div className="cardTitle">{cardTitle}</div>
      <p className="notes">{notes}</p>

      <div style={{ width: "75%", margin: "0 auto" }}>
        <div className="hContainer">
          <div className="hBox1">
            <div>
              <QRCode value={invitationURL} />
            </div>
          </div>
          <div className="hBox2">
            <h1 className="qrTitle">{infoTitle}</h1>
            <p className="qrNotes">{infoContent}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
