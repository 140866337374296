import React, { useState } from "react";
import { Select } from "antd";

interface CustomerDashboardTabNavigationControl {
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
}

export const VideoSelfieCard: React.FC<
  CustomerDashboardTabNavigationControl
> = (props) => {
  const cameraSelected = "cameraSelected";

  const [selectedOption, setSelectedOption] = useState("");
  const [percentage, setPercentage] = useState(0);
  let videoStream: MediaStream | null;

  const cardTitle = <div>Record Video</div>;
  const notes =
    "This video will be used to validate your likeness and liveness to your ID.";

  const turnOffCamera = () => {
    // Note:
    // The camera won't turn off on Chrome on macOS.
    //
    const cameraFeed = document.getElementById(
      "livelinessVideo"
    ) as HTMLVideoElement;

    try {
      if (cameraFeed) {
        cameraFeed.pause();
        cameraFeed.srcObject = null;
        cameraFeed.src = "";
        cameraFeed.setAttribute("height", "0");
      }
      if (videoStream) {
        const tracks = videoStream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    } catch (error) {
      console.error("Error stopping camera:", error);
    }
  };

  const proceed = (): void => {
    setPercentage((prev) => {
      if (prev <= 100) {
        // console.log(prev)
        setTimeout(proceed, 50);
      } else {
        turnOffCamera();

        // console.log('ended')
        props.setCurrentTab((currentTab) => currentTab + 1);
      }
      return prev + 1;
    });
  };

  return (
    <div className="cardContent">
      <div className="cardTitle">{cardTitle}</div>
      <p className="notes">{notes}</p>

      <div className="livelinessContainer">
        {selectedOption ? (
          <div
            className="livelinessOverlay"
            style={{
              background: `conic-gradient(rgba(255,255,255,0.5) ${
                360 - percentage * 3.6
              }deg, rgba(100,200,100,1) 0deg)`,
            }}
          />
        ) : null}
        <video id="livelinessVideo" data-caption={"1080 OGG"} />
      </div>

      {/* <VideoPlayer /> */}
      <Select
        className="select"
        defaultValue="Select Webcam"
        onChange={(value) => {
          setSelectedOption(value);
          if (value === cameraSelected) {
            try {
              const livelinessVideoElement = document.getElementById(
                "livelinessVideo"
              ) as HTMLVideoElement;
              navigator.mediaDevices
                .getUserMedia({
                  video: true,
                  audio: false,
                })
                .then((stream) => {
                  videoStream = stream;
                  livelinessVideoElement.srcObject = videoStream;
                  livelinessVideoElement.onloadedmetadata = function () {
                    livelinessVideoElement.play();
                    setTimeout(() => {
                      proceed();
                    }, 1000);
                  };
                });
            } catch (error) {
              console.error("Error starting camera:", error);
            }
          }
        }}
        options={[
          { value: cameraSelected, label: "Camera1" },
          { value: "camera2", label: "Camera2" },
        ]}
      />
    </div>
  );
};
