/**
 * @return A Unix epoch: The number of seconds which have elapsed since 1st January 1970 00:00:00 UTC.
 */
export function convertDateToEpoch(date: Date | undefined): number | undefined {
  if (date === undefined) {
    return undefined
  }

  const ms = date.valueOf()

  // convert ms to seconds, then drop the fraction to return an integer
  return Math.trunc(ms / 1000)
}
